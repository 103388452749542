import { PowerBIProfileServerModel } from "@core/services/power-bi-profile-service/model/power-bi-profile.model";

export class PowerBIProfile {
  id?: number | undefined;
  name: string;
  applicationId: number;
  biApplicationId: string;
  userName: string;
  password: string;
  isCompanyProfile?: boolean | undefined;
  isDefaultProfile?: boolean | undefined;
  useProxy?: boolean | undefined;
  constructor(profile?: PowerBIProfileServerModel) {
    if (profile) {
      this.id = profile.id;
      this.name = profile.name;
      this.applicationId = profile.applicationId;
      this.biApplicationId = profile.biApplicationId;
      this.userName = profile.userName;
      this.password = profile.password;
      this.isCompanyProfile = profile.isCompanyProfile;
      this.isDefaultProfile = profile.isDefaultProfile;
    }
  }
}
