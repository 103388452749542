import { Injectable } from "@angular/core";
import { SearchRestService } from "@app/core/services/search-service/service/search.rest.service";
import { SearchModel } from "../model/search-model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  constructor(private service: SearchRestService) {}
  search$(search: string): Observable<SearchModel[]> {
    return this.service
      .search$(search)
      .pipe(map((res) => res.map((x) => new SearchModel(x))));
  }
}
