import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BookmarkServerModel } from "../models/bookmark.server.model";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class PowerBIBookmarkRestService extends BaseService {
  routUrl = "";

  constructor(private http: HttpClient) {
    super();
  }

  getBookmarks$(reportId: number): Observable<BookmarkServerModel[]> {
    this.routUrl = this.baseUrl + `/reports/${reportId}/bookmarks`;
    return this.http.get<BookmarkServerModel[]>(this.routUrl);
  }
  getBookmark$(id: number): Observable<BookmarkServerModel> {
    this.routUrl = this.baseUrl + `/bookmarks/${id}`;
    return this.http.get<BookmarkServerModel>(this.routUrl);
  }
  getUserBookmarks$(): Observable<BookmarkServerModel[]> {
    this.routUrl = this.baseUrl + `/application/powerbi/bookmarks/my`;
    return this.http.get<BookmarkServerModel[]>(this.routUrl);
  }
  updateBookmark$(
    bookmark: BookmarkServerModel
  ): Observable<BookmarkServerModel> {
    this.routUrl = this.baseUrl + `/bookmarks`;
    return this.http.put<BookmarkServerModel>(this.routUrl, bookmark);
  }
  remove$(bookmark: BookmarkServerModel): Observable<boolean> {
    this.routUrl = this.baseUrl + `/bookmarks/remove`;
    return this.http.put<boolean>(this.routUrl, bookmark);
  }
  syncBookmark$(id: number, bmks: BookmarkServerModel[]): Observable<boolean> {
    this.routUrl = this.baseUrl + `/reports/${id}/bookmarks/sync`;
    return this.http.post<boolean>(this.routUrl, bmks).pipe(map(() => true));
  }
  getBookmarkEmbedConfig$(bookmarkId: number): Observable<PowerBIEmbedConfig> {
    this.routUrl =
      this.baseUrl +
      `/application/powerbi/bookmarks/${bookmarkId}/embed-config`;
    return this.http.get<PowerBIEmbedConfig>(this.routUrl);
  }
}
