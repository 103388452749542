import { Injectable } from "@angular/core";
import {
  ProductServerModel,
  ProductSubscriptionServerModel,
} from "./models/product.server.model";
import { BaseService } from "../base.service";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable({
  providedIn: "root",
})
export class ProductRestService extends BaseService {
  routUrl = "";

  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + "/products";
  }
  getProducts$(
    page: number,
    pageSize: number,
    searchTerm: string,
    field: string,
    order: string,
    getTotal: boolean
  ): Observable<PageResultModel<ProductServerModel>> {
    let url_ = this.routUrl + "?";

    if (page !== undefined)
      url_ += "page=" + encodeURIComponent("" + page) + "&";
    if (pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";
    if (searchTerm !== undefined)
      url_ += "searchTerm=" + encodeURIComponent("" + searchTerm) + "&";
    if (field !== undefined)
      url_ += "sortOrder=" + encodeURIComponent("" + field + "_" + order) + "&";
    url_ += "getTotal=" + encodeURIComponent("" + getTotal);

    return this.http
      .get<PageResultModel<ProductServerModel>>(url_)
      .pipe(map((x) => new PageResultModel<ProductServerModel>(x)));
    //return of(this.products);
  }
  getProductsCount$(): Observable<number> {
    let url_ = this.routUrl + "/count";
    return this.http.get<number>(url_);
  }
  getProduct$(productId: number): Observable<ProductServerModel> {
    let url_ = this.routUrl + `/${productId}`;
    return this.http.get<ProductServerModel>(url_);
  }
  getSubscribedProducts$(): Observable<ProductSubscriptionServerModel[]> {
    let url_ = this.routUrl + `/subscribed`;
    return this.http.get<ProductSubscriptionServerModel[]>(url_);
  }
  updateProduct$(product: ProductServerModel): Observable<ProductServerModel> {
    return this.http.put<ProductServerModel>(this.routUrl, product);
  }
  addProduct$(product: ProductServerModel): Observable<ProductServerModel> {
    //product.type = "service";
    return this.http.post<ProductServerModel>(this.routUrl, product);
    //return of(product);
  }
  deleteProduct$(productId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.routUrl + `/${productId}`).pipe(
      map((res) => {
        if (res) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
