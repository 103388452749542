<div class="d-flex justify-content-between report-pageNames">
   <div class="btn-group"
        role="group"
        aria-label="Button group with nested dropdown">
      <button type="button"
              class="btn btn-secondary b-radius-0"
              [ngClass]="{'border-bottom-blue':true}"> {{bookmark?.displayName}}</button>
   </div>
   <div class="text-right  reportActions-container">
      <a class="btn btn-icon"
         (click)="fullScreen()"
         href="javascript:;">
         <i class="la la-expand text-dark"
            title="Full Screen"></i>
      </a>
      <a class="btn btn-icon"
         (click)="printReport()"
         href="javascript:;">
         <i class="la la-print text-dark"
            title="Print"></i>
      </a>
      <a class="btn btn-icon"
         (click)="removeVisualHeader()"
         href="javascript:;">
         <i class="fa fa-filter"
            [ngClass]="{'text-icon':visualHeaderVisible===false,'text-primary':visualHeaderVisible===true}"
            [title]="visualHeaderVisible?'Hide Visual Header':'Show Visual Header'"></i>
      </a>
   </div>
</div>
<div class=" reports-container">
   <qx-pbi-embedded [page]="'false'"
                    [embeddedConfig]='embedConfig'>
   </qx-pbi-embedded>
</div>