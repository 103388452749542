
import {
  BrowserModule,
} from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule as appCoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModules } from "./shared/module/shared.modules";
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthGuard } from "./core/guards/auth.guard";
import { ScopeGuard } from "./core/guards/scope.guard";
import { AppInterceptorService } from "./core/services/Interceptors/app-interceptor.service";

import { ProductService } from "./payment-management/components/products/services/product.service";
import { SubscriptionService } from "./payment-management/components/suscriptions/service/subscription.service";

import {
  LayoutRefService,
  MenuConfigService,
  PageConfigService,
  KtDialogService,
  SplashScreenService,
  SubheaderService,
  MenuHorizontalService,
  MenuAsideService,
  LayoutConfigService,
} from "../theme/metronic/app/core/_base/layout";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";

import {
  HttpUtilsService,
  TypesUtilsService,
} from "../theme/metronic/app/core/_base/crud";
import { NgxPermissionsModule } from "ngx-permissions";
import { PartialsModule } from "../theme/metronic/app/views/partials/partials.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { TranslateModule } from "@ngx-translate/core";

import { CoreModule } from "../theme/metronic/app/core/core.module";
import { LayoutConfig } from "@theme/metronic/app/core/_config/layout.config";
import { ThemeModule } from "@theme/metronic/app/views/theme/theme.module";
import { AuthService as ThemeAuthService } from "@theme/metronic/app/core/auth";
import { AuthService } from "./core/services/Auth-service/auth.service";
import { SSEService } from "./core/services/notification-service/service/sse.service";
import { environment } from "../environments/environment";
import { PermissionGuard } from "./core/guards/permission.guard";
import { InlineSVGModule } from "ng-inline-svg";
import { authConfig } from "./core/config-objects/sso-config";
import { authModuleConfig, storageFactory } from "./auth-module-config";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PagesModule } from './pages/pages.module';
import { CustomErrorComponent } from './custom-error/custom-error.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}



@NgModule({
  imports: [
    SharedModules,
    PagesModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    appCoreModule,
    ToastrModule.forRoot({
      progressBar: true,
      tapToDismiss: true,
      closeButton: true,
      enableHtml: true,
      preventDuplicates: true,

      extendedTimeOut: 2000,
      progressAnimation: "increasing",
      positionClass: "toast-bottom-right",
    }),

    // Theme
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    NgxPermissionsModule.forRoot(),
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    // StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer, stateKey: "router" }),

    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    ThemeModule,

  ],
  declarations: [AppComponent,
    CustomErrorComponent
  ],
  providers: [
    SSEService,
    PermissionGuard,
    ScopeGuard,
    AuthGuard,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true,
    },
    ScopeGuard,
    ProductService,
    SubscriptionService,
    // Theme
    ThemeAuthService,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },

    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },

    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {


}


