// Angular
import { Injectable, OnDestroy } from "@angular/core";
// RxJS
import { Subject } from "rxjs";
import {
  LayoutConfigService,
  MenuAsideService,
  MenuHorizontalService,
  LayoutConfigModel,
} from "@theme/metronic/app/core/_base/layout";
import { UserMenuRestService } from "./user-menu-service/service/user-menu-rest.service";
import { ClientLayout } from "../config-objects/layout-config";
import { aside } from "../config-objects/aside-menu";
import { headerMenu } from "../config-objects/header-menu";
import { systemObject } from "@app/shared/model/user-action.enum";
import { HorizontalMenuModel } from "./user-menu-service/model/horizontal-menu.model";
import { take, takeUntil } from "rxjs/operators";

@Injectable()
export class AppLayoutConfigService extends LayoutConfigService
  implements OnDestroy {
  layoutConfigObj = ClientLayout as LayoutConfigModel;
  private unsubscribe$$ = new Subject<void>();
  premReports: any[] = [];
  reports: any[] = [];
  pages: any[] = [];
  bookmarks: any[] = [];
  datastories: any[] = [];
  dashboards: any[] = [];
  constructor(
    private asideService: MenuAsideService,
    private horMenuService: MenuHorizontalService,
    private horizontalMenuService: UserMenuRestService
  ) {
    super();
  }
   loadConfig(config?: LayoutConfigModel) {
     if (config) this.layoutConfigObj = config;
     this.resetConfig();
     this.setConfig(this.layoutConfigObj);
     this.saveConfig(this.layoutConfigObj);
     this.loadConfigs(this.layoutConfigObj);
    this.reloadConfigs();

     this.asideService.menuList$.next(aside.items);
     //this.reloadHorizontalMenu();
  }
  reloadHorizontalMenu() {
    this.horMenuService.menuList$.next(headerMenu.items);
     this.reloadDashboards();
    this.reloadReports();
    this.updateStories();
  }
  loadDashboards() {
    headerMenu.items[0].submenu = this.dashboards;
    this.horMenuService.menuList$.next(headerMenu.items);
  }

  reloadDashboards() {
    this.horizontalMenuService
      .getUserDashboards$()
      .pipe(take(1), takeUntil(this.unsubscribe$$))
      .subscribe((dashboards) => {
        if (dashboards && dashboards.length > 0) {
          this.dashboards = dashboards;
          this.loadDashboards();
        }
      });
  }

  reloadReports() {
    this.horizontalMenuService
      .getUserReports$()
      .pipe(take(1), takeUntil(this.unsubscribe$$))
      .toPromise()
      .then((reports) => {
        if (reports && reports.length > 0) {
          this.reports = reports.map(
            (x) => new HorizontalMenuModel(x, systemObject.report)
          );
          const bmkReports = reports.map(
            (x) => new HorizontalMenuModel(x, systemObject.report)
          );
          reports.forEach((reportItem, index) => {
            const isReport = reports.find(
              (x) => x.id == this.reports[index].id
            );
            const bmks =
              isReport && isReport.bookmarks && isReport.bookmarks.length > 0
                ? isReport.bookmarks
                : undefined;

            if (bmks) {
              bmkReports[index].submenu = bmks.map(
                (x) => new HorizontalMenuModel(x, systemObject.bookmark)
              );
            }
            headerMenu.items[3].submenu = bmkReports.filter(
              (x) => x.submenu && x.submenu.length > 0
            );
            this.horMenuService.menuList$.next(headerMenu.items);
          });
        }
        this.horizontalMenuService
      .getMyOnPremReports$()
      .pipe(take(1), takeUntil(this.unsubscribe$$))
      .toPromise()
      .then((onPremReports) => {
        headerMenu.items[1].submenu = [...this.reports, ...onPremReports];
        this.horMenuService.menuList$.next(headerMenu.items);
      });
      });

  }
  updateStories() {

    this.horizontalMenuService.getUserDataStories$()
      .pipe(take(1), takeUntil(this.unsubscribe$$))
      .toPromise().then(ds => {
        if (ds && ds.length > 0) {
          let pageWithStories = ds.map(
            (x) => new HorizontalMenuModel(x, systemObject.dataStory)
          );
          if (pageWithStories.length > 0)
            pageWithStories.forEach((item, index) => {
              if (!headerMenu.items[4].submenu.find((x) => x.page === item.page))
                headerMenu.items[4].submenu = [
                  ...headerMenu.items[4].submenu, item
                ];
            });

          this.horMenuService.menuList$.next(headerMenu.items);
        }
      });

  }

  ngOnDestroy() {
    this.unsubscribe$$.next();
  }
}
