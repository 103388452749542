<!-- splash screen -->
<div #splashScreen
	 class="kt-splash-screen text-center">
	<span *ngIf="loaderType === 'spinner-message'">{{loaderMessage}}</span>
	<img *ngIf="loaderType === 'spinner-logo'"
		 [attr.src]="'./assets/media/loader/Q-Loader.gif'"
		 alt="Logo">
	<!-- <mat-spinner diameter="40"></mat-spinner> -->
	<!-- <div class="dot-pulse"></div> -->
	<!-- <img class="brickclay-logo"
		 [attr.src]="'./assets/media/logos/brickclay.svg'"
		 alt="Product By Brickclay"> -->
</div>