import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import * as pbi from "powerbi-client";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { DataStoryModel } from "../model/data-story.model";
import { Subject } from "rxjs";
import { PBIEmbeddedComponent } from "../pbi-embedded/pbi-embedded.component";
import { NotifyService } from "@app/shared/services/notify.service";
import { ActivatedRoute } from "@angular/router";
import { DataStoryService } from "../service/data-story.service";
import { UserAction, systemObject } from "@app/shared/model/user-action.enum";
import { Report } from "powerbi-client";
import { takeUntil, take } from "rxjs/operators";

@Component({
  selector: "qx-render-datastory",
  templateUrl: "./render-datastory.component.html",
  styleUrls: ["./render-datastory.component.scss"],
})
export class RenderDatastoryComponent implements OnInit, OnDestroy {
  datastory: DataStoryModel = new DataStoryModel();
  id: number;
  embedConfig: PowerBIEmbedConfig = {};
  private unsubscribe$$ = new Subject<void>();
  powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
    pbi.Report
  );
  visualHeaderVisible = true;
  @ViewChild(PBIEmbeddedComponent)
  public embeddedReport: PBIEmbeddedComponent;
  constructor(
    private notifyService: NotifyService,
    private router: ActivatedRoute,
    public datastoryService: DataStoryService
  ) {}

  ngOnInit() {
    this.router.paramMap.pipe(takeUntil(this.unsubscribe$$)).subscribe(
      (x) => {
        this.id = +x.get("id");
        this.datastoryService
          .getDataStory$(this.id)
          .pipe(take(1), takeUntil(this.unsubscribe$$))
          .subscribe(
            (datastory) => {
              if (datastory) {
                this.datastory = datastory;
                this.datastoryService
                  .getDataStoryEmbedConfig$(this.id)
                  .pipe(take(1), takeUntil(this.unsubscribe$$))
                  .subscribe(
                    (config) => {
                      this.embedConfig = config;
                    },
                    (err) => {
                      if (err) {
                        this.notifyService.error({
                          crud: UserAction.Crud.get,
                          object: systemObject.dataStory,
                        });
                      }
                    }
                  );
              }
            },
            (err) => {
              if (err) {
                this.notifyService.error({
                  crud: UserAction.Crud.get,
                  object: systemObject.dataStory,
                });
              }
            }
          );
      },
      (err) => {
        if (err) {
          this.notifyService.error({
            crud: UserAction.Crud.get,
            object: systemObject.dataStory,
          });
        }
      }
    );
  }
  fullScreen() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.fullscreen();
  }
  printReport() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.print();
  }
  removeVisualHeader() {
    if (this.visualHeaderVisible === false) {
      this.visualHeaderVisible = true;
    } else {
      this.visualHeaderVisible = true;
    }

    const newSettings = {
      visualSettings: {
        visualHeaders: [
          {
            settings: {
              visible: this.visualHeaderVisible,
            },
          },
        ],
      },
    };
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.updateSettings(newSettings);
  }
  ngOnDestroy() {
    this.unsubscribe$$.next();
  }
}
