import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  AuthConfig,
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { authConfig } from './core/config-objects/sso-config';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/services/Auth-service/auth.service';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [HttpClientModule, OAuthModule.forRoot()],
  providers: [
    AuthGuard,
  ],
})
export class AuthModule {

  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AuthConfig,
          useValue: this.getAuthConfigValue(),
        },
        { provide: OAuthModuleConfig, useValue: authConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }


  static getAuthConfigValue(): AuthConfig {
    return authConfig;
  }
}
