import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { SubscriptionServerModel } from "../model/subscription.server.model";
import { BaseService } from "../../base.service";
import { HttpClient } from "@angular/common/http";
import { CouponServerModel } from "../../coupon-services/model/coupon.server.model";
import { UsageServerModel } from "../model/usage.server.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class SubscriptionRestService extends BaseService {
  routUrl = "";
  subs: SubscriptionServerModel[] = [
    {
      applicationId: 1,
      chargeType: 1,
      id: 1,
      packageId: 1,
      usage: [
        { key: "Users", dataType: "list", value: [1, 2, 3, 4] },
        { key: "Reports", dataType: "list", value: [1, 2, 3, 4] }
      ],
      features: [
        {
          id: 1,
          dataType: "list",
          key: "Users",
          showInSummery: true,
          value: "9"
        },
        {
          id: 2,
          dataType: "list",
          key: "Reports",
          showInSummery: true,
          value: "9"
        }
      ]
    }
  ];
  coupon: CouponServerModel = {
    id: 1,
    code: "SJF-345D-SFS",
    created: new Date(),
    object: "object;",
    name: "Coupon",
    redeemBy: new Date(),
    valid: true,
    maxRedemptions: 5,

    percentOff: 50
  };
  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + "/subscriptions";
  }
  getActiveSubscriptions$(): Observable<SubscriptionServerModel[]> {
    let url_ = this.routUrl + `/my`;
    return this.http.get<SubscriptionServerModel[]>(url_);
  }
  getSubscriptions$(): Observable<SubscriptionServerModel[]> {
    return this.http.get<SubscriptionServerModel[]>(this.routUrl);
  }
  getSubscription$(
    subbscriptionId: number
  ): Observable<SubscriptionServerModel> {
    let url_ = this.routUrl + `/${subbscriptionId}`;
    return this.http.get<SubscriptionServerModel>(url_);
  }
  createSubscription$(
    subs: SubscriptionServerModel
  ): Observable<SubscriptionServerModel> {
    return this.http.post(this.routUrl, subs);
  }
  cancelSubscription$(subsId: number): Observable<boolean> {
    let url_ = this.routUrl + `/${subsId}`;
    return this.http.delete<boolean>(url_);
  }
  updateSubscriptionUsage$(
    subsId: number,
    subsUsage: UsageServerModel
  ): Observable<boolean> {
    let url_ = this.routUrl + `/${subsId}/usage`;
    return this.http.put<boolean>(url_, subsUsage);
  }
}
