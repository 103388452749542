export class NotificationServerModel {
  id?: number | undefined;
  message?: string | undefined;
  severity?: number | undefined;
  createdOn?: Date | undefined;
  status?: boolean | undefined;
}
export class LogServerModel {
  id?: number | undefined;
  message?: string | undefined;
  createdOn?: Date | undefined;
}
export class CommandNotificationModel {
  object?: string | undefined;
  operation?: string | undefined;
}
export enum CommandOperations {
  suspend = "suspend",
  refreshToken = "refreshtoken",
  shared = "shared"
}
