import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { DashboardVisualServerModel } from "@app/core/services/pbi-dashboar-visual-service/model/dashboard-visual.model";

export class DashboardVisualModel {
  id?: number | undefined;
  pageId?: number | undefined;
  typeId?: number | undefined;
  token?: string | undefined;
  pageToken?: string | undefined;
  userId?: number | undefined;
  createdOn?: Date | undefined;
  height?: number | undefined;
  width?: number | undefined;
  top?: number | undefined;
  left?: number | undefined;
  available?: boolean | undefined;
  config?: PowerBIEmbedConfig | undefined;
  constructor(item?: DashboardVisualServerModel) {
    if (item) {
      this.id = item.id;
      this.pageId = item.pageId;
      this.typeId = item.typeId;
      this.token = item.token;
      this.pageToken = item.pageToken;
      this.userId = item.userId;
      this.height = item.height;
      this.width = item.width;
      this.top = item.top;
      this.left = item.left;
      this.available = item.available;
      this.config = item.config;
    }
  }
}
