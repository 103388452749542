import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { Observable, of } from "rxjs";
import { PowerBIProfileServerModel } from "../model/power-bi-profile.model";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../../base.service";

@Injectable({
  providedIn: "root",
})
export class ProfileRestService extends BaseService {
  routUrl = "";

  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + `/profiles`;
  }

  getPowerBIProfiles$(): Observable<PowerBIProfileServerModel[]> {
    return this.http.get<PowerBIProfileServerModel[]>(this.routUrl);
    //return of(this.profiles);
  }
  getCompanyPowerBIProfilesCount$(id: number): Observable<number> {
    const url_ = this.baseUrl + `/companies/${id}/profiles/count`;
    return this.http.get<number>(url_);
  }
  getPowerBIProfile$(profileId: number): Observable<PowerBIProfileServerModel> {
    const url_ = this.routUrl + `/${profileId}`;
    return this.http.get<PowerBIProfileServerModel>(url_);
    //return of(this.profiles.find(x => x.id == profileId));
  }
  updatePowerBIProfile$(
    profile: PowerBIProfileServerModel
  ): Observable<PowerBIProfileServerModel> {
    return this.http.patch<PowerBIProfileServerModel>(this.routUrl, profile);
    // const index = this.profiles.findIndex(x => x.id == profile.id);
    // this.profiles[index] = profile;
    // return of(profile);
  }
  addPowerBIProfile$(
    profile: PowerBIProfileServerModel
  ): Observable<PowerBIProfileServerModel> {
    return this.http.post<PowerBIProfileServerModel>(this.routUrl, profile);
  }
  syncPBIProfile$(profileId: number): Observable<boolean> {
    const url_ = this.routUrl + `/${profileId}/sync`;
    return this.http.get<boolean>(url_);
  }
}
