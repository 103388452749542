import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  HostListener,
} from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Report } from "report";
import * as pbi from "powerbi-client";
import { PowerBIPageService } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/pages/service/power-bi-page.service";
import { PBIEmbeddedComponent } from "../pbi-embedded/pbi-embedded.component";
import { PageModel } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/pages/model/page.model";
import { NotifyService } from "@app/shared/services/notify.service";
import { UserAction, systemObject } from "@app/shared/model/user-action.enum";
import { PowerBIReportService } from "@app/power-bi/components/power-bi-details/workspace-details/reports/service/power-bi-report.service";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { DashboardVisualModel } from "../model/dashboard-visual.model";
import { PowerBIDashboardVisualService } from "../service/pbi-dashboard-visual.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DataStoryModel } from "../model/data-story.model";
import { ShareDatastoryComponent } from "../share-data-story/share-data-story.component";
import { ReportVisitService } from "@app/shared/services/reportVisit.service";

@Component({
  selector: "qx-render-report",
  templateUrl: "./render-report.component.html",
  styleUrls: ["./render-report.component.scss"],
})
export class RenderReportComponent implements OnInit, OnDestroy {
  selectedTabIndex = 0;
  activePage: PageModel = new PageModel();
  id: number;
  pageId: number;
  private sub: any;
  embedConfig: PowerBIEmbedConfig;
  pages: PageModel[] = [];
  visualHeaderVisible = false;
  showMore = false;
  expand = false;
  limit = 5;
  powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
    pbi.Report
  );

  @ViewChild(PBIEmbeddedComponent, { static: true })
  public embeddedReport: PBIEmbeddedComponent;
  private unsubscribe$$ = new Subject<void>();
  isFavourite: boolean;
  constructor(
    private reportService: PowerBIReportService,
    private dialog: MatDialog,
    private notifyService: NotifyService,
    private router: ActivatedRoute,
    private pageService: PowerBIPageService,
    private visualService: PowerBIDashboardVisualService,
    private reportVisitService: ReportVisitService
  ) {}
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth <= 540) {
      this.limit = 3;
    } else if (event.target.innerWidth <= 570) {
      this.limit = 4;
    } else {
      this.limit = 5;
    }
  }
  ngOnInit() {
    if (window.innerWidth <= 540) {
      this.limit = 3;
    } else if (window.innerWidth <= 570) {
      this.limit = 4;
    } else {
      this.limit = 5;
    }
    this.router.paramMap
      .pipe(takeUntil(this.unsubscribe$$))
      .subscribe((routeId) => {
        this.id = +routeId.get("id");
        this.pageService
          .getPages$(this.id, true)
          .toPromise()
          .then((pages) => {
            this.pages = pages;
          })
          .catch((err) => {
            if (err) {
              this.notifyService.error({
                crud: UserAction.Crud.get,
                object: systemObject.report,
              });
            }
          });
        this.reportService
          .getReportEmbedConfig$(this.id)
          .toPromise()
          .then((config) => {
            this.embedConfig = config;
            this.visualHeaderVisible = this.embedConfig.settings.visualSettings.visualHeaders[0].settings.visible;
            if (this.reportVisitService.pageId) {
              this.activePage = this.pages.find(
                (x) => x.id == +this.reportVisitService.pageId
              );
              this.embedConfig.pageName = this.activePage.token;
            } else {
              this.activePage = this.pages.find(
                (page) => page.token === this.embedConfig.pageName
              );
            }
          });
      });
  }

  pinToDashbaord() {
    const visual: DashboardVisualModel = {
      pageId: this.activePage.id,
      token: this.activePage.token,
      typeId: 1,
      height: 30,
      width: 30,
      left: 30,
      top: 30,
    };
    this.visualService
      .addDashboardVisual$(visual)
      .toPromise()
      .then((x) => {
        if (x) {
          this.notifyService.success({
            crud: UserAction.Crud.pin,
            object: systemObject.page,
          });
        } else {
          this.notifyService.error({
            crud: UserAction.Crud.pin,
            object: systemObject.page,
          });
        }
      })
      .catch((err) => {
        if (err) {
          this.notifyService.error({
            crud: UserAction.Crud.pin,
            object: systemObject.page,
          });
        }
      });
  }
  addRemoveFavourite() {
    if (this.activePage.isFavourite === true) {
      this.pageService
        .removeFavourite$(this.activePage.id)
        .toPromise()
        .then((x) => {
          this.activePage.isFavourite = !x;
        });
    } else {
      this.pageService
        .addFavourite$(this.activePage.id)
        .toPromise()
        .then((x) => {
          this.activePage.isFavourite = x;
        });
    }
  }
  fullScreen() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.fullscreen();
  }
  printReport() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.print();
  }
  removeVisualHeader() {
    this.visualHeaderVisible = !this.visualHeaderVisible;
    const newSettings = {
      visualSettings: {
        visualHeaders: [
          {
            settings: {
              visible: this.visualHeaderVisible,
            },
            // No selector - Hide visual header for all the visuals in the report
          },
        ],
      },
    };
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.updateSettings(newSettings);
  }
  ChangePage(event: PageModel) {
    this.activePage = event;
    this.reportVisitService.pageId=this.activePage.id;
    this.showMore = false;
    const newSettings = {
      filterPaneEnabled: this.activePage.filterPane,
      navContentPaneEnabled: false,
      background: this.activePage.background === true ? 0 : 1,

      visualSettings: {
        visualHeaders: [
          {
            settings: {
              visible: this.activePage.visualHeader,
            },
            // No selector - Hide visual header for all the visuals in the report
          },
        ],
      },
    };
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report
      .updateSettings(newSettings)
      .then((x) => {
        report
          .getPages()
          .then((pages) => {
            report
              .setPage(this.activePage.token)
              .then((succes) => {
                this.showMore = false;
                // this.selectedTabIndex = event.index;
              })
              .catch((errors) => {
                //event.index = this.selectedTabIndex;
              });
          })
          .catch((error) => {
            //event.index = this.selectedTabIndex;
          });
      })
      .catch((errr) => {
        //event.index = this.selectedTabIndex;
      });
  }
  getSlice(data: any[], skipTop: boolean) {
    if (skipTop) {
      if (data.length > this.limit) {
        return data.slice(0, this.limit);
      } else {
        return data;
      }
    } else {
      return data.slice(this.limit, data.length);
    }
  }
  activeInMore() {
    var index = this.pages.lastIndexOf(this.activePage);
    return index >= this.limit;
  }
  refreshReport() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.reload();
  }
  captureBookmark(audience: boolean) {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.bookmarksManager.capture().then((bookmark) => {
      const bmk: DataStoryModel = {};
      bmk.state = bookmark.state;
      bmk.pageId = this.activePage.id;
      this.dialog
        .open(ShareDatastoryComponent, {
          data: { dataStory: { ...{}, ...bmk }, audience: audience },
        })
        .afterClosed()
        .toPromise()
        .then((result) => {
          if (result) {
            if (UserAction.Crud.CreateAndShare === result) {
              this.notifyService.success({
                crud: UserAction.Crud.CreateAndShare,
                object: systemObject.dataStory,
              });
            } else {
            this.notifyService.success({
              crud: UserAction.Crud.create,
              object: systemObject.dataStory,
            });
          }
          }
        });
    });
  }
  refreshDataset() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.refresh();
  }
  ngOnDestroy() {
    this.unsubscribe$$.next();
    this.reportVisitService.pageId=0;
  }
}
