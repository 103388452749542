// Angular
import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../../partials/layout/quick-panel/service/notification.service";
import { OperationStatus } from "../../../partials/layout/quick-panel/model/notification.model";
import { AuthService } from "@app/core/services/Auth-service/auth.service";

@Component({
  selector: "kt-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"]
})
export class TopbarComponent implements OnInit {
  count: number = 0;
  constructor(public authService: AuthService, public service: NotificationService) { }
  ngOnInit() {
    this.count =
      this.service.alertsCount +
      this.service.notificationsCount +
      this.service.syncNotifications.length;
  }
  showProgress() {
    const notifications = this.service.syncNotifications.filter(
      x =>
        x.message.status.toLowerCase() !==
        OperationStatus.Completed.toLowerCase()
    );
    if (notifications && notifications.length > 0) {
      return true;
    }
    return false;
  }
}
