import { DataStoryServerModel } from "@app/core/services/data-story-service/model/data-story.server.model";

export class DataStoryModel {
  id?: number | undefined;
  pageId?: number | undefined;
  teamIds?: number[] | undefined;
  userIds?: number[] | undefined;
  title?: string | undefined;
  description?: string | undefined;
  state?: string | undefined;
  expiryDate?: Date | undefined;
  isPersonal?: boolean | undefined;
  constructor(item?: DataStoryServerModel) {
    if (item) {
      this.id = item.id;
      this.pageId = item.pageId;
      this.teamIds = item.teamIds ? item.teamIds : [];
      this.userIds = item.userIds ? item.userIds : [];
      this.title = item.title;
      this.description = item.description;
      this.state = item.state;
      this.expiryDate = item.expiryDate;
      this.isPersonal = item.isPersonal;
    }
  }
}
