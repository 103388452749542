import { IconModel } from "@app/icons/model/icon.model";
import { CatalogRestModel } from "@app/core/services/powerbi-server-service/model/catalog-rest.model";

export class Catalog {
  id: number;
  name: string;
  description: string;
  path: string;
  type: string;
  status: number;
  onPremId: string;
  iconId?: number | undefined;
  icon?: IconModel | undefined;
  parentFolderId: string;
  isFavorite: boolean;
  tags?: string[] | undefined;
  constructor(catalog?: CatalogRestModel) {
    if (catalog) {
      this.id = catalog.id;
      this.name = catalog.name;
      this.description = catalog.description;
      this.path = catalog.path;
      this.type = catalog.type;
      this.status = catalog.status;
      this.onPremId = catalog.onPremId;
      this.parentFolderId = catalog.parentFolderId;
      this.isFavorite = catalog.isFavorite;
      this.iconId = catalog.iconId;
      this.icon = new IconModel(catalog.icon);
      this.tags = catalog.tags;
    }
  }
}
