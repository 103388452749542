import { Injectable } from "@angular/core";

@Injectable()
export class AppConfigService {
  constructor() {}
  public transformPic(model: any) {
    let str = "";
    if (model.pic) {
      str = `${model.pic}`;
    } else if (model.logo) {
      str = `${model.logo}`;
    } else {
      if (model.email) {
        const string = model.email.trim().split(" ");
        if (string.length === 1) {
          str = str + string[0][0].toUpperCase();
        } else {
          str = str + string[0][0].toUpperCase() + string[1][0].toUpperCase();
        }
      } else if (model.name) {
        const string = model.name.trim().split(" ");
        if (string.length === 1) {
          str = str + string[0][0].toUpperCase();
        } else {
          str = str + string[0][0].toUpperCase() + string[1][0].toUpperCase();
        }
      } else {
        str = model;
      }
    }
    return str;
  }
  public getRandomColor(str: string, alpha: number): string {
    if (str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line:no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = "#";
      for (let i = 0; i < 3; i++) {
        // tslint:disable-next-line:no-bitwise
        const value = (hash >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).substr(-2);
      }
      return this.hexToHSLA(color, alpha);
    }
  }
  public hexToHSLA(H, alpha) {
    // Convert hex to RGB first
    // tslint:disable-next-line:one-variable-per-declaration
    let r: any, g: any, b: any;
    if (H.length === 4) {
      r = "0x" + H[1] + H[1];
      g = "0x" + H[2] + H[2];
      b = "0x" + H[3] + H[3];
    } else if (H.length === 7) {
      r = "0x" + H[1] + H[2];
      g = "0x" + H[3] + H[4];
      b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    // tslint:disable-next-line:one-variable-per-declaration
    const cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin;
    // tslint:disable-next-line:one-variable-per-declaration
    let h = 0,
      s = 0,
      l = 0;

    // tslint:disable-next-line:max-line-length
    if (delta === 0) {
      h = 0;
    } else if (cmax === r) {
      h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    if (h < 0) {
      h += 360;
    }

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return "hsl(" + h + "," + s + "%," + l + "%," + alpha + ")";
  }
  fileExist(path: string) {
    //const fileExists = require("file-exists");
    return true;
    //return fsync(`assets/media/users/${path}`);
    // return existsSync(`/assets/media/users/${path}`);
  }
}
export function deepCopy<T>(obj: T): T {
  const stObj = JSON.stringify(obj);
  try {
    return JSON.parse(stObj);
  } catch (err) {
    return { ...{}, ...obj };
  }
}
