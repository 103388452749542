import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../../base.service";
import { PowerBIServerProfileRestModel } from "../model/power-bi-server-profile.model";

@Injectable({
  providedIn: "root",
})
export class PowerBIServerProfileRestService extends BaseService {
  routUrl = "";

  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + `/powerbionprem/profiles`;
  }
  getPremProfiles$(): Observable<PowerBIServerProfileRestModel[]> {
    return this.http.get<PowerBIServerProfileRestModel[]>(this.routUrl);
    //return of(this.profiles);
  }
  getCompanyPremProfilesCount$(id: number): Observable<number> {
    const url_ = this.baseUrl + `/companies/${id}/profiles/count`;
    return this.http.get<number>(url_);
  }
  getPremProfile$(
    profileId: number
  ): Observable<PowerBIServerProfileRestModel> {
    const url_ = this.routUrl + `/${profileId}`;
    return this.http.get<PowerBIServerProfileRestModel>(url_);
    //return of(this.profiles.find(x => x.id == profileId));
  }
  updatePremProfile$(
    profile: PowerBIServerProfileRestModel
  ): Observable<PowerBIServerProfileRestModel> {
    return this.http.patch<PowerBIServerProfileRestModel>(
      this.routUrl,
      profile
    );
    // const index = this.profiles.findIndex(x => x.id == profile.id);
    // this.profiles[index] = profile;
    // return of(profile);
  }
  addPremProfile$(
    profile: PowerBIServerProfileRestModel
  ): Observable<PowerBIServerProfileRestModel> {
    return this.http.post<PowerBIServerProfileRestModel>(this.routUrl, profile);
  }
  syncPremProfile$(profileId: number): Observable<boolean> {
    const url_ = this.routUrl + `/${profileId}/sync`;
    return this.http.get<boolean>(url_);
  }
}
