import { NgModule, Injectable, ComponentFactoryResolver } from "@angular/core";

import { FlexLayoutModule } from "@angular/flex-layout";

import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatDatetimepickerModule,
  MatNativeDatetimeModule,
} from "@mat-datetimepicker/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, TitleCasePipe } from "@angular/common";

import { GridModule } from "@progress/kendo-angular-grid";
import { NgxStripeModule } from "ngx-stripe";
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ToastrModule } from "ngx-toastr";
import { PipeModule } from "./pipes.module";
import { FeatureStatsComponent } from "../components/feature-stats/feature-stats.component";
import { AppConfigService } from "../services/app-config.service";
import { SharedInvoicesComponent } from "../components/invoices/invoices.component";
import { RefundProrateComponent } from "../components/invoices/refund-prorate/refund-prorate.component";
import { PortletModule } from "@theme/metronic/app/views/partials/content/general/portlet/portlet.module";
import { SharedWorkspaceComponent } from "../components/shared-workspaces/shared-workspace/shared-workspace.component";
import { SharedWorkspacesComponent } from "../components/shared-workspaces/shared-workspaces.component";
import { RouterModule } from "@angular/router";
import { AddUpdateCompanyComponent } from "../components/add-update-company/add-update-company.component";
import { AssignUsersComponent } from "../components/assign-users/assign-users.component";
import { UsersUsageComponent } from "@app/payment-management/components/suscriptions/usage/users-usage/users-usage.component";
import { UsageService } from "@app/payment-management/components/suscriptions/service/usage.service";
import { ReportsUsageComponent } from "@app/payment-management/components/suscriptions/usage/reports-usage/reports-usage.component";
import { AssignReportsComponent } from "../components/assign-reports/assign-reports.component";
import { DashboardsUsageComponent } from "@app/payment-management/components/suscriptions/usage/dashboards-usage/dashboards-usage.component";
import { AssignDashboardsComponent } from "../components/assign-dashboards/assign-dashboards.component";
import { CompanyService } from "@app/company-management/service/company.service";
import { NotificationsSharedComponent } from "../components/notifications-shared/notifications-shared.component";
import { FilesUploadComponent } from "../components/files-upload/files-upload.component";
import { UploadModule } from "@progress/kendo-angular-upload";
import { FileService } from "../components/files-upload/services/files.service";
import { ProfileComponent } from "../../power-bi/components/profiles/profile/profile.component";
import { ProfilesComponent } from "../../power-bi/components/profiles/profiles.component";
import { ProfileCardComponent } from "../../power-bi/components/profiles/profile-card/profile-card.component";
import { ProfileService } from "../../power-bi/components/profiles/services/profile.service";
import { ConfirmActionDialogComponent } from "../components/confirm-action-dialog/confirm-action-dialog.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { IconService } from "@app/icons/service/icon.service";
import { DateInputModule } from "@progress/kendo-angular-dateinputs";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { NgSelectModule } from '@ng-select/ng-select';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatOptionModule, MatNativeDateModule } from "@angular/material/core";
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatListModule } from "@angular/material/list";
import { ReportHeaderSkeletonComponent } from "../components/report-header-skeleton/report-header-skeleton.component";
import { environment } from "src/environments/environment";

@NgModule({
  imports:
    [
      RouterModule,
      CommonModule,
      NgSelectModule,
      ///TemplateModule,
      PipeModule,
      // Modules
      FlexLayoutModule,
      ScrollingModule,
      MatButtonModule,
      MatDialogModule,
      MatInputModule,
      MatDatepickerModule,
      // ToastrModule,
      // MatSnackBarModule,
      MatGridListModule,
      MatFormFieldModule,
      MatOptionModule,
      MatSelectModule,
      MatCardModule,
      MatDividerModule,
      MatChipsModule,
      MatIconModule,
      MatExpansionModule,
      MatListModule,
      MatCheckboxModule,
      FormsModule,
      ReactiveFormsModule,
      MatMenuModule,
      MatRadioModule,
      MatPaginatorModule,
      MatProgressSpinnerModule,
      MatTabsModule,
      MatSlideToggleModule,
      MatNativeDateModule,
      MatNativeDatetimeModule,
      MatDatetimepickerModule,
      MatProgressBarModule,
      MatAutocompleteModule,
      // Bootstrap
      NgbProgressbarModule,
      NgxMatSelectSearchModule,
      // Kendo
      GridModule,
      InputsModule,
      UploadModule,
      PortletModule,
        // Stripe
      NgxStripeModule.forRoot(environment.stripeKey),

    ],
  declarations: [
    FeatureStatsComponent,
    ReportsUsageComponent,
    UsersUsageComponent,
    DashboardsUsageComponent,
    AssignUsersComponent,
    AssignReportsComponent,
    AssignDashboardsComponent,
    AddUpdateCompanyComponent,
    SharedWorkspacesComponent,
    SharedWorkspaceComponent,
    RefundProrateComponent,
    SharedInvoicesComponent,
    NotificationsSharedComponent,
    FilesUploadComponent,
    ProfileComponent,
    ProfilesComponent,
    ProfileCardComponent,
    ConfirmActionDialogComponent,
    ReportHeaderSkeletonComponent
  ],
  providers: [
    TitleCasePipe,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: "100vw",
        width: "30%",
        height: "100%",
        hasBackdrop: true,
        disableClose: true,
        position: { right: "0", top: "0", bottom: "0" },
        closeOnNavigation: true,
      },
    },
    AppConfigService,
    CompanyService,
    UsageService,
    FileService,
    ProfileService,
    IconService,
  ],
  exports: [
    CommonModule,
    NgSelectModule,
    //TemplateModule,
    // Modules
    FlexLayoutModule,
    ScrollingModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    ToastrModule,
    // MatSnackBarModule,
    MatGridListModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    MatDividerModule,
    MatChipsModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatRadioModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatNativeDatetimeModule,
    MatDatetimepickerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    // Bootstrap
    NgbProgressbarModule,
    NgxMatSelectSearchModule,
    // Kendo
    GridModule,
    InputsModule,
    DateInputModule,
    UploadModule,
    FeatureStatsComponent,
    ReportsUsageComponent,
    UsersUsageComponent,
    DashboardsUsageComponent,
    AssignUsersComponent,
    AssignReportsComponent,
    AssignDashboardsComponent,
    AddUpdateCompanyComponent,
    SharedWorkspaceComponent,
    SharedWorkspacesComponent,
    SharedInvoicesComponent,
    NotificationsSharedComponent,
    RefundProrateComponent,
    FilesUploadComponent,
    ProfileComponent,
    ProfilesComponent,
    ProfileCardComponent,
    ReportHeaderSkeletonComponent,
    PipeModule,
  ],
  entryComponents: [
    RefundProrateComponent,
    SharedWorkspaceComponent,
    UsersUsageComponent,
    ReportsUsageComponent,
    DashboardsUsageComponent,
    ProfileComponent,
    ConfirmActionDialogComponent,
  ],
})
export class SharedModules {
  constructor(
    private usageSer: UsageService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    if (this.componentFactoryResolver["_factories"]?.keys()) {
      this.usageSer.registerComponents(
        Array.from(this.componentFactoryResolver["_factories"].keys())
      );
    }
  }
}
