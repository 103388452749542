import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { UserServerModel } from "../model/user.server.model";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../../base.service";
import { UpdatePasswordModel } from "@app/users-management/model/update-password.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";
import { authConfig } from "@app/core/config-objects/sso-config";
import { UserModel } from "@app/users-management/model/user.model";

@Injectable({ providedIn: "root" })
export class UserRestService extends BaseService {
  routUrl = "";
  idsUrl = "";


  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + "/users";
    this.idsUrl = authConfig.issuer;
  }

  getUsers$(
    pageNumber: number,
    pageSize: number,
    roleIds: number[],
    teamIds: number[],
    query: string,
    getTotal: boolean
  ): Observable<PageResultModel<UserServerModel>> {
    let url_ = this.baseUrl + "/users/company?";

    if (pageNumber !== undefined)
      url_ += "page=" + encodeURIComponent("" + pageNumber) + "&";

    if (pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";

    if (roleIds !== undefined)
      roleIds &&
        roleIds.forEach((item) => {
          url_ += "roleIds=" + encodeURIComponent("" + item) + "&";
        });

    if (teamIds !== undefined)
      teamIds &&
        teamIds.forEach((item) => {
          url_ += "teamIds=" + encodeURIComponent("" + item) + "&";
        });

    if (query !== undefined)
      url_ += "searchTerm=" + encodeURIComponent("" + query) + "&";
    url_ += "getTotal=" + encodeURIComponent("" + getTotal);
    return this.http.get<PageResultModel<UserServerModel>>(url_);
  }
  syncUsers$():Observable<UserServerModel[]>{

    let url_ = this.baseUrl + '/company/users/ad';
    return this.http.get<UserServerModel[]>(url_);

  }
  getAssociatedUsers$(pageId: number): Observable<UserServerModel[]> {
    let url_ = this.baseUrl + `/pages/${pageId}/users`;
    return this.http.get<UserServerModel[]>(url_);
  }
  getUsersByCompany$(
    companyId: number,
    pageNumber: number,
    pageSize: number,
    roleIds: number[],
    query: string,
    getTotal: boolean
  ): Observable<PageResultModel<UserServerModel>> {
    let url_ = this.baseUrl + `/companies/${companyId}/users` + "?";

    if (pageNumber !== undefined)
      url_ += "page=" + encodeURIComponent("" + pageNumber) + "&";

    if (pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";

    if (roleIds !== undefined)
      roleIds &&
        roleIds.forEach((item) => {
          url_ += "roleIds=" + encodeURIComponent("" + item) + "&";
        });

    if (query !== undefined)
      url_ += "searchTerm=" + encodeURIComponent("" + query) + "&";
    url_ += "getTotal=" + encodeURIComponent("" + getTotal);
    return this.http.get<PageResultModel<UserServerModel>>(url_);
  }
  getCompanyUsers$(
    pageNumber: number,
    pageSize: number,
    teamIds: number[],
    roleIds: number[],
    query: string,
    getTotal: boolean,
  ): Observable<PageResultModel<UserServerModel>> {
    let url_ = this.routUrl + "/company" + "?";

    if (pageNumber !== undefined)
      url_ += "page=" + encodeURIComponent("" + pageNumber) + "&";

    if (pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";

    if (roleIds !== undefined)
      roleIds &&
        roleIds.forEach((item) => {
          url_ += "roleIds=" + encodeURIComponent("" + item) + "&";
        });

    if (teamIds !== undefined)
      teamIds &&
        teamIds.forEach((item) => {
          url_ += "teamIds=" + encodeURIComponent("" + item) + "&";
        });

    if (query !== undefined)
      url_ += "searchTerm=" + encodeURIComponent("" + query) + "&";
    if (getTotal !== undefined)
      url_ += "getTotal=" + encodeURIComponent("" + getTotal);
    return this.http.get<PageResultModel<UserServerModel>>(url_);
  }
  getUser$(userId: number): Observable<UserServerModel> {
    const url_ = this.routUrl + `/${userId}`;
    return this.http.get<UserServerModel>(url_);
  }
  getCurrentUserDetail$(): Observable<UserServerModel> {
    const url_ = this.routUrl + `/my/details`;
    return this.http.get<UserServerModel>(url_);
  }
  updatePassword$(password: UpdatePasswordModel): Observable<boolean> {
    const url_ = this.idsUrl + "/Account/UpdatePassword";
    var formData = new FormData();
    formData.append("oldPassword", password.oldPassword);
    formData.append("newPassword", password.newPassword);
    formData.append("Email", password.Email);
    return this.http.post<boolean>(url_, formData);
  }
  getCompanyUsersCount$(): Observable<number> {
    const url_ = this.routUrl + "/company/count";
    return this.http.get<number>(url_);
  }
  getUsersCountByCompany$(companyId: number): Observable<number> {
    const url_ = this.baseUrl + `/companies/${companyId}/users/count`;
    return this.http.get<number>(url_);
  }
  createUser$(user: UserServerModel): Observable<UserServerModel> {
    return this.http.post<UserServerModel>(this.routUrl, user);
  }
  updateUser$(user: UserServerModel): Observable<UserServerModel> {
    return this.http.put<UserServerModel>(this.routUrl, user);
  }
  updateCompanyUser$(user: UserServerModel): Observable<UserServerModel> {
    const url_ = this.baseUrl + "/users/company";
    return this.http.put<UserServerModel>(url_, user);
  }
  updateSyncUsers$(users:UserServerModel[]){
    const url_ = this.baseUrl + "/company/users/sync";
    return this.http.post<UserServerModel[]>(url_,users);
  }
  createCompanyUser$(user: UserServerModel): Observable<UserServerModel> {
    const url_ = this.baseUrl + "/users/company";
    return this.http.post<UserServerModel>(url_, user);
  }
  updateMyDetails$(user: UserServerModel): Observable<UserServerModel> {
    const url_ = this.routUrl + "/my";
    return this.http.put<UserServerModel>(url_, user);
  }
  uploadPhoto$(file: File): Observable<UserServerModel> {
    const url_ = this.baseUrl + "/users/profile-image";
    const uploadData = new FormData();
    uploadData.append("image", file, file.name);
    return this.http.post<UserServerModel>(url_, uploadData);
  }
}
