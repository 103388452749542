import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { TeamServerModel } from "../model/team.server.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable({ providedIn: "root" })
export class TeamRestService extends BaseService {
  routUrl = "";
  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + "/teams";
  }

  getTeams$(
    page: number,
    pageSize: number,
    query: string,
    getTotal: boolean
  ): Observable<PageResultModel<TeamServerModel>> {
    let url_ = this.routUrl + "?";
    if (page != undefined)
      url_ += "page=" + encodeURIComponent("" + page) + "&";
    if (pageSize != undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";
    if (query != undefined)
      url_ += "name=" + encodeURIComponent("" + query) + "&";
    if (getTotal != undefined)
      url_ += "getTotal=" + encodeURIComponent("" + getTotal) + "&";

    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<PageResultModel<TeamServerModel>>(url_);
  }

  createTeam$(team: TeamServerModel): Observable<TeamServerModel> {
    return this.http.post<TeamServerModel>(this.routUrl, team);
  }
  updateTeam$(team: TeamServerModel): Observable<TeamServerModel> {
    return this.http.put<TeamServerModel>(this.routUrl, team);
  }
  updateTeamPages$(id: number, pageIds: number[]): Observable<TeamServerModel> {
    const url_ = this.routUrl + `/${id}/reports/pages`;
    return this.http.put<TeamServerModel>(url_, pageIds);
  }
  updateTeamUsers$(id: number, userIds: number[]): Observable<TeamServerModel> {
    const url_ = this.routUrl + `/${id}/users`;
    return this.http.put<TeamServerModel>(url_, userIds);
  }
  updateTeamPremReports$(
    id: number,
    reportIds: number[]
  ): Observable<TeamServerModel> {
    const url_ = this.routUrl + `/${id}/prem-pages`;
    return this.http.put<TeamServerModel>(url_, reportIds);
  }
  updateTeamDashboards$(
    id: number,
    dashboardIds: number[]
  ): Observable<TeamServerModel> {
    const url_ = this.routUrl + `/${id}/dashboards`;
    return this.http.put<TeamServerModel>(url_, dashboardIds);
  }
}
