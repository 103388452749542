import { Injectable } from "@angular/core";
import { PBIDashboardModel } from "../model/pbi-dashboard.model";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { PowerBIDashboardRestService } from "@app/core/services/dashboard-service/services/power-bi-dashboard.rest.service";
import { PBIDashboardServerModel } from "@app/core/services/dashboard-service/models/dashboard.server.model";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable()
export class PowerBIDashboardService {
  count: number;
  constructor(private service: PowerBIDashboardRestService) {}
  getAllDashboards$(
    page: number,
    pageSize: number,
    query: string,
    field: string,
    order: string,
    getTotal: boolean,
    status: boolean
  ): Observable<PBIDashboardModel[]> {
    return this.service
      .getAllDashboards$(page, pageSize, query, field, order, getTotal, status)
      .pipe(
        map((res: PageResultModel<PBIDashboardServerModel>) => {
          if (getTotal) this.count = res.total;
          return res.result.map((x) => new PBIDashboardModel(x));
        })
      );
  }
  getDashboards$(workspaceId: number, search?: string): Observable<PBIDashboardServerModel[]> {
    return this.service.getDashboards$(workspaceId, search).pipe(
      map((res) => {
        return res.result.map((x) => new PBIDashboardModel(x));
      })
    );
  }
  getRecentDashboards$(): Observable<PBIDashboardModel[]> {
    return this.service.getRecentDashboards$().pipe(
      map((res: PBIDashboardModel[]) => {
        return res.map((x) => new PBIDashboardModel(x));
      })
    );
  }
  getFrequentDashboards$(): Observable<PBIDashboardModel[]> {
    return this.service.getFrequentDashboards$().pipe(
      map((res: PBIDashboardModel[]) => {
        return res.map((x) => new PBIDashboardModel(x));
      })
    );
  }
  getDashboardsCount$(): Observable<number> {
    return this.service.getDashboardsCount$();
  }
  getDashboardsCountByStatus$(status: number): Observable<number> {
    return this.service.getDashboardsCountByStatus$(status);
  }
  getDashboard$(id: number): Observable<PBIDashboardModel> {
    return this.service
      .getDashboard$(id)
      .pipe(map((res: PBIDashboardServerModel) => new PBIDashboardModel(res)));
  }
  updateDashboard$(
    Dashboard: PBIDashboardModel
  ): Observable<PBIDashboardModel> {
    return this.service
      .updateDashboard$(Dashboard)
      .pipe(map((res: PBIDashboardServerModel) => new PBIDashboardModel(res)));
  }
  remove$(dashboard: PBIDashboardModel): Observable<boolean> {
    return this.service.remove$(dashboard);
  }
  syncDashboard$(id: number): Observable<boolean> {
    return this.service.syncDashboard$(id);
  }
  getDashboardEmbedConfig$(
    dashboardId: number
  ): Observable<PowerBIEmbedConfig> {
    return this.service.getDashboardEmbedConfig$(dashboardId);
  }
}
