// Angular
import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ReportVisitService } from "@app/shared/services/reportVisit.service";

export interface ISearchResult {
  icon: string;
  parent?: number | undefined | null;
  img: string;
  type: number;
  title: string;
  text: string;
  url: string;
}

@Component({
  selector: "kt-search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.scss"],
})
export class SearchResultComponent {
  // Public properties
  @Input() data: ISearchResult[];
  @Input() noRecordText: string;
  @Output() clicked = new EventEmitter<any>();
  constructor(
    private _router: Router,
    private reportVisitService: ReportVisitService
  ) {}
  goToUrl(item: any) {
    if (item.parent !== undefined && item.parent !== null) {
      this.reportVisitService.pageId = item.id;
    }
    this._router.navigateByUrl(item.url);
    this.clicked.emit(true);
    // const btn = (document.getElementById(
    //   "search-dp"
    // ) as unknown) as HTMLElement;
    // btn..click();
  }
}
