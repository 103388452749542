import { systemObject } from "@app/shared/model/user-action.enum";
import { PageModel } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/pages/model/page.model";
import { BookmarkModel } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/bookmarks/model/bookmark.model";
import { ReportDetailsServerModel } from "../../report-service/models/report.server.model";
import {
  DashboardIcon,
  ReportIcon,
  PageIcon,
  BookmarkIcon,
} from "@app/core/config-objects/default-icons";

export class HorizontalMenuModel {
  id?: number | undefined;
  reportId?: number | undefined;
  pageId?: number | undefined;
  title?: string | undefined;
  description?: string | undefined;
  root?: boolean | undefined;
  alignment?: string | undefined;
  page?: string | undefined;
  bullet = "dot";
  icon?: string | undefined;
  isPersonal?: boolean | undefined;
  isSvg?: boolean | undefined;
  expiryDate?: string | undefined;
  submenu?: any[];
  constructor(item?: any, obj?: systemObject) {
    if (item) {
      this.id = item.id;
      this.description = item.description ? item.description : undefined;
      this.reportId = item.reportId ? item.reportId : null;
      this.pageId = item.pageId ? item.pageId : null;
      this.title =
        obj == systemObject.dataStory
          ? item.title
          : !systemObject.serverReport
          ? item.displayName
          : item.name;
      this.alignment = "right";
      this.page =
        obj && obj === systemObject.serverReport
          ? `/powerbi-server-render/id/${item.id}`
          : obj
          ? `/render/${obj.toLowerCase().replace(" ", "")}/${item.id}`
          : "/dashboard";
      switch (obj) {
        case systemObject.dashboard: {
          this.icon =
            item.icon && item.icon.url ? item.icon.url : DashboardIcon;
          this.isSvg = true;
          break;
        }
        case systemObject.report: {
          this.icon = item.icon && item.icon.url ? item.icon.url : ReportIcon;
          this.isSvg = true;
          break;
        }
        case systemObject.page: {
          this.icon = item.icon && item.icon.url ? item.icon.url : PageIcon;
          this.isSvg = true;
          break;
        }
        case systemObject.bookmark: {
          this.icon = item.icon && item.icon.url ? item.icon.url : BookmarkIcon;
          this.isSvg = true;
          break;
        }
        case systemObject.dataStory: {
          this.icon = "flaticon2-paper";
          this.isPersonal = item.isPersonal;
          this.isSvg = false;
          this.expiryDate = new Date(item.expiryDate).toDateString();
          break;
        }
        case systemObject.serverReport: {
          this.icon = item.icon && item.icon.url ? item.icon.url : ReportIcon;
          this.isSvg = true;
          break;
        }
      }
    }
  }
}

export class ReportDetailsModel {
  id?: number | undefined;
  workspaceId?: number | undefined;
  name?: string | undefined;
  token?: string | undefined;
  displayName?: string | undefined;
  status?: number | undefined;
  authenticationMethodId?: number | undefined;
  isEffectiveRoleRequired?: boolean | undefined;
  isEffectiveIdentityRequired?: boolean | undefined;
  isOnPrimGateway?: boolean | undefined;
  isRefreshableReport?: boolean | undefined;
  reportRole?: string | undefined;
  dataSourceTypeId?: number | undefined;
  powerBITemplateId?: number | undefined;
  pages?: PageModel[] | undefined;
  bookmarks?: BookmarkModel[] | undefined;
  constructor(report?: ReportDetailsServerModel) {
    if (report) {
      this.id = report.id;
      this.workspaceId = report.workspaceId;
      this.name = report.name;
      this.token = report.token;
      this.displayName = report.displayName;
      this.status = report.status;
      this.authenticationMethodId = report.authenticationMethodId;
      this.isEffectiveRoleRequired = report.isEffectiveRoleRequired;
      this.isEffectiveIdentityRequired = report.isEffectiveIdentityRequired;
      this.isOnPrimGateway = report.isOnPrimGateway;
      this.isRefreshableReport = report.isRefreshableReport;
      this.reportRole = report.reportRole;
      this.dataSourceTypeId = report.dataSourceTypeId;
      this.powerBITemplateId = report.powerBITemplateId;

      this.pages = report.pages
        ? report.pages.map((x) => new PageModel(x))
        : [];
      this.bookmarks = report.bookmarks
        ? report.bookmarks.map((x) => new BookmarkModel(x))
        : [];
    }
  }
}
