import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { IndividualConfig } from "ngx-toastr";
import { LocalStorageDataService } from "./local-storage.service";
import { MessageParams } from "../model/messageParams";
import { TitleCasePipe } from "@angular/common";
import { notifyTitle } from "@shared/model/user-action.enum";
@Injectable({ providedIn: "root" })
export class NotifyService {
  public config: Partial<IndividualConfig> = {
    progressBar: true,
    tapToDismiss: true,
    closeButton: true,
    enableHtml: true,
    extendedTimeOut: 2000,
    progressAnimation: "increasing",
    positionClass: "toast-bottom-right"
  };

  constructor(
    private toastrService: ToastrService,
    private titleCasePipe: TitleCasePipe,
    private localStorageService: LocalStorageDataService
  ) {}

  async success(param: MessageParams) {
    param.type = "success";
    if (param.message) {
      this.toastrService.success(param.message, "Success", this.config);
      return;
    }
    if (param.crud) {
      this.toastrService.success(
          this.localStorageService.getMessage(param),
        param.crud
          ? `${this.titleCasePipe.transform(param.object).replace("Dns", "DNS")} ${this.titleCasePipe.transform(notifyTitle[param.crud])}`
          : "Success",
        this.config
      );
    }
  }

  async error(param: MessageParams) {
    param.type = "error";
    if (param.message) {
      this.toastrService.error(param.message, "Error", this.config);
      return;
    }
    this.toastrService.error(
      this.localStorageService.getMessage(param),
      param.crud
        ? `${this.titleCasePipe.transform(param.object).replace("Dns", "DNS")} ${this.titleCasePipe.transform(notifyTitle[param.crud])}`
        : "Error",
      this.config
    );
  }

  info(message?: string, title?: string) {
    this.toastrService.info(message, "Info", this.config);
  }

  warning(message?: string, title?: string) {
    this.toastrService.warning(message, "Warning", this.config);
  }

  clear(toastId?: number) {
    this.toastrService.clear(toastId);
  }

  remove(toastId: number) {
    this.toastrService.clear(toastId);
  }
}
