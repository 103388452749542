import { Injectable } from "@angular/core";
import { PowerBIReportRestService } from "../../report-service/services/power-bi-report.rest.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  HorizontalMenuModel,
  ReportDetailsModel,
} from "../model/horizontal-menu.model";
import { systemObject } from "@app/shared/model/user-action.enum";
import { PowerBIDashboardRestService } from "../../dashboard-service/services/power-bi-dashboard.rest.service";
import { PowerBIPageRestService } from "../../page-service/services/power-bi-page.rest.service";
import { PowerBIBookmarkRestService } from "../../bookmark-service/services/power-bi-bookmark.rest.service";
import { DataStoryRestService } from "../../data-story-service/service/data-story.rest.service";
import { PowerBIServerService } from "@app/powerbi-server/services/powerbi-server.service";

@Injectable({
  providedIn: "root",
})
export class UserMenuRestService {
  bookmarks: HorizontalMenuModel[] = [];
  pages: HorizontalMenuModel[] = [];
  constructor(
    private reportService: PowerBIReportRestService,
    private dashboardService: PowerBIDashboardRestService,
    private pageService: PowerBIPageRestService,
    private bmkService: PowerBIBookmarkRestService,
    private dsService: DataStoryRestService,
    private premService: PowerBIServerService
  ) {}
  getUserDashboards$(): Observable<HorizontalMenuModel[]> {
    return this.dashboardService
      .getUserDashboards$()
      .pipe(
        map((resp) =>
          resp.map((x) => new HorizontalMenuModel(x, systemObject.dashboard))
        )
      );
  }
  getUserReports$(): Observable<ReportDetailsModel[]> {
    return this.reportService.getUserReports$().pipe(
      map((resp) => {
        //  this.bookmarks= resp.map(x => new HorizontalMenuModel(x., systemObject.bookmark))
        return resp.map((x) => new ReportDetailsModel(x));
      })
    );
  }
  getOnPremReports$(): Observable<HorizontalMenuModel[]> {
    return this.premService.getActiveReports$().pipe(
      map((resp) => {
        //  this.bookmarks= resp.map(x => new HorizontalMenuModel(x., systemObject.bookmark))
        return resp.map(
          (x) => new HorizontalMenuModel(x, systemObject.serverReport)
        );
      })
    );
  }
  getMyOnPremReports$(): Observable<HorizontalMenuModel[]> {
    return this.premService.getMyOnPremReports$().pipe(
      map((resp) => {
        //  this.bookmarks= resp.map(x => new HorizontalMenuModel(x., systemObject.bookmark))
        return resp.map(
          (x) => new HorizontalMenuModel(x, systemObject.serverReport)
        );
      })
    );
  }
  getUserPages$(reportId: number): Observable<HorizontalMenuModel[]> {
    return this.pageService
      .getUserPages$(reportId)
      .pipe(
        map((resp) =>
          resp.map((x) => new HorizontalMenuModel(x, systemObject.page))
        )
      );
  }
  getUserBookmarks$(): Observable<HorizontalMenuModel[]> {
    return this.bmkService
      .getUserBookmarks$()
      .pipe(
        map((resp) =>
          resp.map((x) => new HorizontalMenuModel(x, systemObject.bookmark))
        )
      );
  }
  getUserDataStories$(): Observable<HorizontalMenuModel[]> {
    return this.dsService
      .getUserDataStories$()
      .pipe(
        map((resp) =>
          resp.map((x) => new HorizontalMenuModel(x, systemObject.dataStory))
        )
      );
  }
}
