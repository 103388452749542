import { T } from "@angular/cdk/keycodes";

export class PageResultModel<T> {
  result?: T[] | undefined;
  total?: number | undefined;
  constructor(item?: PageResultModel<T>) {
    if (item) {
      this.result = item.result;
      this.total = item.total;
    }
  }
}
