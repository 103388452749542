<!-- BEGIN: Horizontal Menu -->
<div ktOffcanvas
	 [options]="offcanvasOptions"
	 class="kt-header-menu-wrapper"
	 id="kt_header_menu_wrapper">
	<div ktMenu
		 [options]="menuOptions"
		 id="kt_header_menu"
		 class="kt-header-menu kt-header-menu-mobile"
		 [ngClass]="htmlClassService.getClasses('header_menu', true)">
		<ul class="kt-menu__nav"
			[ngClass]="htmlClassService.getClasses('header_menu_nav', true)">
			<ng-container *ngFor="let item of menuHorService.menuList$ | async; let i = index;">
				<ng-container *ngIf="item.title && ((item.submenu && item.submenu.length>0))"
							  [ngTemplateOutlet]="menuTemplate"
							  [ngTemplateOutletContext]="{ item: item }"></ng-container>
			</ng-container>
		</ul>
	</div>
</div>
<!-- END: Horizontal Menu -->


<ng-template #menuTemplate
			 let-item="item"
			 let-parentItem="parentItem">
	<li [attr.aria-haspopup]="true"
		[attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)"
		[ngClass]="getItemCssClasses(item)"
		[class]="isDatastory(item)?'d-flex flex-column pb-0':''">

		<!-- if item has submenu -->
		<ng-container *ngIf="item.submenu">
			<a href="javascript:;"
			   class="kt-menu__link kt-menu__toggle">

				<ng-container [ngTemplateOutlet]="menuItemInnerTemplate"
							  [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>


				<ng-container *ngIf="rootArrowEnabled">
					<!-- arrow icons -->
					<i *ngIf="item.submenu && item.root"
					   class="kt-menu__hor-arrow la la-angle-down"></i>
					<i *ngIf="item.submenu && item.root"
					   class="kt-menu__ver-arrow la la-angle-right"></i>
				</ng-container>
				<!-- else arrow icons -->
				<i *ngIf="item.submenu && !item.root"
				   class="kt-menu__hor-arrow la la-angle-right"></i>
				<i *ngIf="item.submenu && !item.root"
				   class="kt-menu__ver-arrow la la-angle-right"></i>

			</a>
		</ng-container>

		<!-- if item hasn't sumbenu -->


		<!--If Report Menu-->
		<ng-container *ngIf="!item.submenu">
			<div
				 class="d-flex align-items-center justify-content-space-between w-100 m--padding-5 menu-link menu-toggle">
				<div [routerLink]="item.page"
					 class="d-flex align-items-center">
					<!--begin::Symbol-->

					<div class="symbol symbol-40 symbol-light-primary text-light m--margin-right-5">
						<span class="symbol-label">
							<span class="svg-icon svg-icon-xl svg-icon-primary">
								<!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Home/Library.svg-->
								<img *ngIf="item.isSvg"
									 class="kt-menu__link-icon pr-2 max-width-20"
									 [src]="item.icon">
								<i *ngIf="item.icon && !item.isSvg"
								   class="kt-menu__link-icon"
								   [ngClass]="item.icon"></i>
								<!--end::Svg Icon-->
							</span>
						</span>
					</div>
					<!--end::Symbol-->
					<!--begin::Text-->
					<div class="d-flex flex-column font-weight-bold"
						 title="{{item.description ? item.description:''}}">
						<a href="javascript:;"
						   class="text-light  text-hover-primary  font-size-lg">{{item.title}}</a>
						<span class="text-muted text-ellipses"
							  *ngIf="item.description">{{item.description}}</span>

					</div>

					<!--end::Text-->
				</div>

				<div *ngIf="isDatastory(item)">
					<a href="javascript:;"
					   title="share"
					   (click)="shareDatastory(item.id)"
					   class="btn btn-hover-light-primary text-light btn-sm btn-icon">
						<i class="fa fa-share"></i>
					</a>

				</div>

			</div>
			<span *ngIf="isDatastory(item)"
				  class="text-danger text-right text-ellipses ng-star-inserted"
				  style="font-size: 11px !important; min-width: 100% !important;text-align: end !important;">{{item.expiryDate}}</span>
		</ng-container>
		<!-- if menu item has submenu child then recursively call new menu item component -->
		<ng-container *ngIf="item.submenu">
			<div class="kt-menu__submenu"
				 *ngIf="item.submenu?.length && !isBookmark(item)"
				 style="max-height: 70vh !important"
				 perfectScrollbar
				 [ngClass]="getItemMenuSubmenuClass(item)"
				 [ngStyle]="{ 'width': item.submenu.width}">
				<span class="kt-menu__arrow"
					  [ngClass]="{ 'kt-menu__arrow--adjust': item.root }"></span>




				<ul *ngIf="item.submenu?.length"
					class="kt-menu__subnav">
					<ng-container *ngFor="let child of item.submenu">
						<ng-container [ngTemplateOutlet]="menuTemplate"
									  [ngTemplateOutletContext]="{ item: child, parentItem: item }">
						</ng-container>
					</ng-container>
				</ul>


				<ul *ngIf="item.submenu.items?.length"
					class="kt-menu__subnav px-20">
					<ng-container *ngFor="let child of item.submenu.items">
						<ng-container [ngTemplateOutlet]="menuTemplate"
									  [ngTemplateOutletContext]="{ item: child, parentItem: item }">
						</ng-container>
					</ng-container>
				</ul>

				<div *ngIf="item.submenu.type === 'mega' && item.submenu.columns?.length"
					 class="kt-menu__subnav">
					<ul class="kt-menu__content">
						<ng-container *ngFor="let child of item.submenu.columns">
							<ng-container [ngTemplateOutlet]="menuColumnTemplate"
										  [ngTemplateOutletContext]="{ item: child }"></ng-container>
						</ng-container>
					</ul>
				</div>

			</div>
			<div class="kt-menu__submenu"
				 *ngIf="item.submenu?.length && isBookmark(item)"
				 [ngClass]="getItemMenuSubmenuClass(item)"
				 [ngStyle]="{ 'width': item.submenu.width}">
				<span class="kt-menu__arrow"
					  [ngClass]="{ 'kt-menu__arrow--adjust': item.root }"></span>
				<ul class="kt-menu__subnav">
					<ng-container *ngFor="let child of item.submenu">
						<ng-container [ngTemplateOutlet]="menuTemplate"
									  [ngTemplateOutletContext]="{ item: child, parentItem: item }">
						</ng-container>
					</ng-container>
				</ul>
			</div>

		</ng-container>
	</li>
</ng-template>

<!-- item inner -->
<ng-template #menuItemInnerTemplate
			 let-item="item"
			 let-parentItem="parentItem">
	<!-- if menu item has icon -->
	<img *ngIf="item.isSvg"
		 class="kt-menu__link-icon pr-2 max-width-20"
		 [src]="item.icon">
	<i *ngIf="item.icon && !item.isSvg"
	   class="kt-menu__link-icon"
	   [ngClass]="item.icon"></i>
	<ng-container *ngIf="!item.icon">
		<!-- if menu item using bullet -->
		<i *ngIf="parentItem && parentItem.bullet === 'dot' || item.bullet === 'dot'"
		   class="kt-menu__link-bullet kt-menu__link-bullet--dot">
			<span></span>
		</i>
		<i *ngIf="parentItem && parentItem.bullet === 'line' || item.bullet === 'line'"
		   class="kt-menu__link-bullet kt-menu__link-bullet--line">
			<span></span>
		</i>
	</ng-container>

	<ng-container *ngIf="!item.badge; else menuLinkBadge">
		<span class="kt-menu__item-here"></span>
		<!-- menu item title text -->
		<span class="kt-menu__link-text"
			  [translate]="item.translate">
			{{item.title}}
		</span>
		<ng-container *ngIf="isDatastory(item)">
			<i (click)="shareDatastory(item.id)"
			   title="share"
			   class="fa  fa-share"></i>
		</ng-container>
	</ng-container>

	<ng-template #menuLinkBadge>
		<!-- menu item with badge -->
		<span class="kt-menu__link-text"
			  [translate]="item.translate">{{item.title}}</span>
		<span class="kt-menu__link-badge">
			<span class="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill"
				  [ngClass]="item.badge.type"
				  [translate]="item.badge.translate">{{item.badge.value}}</span>
		</span>
	</ng-template>
</ng-template>

<!-- item column -->
<ng-template #menuColumnTemplate
			 let-item="item">
	<li class="kt-menu__item">
		<h3 class="kt-menu__heading kt-menu__toggle">
			<span class="kt-menu__link-text"
				  [translate]="item.heading.translate">
				{{item.heading.title}}
			</span>
			<i class="kt-menu__ver-arrow la la-angle-right"></i>
		</h3>
		<ng-container *ngIf="item.items?.length">
			<ul class="kt-menu__inner">
				<ng-container *ngFor="let child of item.items">
					<ng-container [ngTemplateOutlet]="menuTemplate"
								  [ngTemplateOutletContext]="{ item: child, parentItem: item }">
					</ng-container>
				</ng-container>
			</ul>
		</ng-container>
	</li>
</ng-template>