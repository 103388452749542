import { Injectable } from "@angular/core";
import { CompanyModel } from "../model/company.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CompanyRestService } from "@app/core/services/company-service/service/company.rest.service";
import { CompanyServerModel } from "@app/core/services/company-service/model/company.server.model";
import { CompanyStatusModel } from "../model/company-status.model";
import { CompanyStatusServerModel } from "@app/core/services/company-service/model/company-status.server.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable()
export class CompanyService {
  constructor(private service: CompanyRestService) {}
  companyPageResult: PageResultModel<CompanyModel> = { result: [], total: -1 };

  getCompanies$(
    pageNumber?: number,
    pageSize?: number,
    query?: string,
    getTotal?: boolean
  ): Observable<PageResultModel<CompanyModel>> {
    return this.service
      .getCompanies$(pageNumber, pageSize, query, getTotal)
      .pipe(
        map((res: PageResultModel<CompanyServerModel>) => {
          this.companyPageResult = new PageResultModel<CompanyModel>(res);
          return this.companyPageResult;
        })
      );
  }
  getCompanyStatuses$(): Observable<CompanyStatusModel[]> {
    return this.service.getCompanyStatuses$().pipe(
      map((res: CompanyStatusServerModel[]) => {
        return res.map(x => new CompanyStatusModel(x));
      })
    );
  }
  getCompany$(companyId: number): Observable<CompanyModel> {
    return this.service
      .getCompany$(companyId)
      .pipe(map(res => new CompanyModel(res)));
  }
  getCurrentCompanyDetail$(): Observable<CompanyModel> {
    return this.service
      .getCurrentCompanyDetail$()
      .pipe(map(res => new CompanyModel(res)));
  }

  CompaniesCount$(): Observable<number> {
    return this.service.CompaniesCount$();
  }
  createCompany$(company: CompanyModel): Observable<CompanyModel> {
    return this.service
      .createCompany$(company)
      .pipe(map(res => new CompanyModel(res)));
  }
  updateCompany$(company: CompanyModel): Observable<CompanyModel> {
    return this.service
      .updateCompany$(company)
      .pipe(map(res => new CompanyModel(res)));
  }
  uploadLogo$(file: File): Observable<CompanyModel> {
    return this.service
      .uploadLogo$(file)
      .pipe(map(res => new CompanyModel(res)));
  }
}
