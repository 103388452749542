import { BookmarkServerModel } from "@app/core/services/bookmark-service/models/bookmark.server.model";
import { IconModel } from "@app/icons/model/icon.model";

export class BookmarkModel {
  id?: number | undefined;
  reportId?: number | undefined;
  iconId?: number | undefined;
  name?: string | undefined;
  token?: string | undefined;
  displayName?: string | undefined;
  status?: number | undefined;
  state?: string | undefined;
  sortId?: number | undefined;
  icon?: IconModel | undefined;
  constructor(item?: BookmarkServerModel) {
    if (item) {
      this.id = item.id;
      this.reportId = item.reportId;
      this.iconId = item.iconId;
      this.name = item.name;
      this.token = item.token;
      this.displayName = item.displayName;
      this.status = item.status;
      this.state = item.state;
      this.sortId = item.sortId;
      this.icon = new IconModel(item.icon);
    }
  }
}
