import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { DataStoryServerModel } from "../model/data-story.server.model";

@Injectable({ providedIn: "root" })
export class DataStoryRestService extends BaseService {
  routUrl = "";
  dataStories: DataStoryServerModel[] = [];

  constructor(private http: HttpClient) {
    super();
  }
  getAllDataStories$(): Observable<DataStoryServerModel[]> {
    this.routUrl = this.baseUrl + `/datastories`;
    return of(this.dataStories);
  }

  getUserDataStories$(): Observable<DataStoryServerModel[]> {
    this.routUrl = this.baseUrl + `/pages/datastories/my`;
    return this.http.get<DataStoryServerModel[]>(this.routUrl);
  }

  getDataStory$(id: number): Observable<DataStoryServerModel> {
    this.routUrl = this.baseUrl + `/pages/datastories/${id}`;
    return this.http.get<DataStoryServerModel>(this.routUrl);
    //return of(this.dataStories.find(x => x.id == id));
  }
  createDataStory$(
    dataStory: DataStoryServerModel
  ): Observable<DataStoryServerModel> {
    this.routUrl = this.baseUrl + `/pages/datastories`;
    return this.http.post<DataStoryServerModel>(this.routUrl, dataStory);
  }
  shareDataStory$(
    dataStory: DataStoryServerModel
  ): Observable<DataStoryServerModel> {
    this.routUrl = this.baseUrl + `/pages/datastories/share`;
    return this.http.post<DataStoryServerModel>(this.routUrl, dataStory);
  }
  updateDataStories$(
    dataStories: DataStoryServerModel[]
  ): Observable<DataStoryServerModel> {
    this.routUrl = this.baseUrl + `/pages/datastories`;
    return this.http.put<DataStoryServerModel>(this.routUrl, dataStories);
  }

  getDataStoryEmbedConfig$(
    dataStoryId: number
  ): Observable<PowerBIEmbedConfig> {
    this.routUrl =
      this.baseUrl + `/pages/datastories/${dataStoryId}/embed-config`;

    return this.http.get<PowerBIEmbedConfig>(this.routUrl);
  }
}
