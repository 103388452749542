import { Injectable } from "@angular/core";
import { NotificationRestService } from "@core/services/notification-service/service/notification.rest.service";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { NotificationServerModel } from "@core/services/notification-service/model/notification.server.model";
import {
  NotificationModel,
  SyncNotificationModel,
  MessageModel,
} from "../model/notification.model";
import { LogModel } from "../model/logs.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";
import { NotifyService } from "@app/shared/services/notify.service";
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  notificationsList$: Observable<NotificationModel[]>;
  alerts: NotificationModel[] = [];
  alertsCount: number = 0;
  notificationsCount: number = 0;
  notifications: NotificationModel[] = [];
  syncNotifications: SyncNotificationModel[] = [];
  constructor(
    private service: NotificationRestService,
    private notifyService: NotifyService
  ) {}
  getAllNotifications$(
    page: number,
    pageSize: number,
    includeRead: boolean,
    filter: string,
    getTotal: boolean
  ): Observable<PageResultModel<NotificationModel>> {
    return this.service
      .getAllNotifications$(page, pageSize, includeRead, filter, getTotal)
      .pipe(
        map((res) => {
          return new PageResultModel<NotificationModel>(res);
        })
      );
  }
  getNotification$(
    page: number,
    pageSize: number
  ): Observable<NotificationModel[]> {
    this.notificationsList$ = this.service
      .getNotifications$(page, pageSize)
      .pipe(
        map((res) => {
          return res.result.map((x) => new NotificationModel(x));
        }),
        tap((x: NotificationServerModel[]) => {
          this.alerts = x.filter((x) => x.severity === 2 && x.status === false);
          this.alertsCount = this.alerts.length;
          this.notifications = x.filter(
            (x) => x.severity === 1 && x.status === false
          );
          this.notificationsCount = this.notifications.length;
        })
      );
    return this.notificationsList$;
  }
  clearAll$(ids: number[]): Observable<boolean> {
    return this.service.clearAll$(ids).pipe(
      tap((x) => {
        if (x) {
          x.forEach((notificationId, index) => {
            this.alerts.splice(
              this.alerts.findIndex((item) => item.id == notificationId),
              1
            );
            this.notifications.splice(
              this.notifications.findIndex((item) => item.id == notificationId),
              1
            );
            this.alertsCount = this.alerts.length;
            this.notificationsCount = this.notifications.length;
          });
        }
      }),
      map((x) => {
        if (x.length) return true;
        else return false;
      })
    );
  }
  removeAlert$(id: number): Observable<boolean> {
    return this.service.removeAlert$(id).pipe(
      tap((x) => {
        if (x) {
          x.forEach((notificationId, index) => {
            this.alerts.splice(
              this.alerts.findIndex((item) => item.id == notificationId),
              1
            );
            this.notifications.splice(
              this.notifications.findIndex((item) => item.id == notificationId),
              1
            );
            this.alertsCount = this.alerts.length;
            this.notificationsCount = this.notifications.length;
          });
        }
      }),
      map((x) => {
        if (x.length) return true;
        else return false;
      })
    );
  }
  removeNotification$(id: number): Observable<boolean> {
    return this.service.removeNotification$(id).pipe(
      tap((x) => {
        if (x) {
          x.forEach((notificationId, index) => {
            this.alerts.splice(
              this.alerts.findIndex((item) => item.id == notificationId),
              1
            );
            this.notifications.splice(
              this.notifications.findIndex((item) => item.id == notificationId),
              1
            );
            this.alertsCount = this.alerts.length;
            this.notificationsCount = this.notifications.length;
          });
        }
      }),
      map((x) => {
        if (x.length) return true;
        else return false;
      })
    );
  }
  addNotification(item: NotificationModel) {
    if (item.severity === 1) {
      this.notifications.unshift(item);
      this.notificationsCount = this.notifications.length;
    }
    if (item.severity === 2) {
      this.alerts.unshift(item);
      this.alertsCount = this.alerts.length;
    }
  }
  addSyncNotification(model: SyncNotificationModel) {
    const index = this.syncNotifications.findIndex(
      (x) =>
        x.id === model.id &&
        model.type === x.type &&
        model.message.object == x.message.object
    );
    if (index === -1) {
      this.syncNotifications.unshift(model);
      this.notifyService.info(model.message.information);
    } else {
      this.updateSyncNotification(model);
    }
  }
  updateSyncNotification(model: SyncNotificationModel) {
    const index = this.syncNotifications.findIndex(
      (x) =>
        x.id === model.id &&
        model.type === x.type &&
        model.message.object == x.message.object
    );
    if (index !== -1) {
      model.createdOn = this.syncNotifications[index].createdOn;
      this.syncNotifications[index].message = new MessageModel(model.message);

      this.notifyService.info(model.message.information);
    }
  }
  removeSyncNotification(model: SyncNotificationModel) {
    const index = this.syncNotifications.findIndex(
      (x) =>
        x.id === model.id &&
        model.type === x.type &&
        model.message.object == x.message.object
    );
    if (index !== -1) {
      this.syncNotifications.splice(index, 1);
      return true;
    }
    return false;
  }
  getLogs$(
    page: number,
    pageSize: number,
    filter?: string
  ): Observable<PageResultModel<LogModel>> {
    return this.service
      .getLogs$(page, pageSize, filter)
      .pipe(map((res) => new PageResultModel<LogModel>(res)));
  }
  getSubdomains$(): Observable<any[]> {
    return this.service.getSubdomains$();
  }
}
