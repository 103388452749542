import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { HttpResponse } from "@aspnet/signalr";
import { NotifyService } from "@shared/services/notify.service";
import { AuthService } from "../Auth-service/auth.service";
import { environment } from "../../../../environments/environment";

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
  constructor(
    private notifyService: NotifyService,
    private authService: AuthService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let clone = req.clone();
    if (req.url.includes(environment.apiUrl)) {
      clone = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`
        },
      });
    }
    if (req.url.includes("event-stream")) {
      clone = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`,
          Accept: "text/event-stream",
          "Content-Type": "text/event-stream;charset=UTF-8",
          "Cache-Control": "no-cache",
          Connection: "keep-alive",
        },
      });
    }
    return next.handle(clone).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "";
        if (clone.url.includes(environment.authUrl)) {
          return throwError(error);
        }

        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
        } else {
          if (error.status == 401) {
            if (!this.authService.isValidToken()) {
              if (environment.teams) {
                this.authService.refreshToken();
              } else {
                this.authService.login('/dashboard');
              }
            }
          } else {
            errorMessage =
              error && error.error && typeof error.error === "string"
                ? error.error
                : "";
            if (errorMessage && errorMessage !== "") {
              this.notifyService.error({
                message: errorMessage,
              });
            }
            // server-side error
            return throwError(false);
          }
        }
        return throwError(true);
      })
    );
  }
}
