import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { HttpClient } from "@angular/common/http";
import { FileServerModel } from "../model/file.server.model";
import { Observable } from "rxjs";
import { CompanyServerModel } from "../../company-service/model/company.server.model";

@Injectable({
  providedIn: "root",
})
export class FileRestService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  getFiles(): Observable<FileServerModel[]> {
    const url = this.baseUrl + `/companies/files/my`;
    return this.http.get<FileServerModel[]>(url);
  }
  getFilesByApplication(applicationId: number): Observable<FileServerModel[]> {
    const url = this.baseUrl + `/companies/files/${applicationId}/my`;
    return this.http.get<FileServerModel[]>(url);
  }
  uploadLogo$(file: File): Observable<CompanyServerModel> {
    const url_ = this.baseUrl + "/companies/logo";
    const uploadData = new FormData();
    uploadData.append("image", file, file.name);
    return this.http.post<CompanyServerModel>(url_, uploadData);
  }
  deleteFile$(url: string): Observable<any> {
    return this.http.delete(url);
  }
}
