export const aside = {
  items: [
    {
      title: "Summary",
      root: true,
      bullet: "dot",

      icon: "flaticon2-heart-rate-monitor",
      // notForAdmin: environment.development ? false : true,
      page: "/dashboard",
      translate: "Summary",
    },
    {
      title: "Companies",
      translate: "Companies",
      permission: "permissions.companies.manage",

      bullet: "dot",
      icon: "fa fa-building",
      page: "/companies",
    },
    {
      title: "Users",
      translate: "Users",
      bullet: "dot",
      icon: "flaticon2-user",
      page: "/users",
    },

    {
      title: "Teams",
      translate: "Teams",
      bullet: "dot",
      icon: "flaticon-computer",
      page: "/teams",
      permission: "permissions.teams.manage",
      notForAdmin: true,
    },
    {
      title: "Roles",
      translate: "Roles",
      bullet: "dot",
      permission: "permissions.roles.manage",
      icon: "flaticon2-avatar",
      page: "/roles",
      forAdmin: true,
    },

    {
      title: "Power BI Service",
      translate: "Power BI Service",
      bullet: "dot",
      scope: "powerbi",
      icon: "flaticon2-analytics-1",
      submenu: [
        {
          title: "Power BI",
          translate: "Power BI",
          page: "/powerBi/manage",
          permission: "permissions.powerbi.manage",
          // notForAdmin: environment.development ? false : true
        },
        {
          title: "Power BI Shared Workspaces",
          translate: "Power BI Shared Workspaces",
          permission: "permissions.sharedWorkspaces.manage",

          page: "/powerBi/shared-workspaces",
        },
        {
          title: "Power BI Service Profiles",
          translate: "Power BI Service Profiles",
          permission: "permissions.powerbi.profiles.manage",
          page: "/powerBi/profiles",
        },
        // {
        //   title: "Files",
        //   translate: "Files",
        //   permission: "permissions.powerbi.manage",
        //   page: "/powerBi/files",
        // },
      ],
    },
    {
      title: "Power BI Server",
      translate: "Power BI Server",
      bullet: "dot",
      scope: "powerbionprem",
      icon: "flaticon2-analytics-1",
      submenu: [
        {
          title: "Power BI",
          translate: "Power BI",
          page: "/powerbi-server/manage",
          permission: "permissions.powerbionprem.manage",
          // notForAdmin: environment.development ? false : true
        },
        {
          title: "Power BI Server Profiles",
          translate: "Power BI Server Profiles",
          permission: "permissions.powerbionprem.profiles.manage",
          page: "/powerbi-server/appId/2/profiles",
        },
        {
          title: "DNS Records",
          translate: "DNS Records",
          permission: "permissions.powerbionprem.manage",
          page: "/powerbi-server/dns-records",
        },
      ],
    },
    {
      title: "Payments",
      translate: "Payments",
      bullet: "dot",
      icon: "flaticon-price-tag",
      permission: "permissions.payments.products.manage",
      forAdmin: true,
      submenu: [
        {
          title: "Applications",
          translate: "Applications",
          page: "/payments/applications",
          permission: "permissions.applications.manage",
        },
        {
          title: "Products",
          translate: "Products",
          page: "/payments/products",
          permission: "permissions.payments.products.manage",
        },
        {
          title: "Coupons",
          translate: "Coupons",
          page: "/payments/coupons",
          permission: "permissions.payments.coupons.manage",
        },
        {
          title: "Invoices",
          translate: "Invoices",
          page: "/payments/invoices",
          permission: "permissions.payments.invoices.manage",
        },
      ],
    },
    {
      title: "Billings",
      translate: "Billings",
      bullet: "dot",
      icon: "flaticon-price-tag",
      notForAdmin: true,
      submenu: [
        {
          title: "Payment Methods",
          translate: "Payment Methods",
          page: "/payments/methods",
          permission: "permissions.payments.methods.manage",
        },
        {
          title: "Invoices",
          translate: "Invoices",
          page: "/payments/invoices",
          permission: "permissions.payments.invoices.view",
        },
        {
          title: "Subscriptions",
          translate: "Subscriptions",
          page: "/payments/subscriptions",
          permission: "permissions.payments.subscriptions.view",
        },
      ],
    },
    {
      title: "Icons",
      translate: "Icons",
      bullet: "dot",
      icon: "fa fa-layer-group",
      root: true,
      permission: "permissions.companies.manage",
      page: "/icons",
      forAdmin: true,
    },

    // {
    //   title: "Support",
    //   translate: "Support",
    //   bullet: "dot",
    //   icon: "flaticon-phone",
    //   module: "support",
    //   submenu: [
    //     {
    //       title: "Dome Request",
    //       translate: "Dome Request",
    //       page: "/support/demo-requests"
    //     }
    //   ]
    // }
  ],
};
