import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { Report } from "report";
import * as pbi from "powerbi-client";
import { Dashboard } from "dashboard";
import { NotifyService } from "@app/shared/services/notify.service";
import { PageModel } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/pages/model/page.model";
import { deepCopy } from "@app/shared/services/app-config.service";
import { PowerBIDashboardVisualService } from "../service/pbi-dashboard-visual.service";
import { DashboardVisualModel } from "../model/dashboard-visual.model";
import { UserAction, systemObject } from "@app/shared/model/user-action.enum";

@Component({
  selector: "qx-pbi-embedded",
  templateUrl: "./pbi-embedded.component.html",
  styleUrls: ["./pbi-embedded.component.scss"],
})
export class PBIEmbeddedComponent implements OnInit, OnDestroy {
  public _config: any = {};
  loading = true;
  public _container = "none";
  powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
    pbi.Report
  );
  dashboardPowerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
    pbi.Dashboard
  );
  _page: PageModel | boolean;
  @ViewChild("cont", { static: true }) dashEl: ElementRef;
  public _isDashboard = false;
  @Input() public set embeddedConfig(config: any) {
    if (config.id) {
      this._config = deepCopy(config);
      this.renderReport();
    }
  }
  public get embeddedConfig() {
    return this._config;
  }
  @Input() public set page(page: PageModel | boolean) {
    this._page = page;
  }
  @Input() public set isDahsboard(value: boolean) {
    this._isDashboard = value;
  }
  @Input() public set container(value: string) {
    this._container = value;
  }

  constructor(
    private toaster: NotifyService,
    public el: ElementRef,
    private visualService: PowerBIDashboardVisualService
  ) {}
  ngOnDestroy(): void {
    (<HTMLInputElement>document.getElementById("kt_content")).classList.remove(
      "p-0"
    );
    (<HTMLInputElement>document.getElementById("kt_content")).classList.remove(
      "pr-3"
    );
    (<HTMLInputElement>document.getElementById("kt_content")).classList.remove(
      "ml-1"
    );
    (<HTMLInputElement>(
      document.getElementsByClassName("kt-container")[0]
    )).classList.remove("p-0");
  }
  ngOnInit() {
    (<HTMLInputElement>document.getElementById("kt_content")).classList.add(
      "p-0"
    );
    (<HTMLInputElement>document.getElementById("kt_content")).classList.add(
      "pr-3"
    );
    (<HTMLInputElement>document.getElementById("kt_content")).classList.add(
      "ml-1"
    );
    (<HTMLInputElement>(
      document.getElementsByClassName("kt-container")[0]
    )).classList.add("p-0");
  }
  renderReport() {
    if (this._config) {
      if (this._isDashboard === true) {
        const config = {
          id: this._config.id,
          type: this._config.type,
          tokenType: this._config.tokenType,
          accessToken: this._config.accessToken,
          embedUrl: this._config.embedUrl,
          pageView: this._config.pageView,
          permissions: this._config.permissions,
        };
        const div = this.el.nativeElement.querySelector("#reportsContainer");
        const report: Dashboard = this.dashboardPowerbi.embed(
          div,
          config
        ) as Dashboard;
        report.on("rendered", (event) => {
          this.loading = false;
          report.off("rendered");
        });
        report.on("error", (event) => {
          this.loading = false;
          this.toaster.error({ message: JSON.stringify(event.detail) });
          report.off("error");
        });
      } else {
        const div = this.el.nativeElement.querySelector("#reportsContainer");
        const report: Report = this.dashboardPowerbi.embed(
          div,
          this._config
        ) as Report;
        report.on("loaded", (event) => {
          this.loading = false;
          report.off("loaded");
        });
        if (typeof this._page !== "boolean") {
          report.on("commandTriggered", (command: any) => {
            const visual: DashboardVisualModel = {
              pageId: (this._page as PageModel).id,
              token: command.detail.visual.name,
              typeId: 2,
            };
            this.visualService
              .addDashboardVisual$(visual)
              .toPromise()
              .then((x) => {
                if (x) {
                  this.toaster.success({
                    crud: UserAction.Crud.pin,
                    object: systemObject.visual,
                  });
                } else {
                  this.toaster.error({
                    crud: UserAction.Crud.pin,
                    object: systemObject.visual,
                  });
                }
              })
              .catch((err) => {
                if (err) {
                  this.toaster.error({
                    crud: UserAction.Crud.pin,
                    object: systemObject.visual,
                  });
                }
              });
          });
        }
        report.on("error", (event) => {
          this.toaster.error({ message: JSON.stringify(event.detail) });
          report.off("error");
        });
      }
    }
  }
}
