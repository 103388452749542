import { DatasetRefreshServerModel } from "@core/services/report-service/models/dataset-refresh.server.model";

export class DatasetRefreshModel {
  refreshType?: string | undefined;
  startTime?: Date | undefined;
  endTime?: Date | undefined;
  status?: string | undefined;
  constructor(item: DatasetRefreshServerModel) {
    if (item) {
      this.refreshType = item.refreshType;
      this.startTime = item.startTime;
      this.endTime = item.endTime;
      this.status = item.status;
    }
  }
}
