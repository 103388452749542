import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { DashboardVisualServerModel } from "../model/dashboard-visual.model";

@Injectable({ providedIn: "root" })
export class PowerBIDashboarVisualRestService extends BaseService {
  routUrl = "";
  dashboardVisuals: DashboardVisualServerModel[] = [];

  constructor(private http: HttpClient) {
    super();
  }
  getAllDashboardVisuals$(): Observable<DashboardVisualServerModel[]> {
    this.routUrl = this.baseUrl + `/visuals/embed-Config`;
    return this.http.get<DashboardVisualServerModel[]>(this.routUrl);
    //return of(this.dashboardVisuals);
  }

  getUserDashboardVisuals$(): Observable<DashboardVisualServerModel[]> {
    this.routUrl = this.baseUrl + `/visuals`;
    return of(this.dashboardVisuals);
  }

  getDashboardVisual$(id: number): Observable<DashboardVisualServerModel> {
    this.routUrl = this.baseUrl + `/visuals/${id}`;
    return this.http.get<DashboardVisualServerModel>(this.routUrl);
  }
  addDashboardVisual$(
    dashboardVisual: DashboardVisualServerModel
  ): Observable<DashboardVisualServerModel> {
    this.routUrl = this.baseUrl + `/visuals/pin`;
    return this.http.post<DashboardVisualServerModel>(
      this.routUrl,
      dashboardVisual
    );
  }
  updateDashboardVisuals$(
    dashboardVisuals: DashboardVisualServerModel[]
  ): Observable<boolean> {
    this.routUrl = this.baseUrl + `/visuals`;
    return this.http.put<boolean>(this.routUrl, dashboardVisuals);
  }
  removeDashboardVisual$(
    dashboardVisual: DashboardVisualServerModel
  ): Observable<boolean> {
    this.routUrl = this.baseUrl + `/visuals/unpin`;
    return this.http.post<boolean>(this.routUrl, dashboardVisual);
  }

  getDashboardVisualEmbedConfig$(
    dashboardVisualId: number
  ): Observable<PowerBIEmbedConfig> {
    this.routUrl = this.baseUrl + `/visuals/${dashboardVisualId}/embed-config`;
    return this.http.get<PowerBIEmbedConfig>(this.routUrl);
  }
}
