import { ReportServerModel } from "@core/services/report-service/models/report.server.model";
import { IconModel } from "@app/icons/model/icon.model";

export class ReportModel {
  id?: number | undefined;
  workspaceId?: number | undefined;
  name?: string | undefined;
  token?: string | undefined;
  displayName?: string | undefined;
  description?: string | undefined;
  tags?: string[] | undefined;
  status?: number | undefined;
  authenticationMethodId?: number | undefined;
  iconId?: number | undefined;
  isEffectiveRoleRequired?: boolean | undefined;
  isEffectiveIdentityRequired?: boolean | undefined;
  isOnPrimGateway?: boolean | undefined;
  isRefreshableReport?: boolean | undefined;
  reportRole?: string | undefined;
  dataSourceTypeId?: number | undefined;
  powerBITemplateId?: number | undefined;
  icon?: IconModel | undefined;

  constructor(report?: ReportServerModel) {
    if (report) {
      this.id = report.id;
      this.workspaceId = report.workspaceId;
      this.name = report.name;
      this.token = report.token;
      this.displayName = report.displayName;
      this.description = report.description;
      this.tags = report.tags;
      this.status = report.status;
      this.authenticationMethodId = report.authenticationMethodId;
      this.iconId = report.iconId;
      this.isEffectiveRoleRequired = report.isEffectiveRoleRequired;
      this.isEffectiveIdentityRequired = report.isEffectiveIdentityRequired;
      this.isOnPrimGateway = report.isOnPrimGateway;
      this.isRefreshableReport = report.isRefreshableReport;
      this.reportRole = report.reportRole;
      this.dataSourceTypeId = report.dataSourceTypeId;
      this.powerBITemplateId = report.powerBITemplateId;
      this.icon = new IconModel(report.icon);
    }
  }
}
