import {
  Injectable,
  ComponentFactoryResolver,
  Type,
  OnInit
} from "@angular/core";
import { Observable } from "rxjs";
import {
  map,
  tap,
  filter,
  find,
  first,
  concatAll,
  mergeMap,
  share
} from "rxjs/operators";
import { ApplicationType } from "../model/application-type.enum";
import { FeatureFunction } from "../model/feature-function.interface";
import { SubscriptionModel } from "../model/subscription.model";
import { Feature } from "../../applications/model/feature.model";
import { SubscriptionService } from "./subscription.service";
import { ProductService } from "../../products/services/product.service";
import { Usage } from "../model/usage.model";
import { SubscriptionUsage } from "../model/subscription-usage.model";
import { UsageServerModel } from "@app/core/services/subscription-services/model/usage.server.model";
import { UsageRestService } from "@app/core/services/subscription-services/services/usage.rest.service";
import { NotifyService } from "@app/shared/services/notify.service";
import { TitleCasePipe } from "@angular/common";
import { Product } from "../../products/models/product.model";
import { ProductServerModel } from "@app/core/services/product-services/models/product.server.model";
@Injectable()
export class UsageService {
  private iocComponents = [];
  public _subscribedProducts: Product[] = [];
  private usages: {
    [key in ApplicationType]: any[];
  } = this.reset();
  private features: {
    [key in ApplicationType]: Feature[];
  } = this.reset();
  private activeSubs: SubscriptionModel[] = [];
  private components: { [key in ApplicationType]: FeatureFunction[] } = {
    1: [
      {
        title: "Manage Users",
        component: "UsersUsageComponent",

        data: [],
        loadData: () => {
          return this.usages[1].find(x => x.key.toLowerCase() === "users");
        }
      },
      {
        title: "Manage Reports",
        component: "ReportsUsageComponent",
        data: [],
        loadData: () => {
          return this.usages[1].find(x => x.key.toLowerCase() === "reports");
        }
      },
      {
        title: "Manage Dashboards",
        component: "DashboardsUsageComponent",
        data: [],
        loadData: () => {
          return this.usages[1].find(x => x.key.toLowerCase() === "dashboards");
        }
      }
    ],
    2: [
      {
        title: "Manage Users",
        component: "UsersUsageComponent",
        data: [],
        loadData: () => {
          return this.usages[2].find(x => x.key.toLowerCase() === "users");
        }
      }
    ]
  };

  constructor(
    private subscriptionService: SubscriptionService,
    private usageService: UsageRestService,
    private productService: ProductService,
    private notifyService: NotifyService,
    private titleCase: TitleCasePipe
  ) {}

  registerComponents(iocComponents) {
    this.iocComponents = iocComponents;
  }
  getUsageObject(application: ApplicationType) {
    return this.usages[application];
  }
  addInUsage(application: ApplicationType, key: string, value: any) {
    const activeSubs = this.activeSubs.find(
      x => x.applicationId === application
    );
    const activeSubsIndex = this.activeSubs.findIndex(
      x => x.applicationId === application
    );
    const usageIndex = activeSubs.usage.findIndex(
      x => x.key.toLowerCase() === key.toLowerCase()
    );
    const usage = activeSubs.usage.find(
      x => x.key.toLowerCase() === key.toLowerCase()
    );
    if (!usage) {
      return false;
    }

    switch (usage.dataType.toLowerCase()) {
      case "list":
        if (!usage.value.find(x => +x === +value)) usage.value.push(value);
        this.activeSubs[activeSubsIndex].usage[usageIndex] = usage;
        return true;
      case "number":
        usage.value = usage.value + +value;
        this.activeSubs[activeSubsIndex].usage[usageIndex] = usage;
        return true;
      case "text":
        usage.value = value;
        this.activeSubs[activeSubsIndex].usage[usageIndex] = usage;
        return true;
      case "boolean":
        usage.value = value;
        this.activeSubs[activeSubsIndex].usage[usageIndex] = usage;
        return true;
      default:
        return false;
    }
  }
  removeFromUsage(application: ApplicationType, key: string, value: any) {
    const activeSubs = this.activeSubs.find(
      x => x.applicationId === application
    );
    const activeSubsIndex = this.activeSubs.findIndex(
      x => x.applicationId === application
    );
    const usageIndex = activeSubs.usage.findIndex(
      x => x.key.toLowerCase() === key.toLowerCase()
    );
    const usage = activeSubs.usage.find(
      x => x.key.toLowerCase() === key.toLowerCase()
    );
    if (!usage) {
      return false;
    }

    switch (usage.dataType.toLowerCase()) {
      case "list":
        usage.value.splice(
          usage.value.findIndex(x => +x === +value),
          1
        );
        this.activeSubs[activeSubsIndex].usage[usageIndex] = usage;

        return true;
      case "number":
        usage.value = usage.value - +value;
        if (usage.value < 0) usage.value = 0;
        this.activeSubs[activeSubsIndex].usage[usageIndex] = usage;
        return true;
      case "text":
        usage.value = value;
        this.activeSubs[activeSubsIndex].usage[usageIndex] = usage;
        return true;
      case "boolean":
        usage.value = value;
        this.activeSubs[activeSubsIndex].usage[usageIndex] = usage;
        return true;
      default:
        return false;
    }
  }
  validateKeyInSubs(applicationType: ApplicationType, key: string): boolean {
    const activeSubs = this.getActiveSubscriptionObject(applicationType);
    if (!activeSubs) {
      this.notifyService.info(
        `You don't have any subscription for '${ApplicationType[applicationType]}'. Please subscribe a package.`,
        "Limitation"
      );
      return false;
    }
    this.setFeature(applicationType, activeSubs.features);
    const feature = activeSubs.features.find(
      x => x.key.toLowerCase() == key.toLowerCase()
    );
    if (!feature) return true;
    const usage = activeSubs.usage.find(
      x => x.key.toLowerCase() == key.toLowerCase()
    );
    if (usage.value === undefined || usage.value === null) {
      this.notifyService.info(
        `'${this.titleCase.transform(
          key
        )}' Management not allowed. Please upgrade your subscription.`,
        "Limitation"
      );
      return false;
    }

    const isUnderLimit = this.isUnderLimit(
      applicationType,
      key.toLowerCase(),
      usage.value
    );

    if (!isUnderLimit) {
      this.notifyService.info(
        `'${this.titleCase.transform(
          key
        )}' Limit Exceeded. Please upgrade your subscription.`,
        "Limitation"
      );

      return false;
    }
    return true;
  }
  getActiveSubscriptionObject(application: ApplicationType) {
    return this.activeSubs.find(x => x.applicationId === application);
  }
  reset() {
    return {
      1: [],
      2: []
    };
  }
  setFeature(application: number, features: Feature[]) {
    this.features[application] = features;
  }
  getFeatureFunctions$(productId: number): Observable<FeatureFunction[]> {
    return this.productService.getProduct$(productId).pipe(
      map(product => {
        if (product) {
          const usage = this.usages[product.applicationId];
          if (usage) {
            return this.components[product.applicationId];
          }
        }
        return [];
      })
    );
  }
  isFeatureInSubscription(application: ApplicationType, key: string): boolean {
    const find = this.usages[application].find(
      x => x.key.toLowerCase() === key.toLowerCase()
    );
    return find ? true : false;
  }
  isApplicationInSubscription(application: ApplicationType): boolean {
    const find = this.usages[application].length;
    return find ? true : false;
  }

  isUnderLimit(
    application: ApplicationType,
    key: string,
    value?: any
  ): boolean {
    const usage = this.usages[application].find(
      x => x.key.toLowerCase() === key.toLowerCase()
    );
    if (!usage) {
      return false;
    }
    const feature = this.features[application].find(
      x => x.key.toLowerCase() === key.toLowerCase()
    );
    if (!feature) {
      return false;
    }
    let limit;
    switch (usage.dataType.toLowerCase()) {
      case "list":
        limit = value ? value : 0;
        if (limit <= feature.value) {
          return true;
        }
        break;
      case "number":
        limit = value ? value : 0;
        if (limit < +feature.value) {
          return true;
        }
        break;
      case "text":
        if (usage.value === feature.value) {
          return true;
        }
        break;
      case "boolean":
        if (usage.value === feature.value) {
          return true;
        }
        break;

      default:
        return false;
    }
    return false;
  }
  resolveType(name: string): Type<any> {
    const factoryClass = this.iocComponents.find(
      (x: any) => x.key === name
    ) as Type<any>;
    if (factoryClass) {
      return factoryClass;
    } else {
      throw new Error(`No component named ${name} found`);
    }
  }
  getActiveSubscription$(
    application?: ApplicationType
  ): Observable<SubscriptionModel> {
    return this.subscriptionService.getActiveSubscriptions$().pipe(
      tap(activeSubs => {
        this.activeSubs = activeSubs;
        this.usages = this.reset();
        const grp = this.subscriptionService.groupBy(
          activeSubs,
          x => x.applicationId
        );
        Object.values(ApplicationType)
          .filter(key => !isNaN(Number(key)))
          .map(x => {
            const subscriptions = grp[x] as SubscriptionModel[];
            if (subscriptions) {
              const sub = subscriptions[0];
              if (sub) {
                this.usages[x] = sub.usage ? sub.usage : [];
              }
            }
          });
      }),

      first(
        (subs, i) =>
          (application && subs[i].applicationId === application) ||
          (!application && 1 == 1),
        null
      ),
      concatAll()
    );
  }

  updateSubscriptionUsage$(
    subs: SubscriptionModel,
    usage: UsageServerModel
  ): Observable<boolean> {
    return this.subscriptionService.updateSubscriptionUsage$(subs.id, usage);
  }
  getSubscribedProducts$(): Observable<Product[]> {
    return this.usageService.getSubscribedProducts$().pipe(
      tap(res => {
        this._subscribedProducts = res.map(p => new Product(p));
      }),
      map((res: ProductServerModel[]) => res.map(p => new Product(p))),
      share()
    );
  }

  getLastUsageByApplication$(applicationId: number): Observable<Usage[]> {
    return this.usageService.getLastUsageByApplication(applicationId).pipe(
      map((res: UsageServerModel[]) => res.map(usage => new Usage(usage))),
      share()
    );
  }
}
