import { UserServerModel } from "@app/core/services/user-service/model/user.server.model";

export class UserModel {
  id?: number | undefined;
  username?: string | undefined;
  password?: string | undefined;
  fullName?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  pic?: string | undefined;
  customData?: string | undefined;
  email?: string | undefined;
  phoneNumber?: string | undefined;
  address?: string | undefined;
  gender?: string | undefined;
  dateOfBirth?: Date | undefined;
  city?: string | undefined;
  state?: string | undefined;
  timeZone?: string | undefined;
  zipCode?: number | undefined;
  country?: string | undefined;
  isBlocked?: boolean | undefined;
  emailConfirmed?: boolean | undefined;
  isExist?: boolean | undefined;
  companyId?: number | undefined;
  signInMethodId?: number | undefined;
  authenticationMethodId?: number | undefined;
  paymentGatewayCustomerId?: string | undefined;
  paymentGatewayDefaultCardId?: string | undefined;
  teamIds?: number[] | undefined;
  roles?: string[] | undefined;
  roleIds?: number[] | undefined;
  constructor(user?: UserServerModel) {
    if (user) {
      this.companyId = user.companyId;
      this.paymentGatewayCustomerId = user.paymentGatewayCustomerId;
      this.paymentGatewayDefaultCardId = user.paymentGatewayDefaultCardId;
      this.id = user.id;
      this.pic = user.pic;
      this.username = user.username;
      this.email = user.email;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.fullName = user.fullName;
      this.gender = user.gender;
      this.phoneNumber = user.phoneNumber;
      this.dateOfBirth = user.dateOfBirth;
      this.isExist = user.isExist;

      this.emailConfirmed = user.emailConfirmed;
      this.isBlocked = user.isBlocked;

      this.password = user.password;
      this.authenticationMethodId = user.authenticationMethodId;
      this.signInMethodId = user.signInMethodId;
      this.customData = user.customData;

      this.zipCode = user.zipCode;
      this.state = user.state;
      this.city = user.city;
      this.country = user.country;
      this.address = user.address;
      this.timeZone = user.timeZone;

      this.teamIds = user.teamIds ? user.teamIds : [];
      this.roleIds = user.roleIds ? user.roleIds : [];
      this.roles = user.roles;
    }
  }
}
