import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import {
  OAuthService,
  OAuthErrorEvent,
} from "angular-oauth2-oidc";
import { UserModel } from "@app/users-management/model/user.model";
import { AppLayoutConfigService } from "../layout-config.service";
import { LayoutConfigModel } from "@app/shared/model/layout.config";
import {
  ClientLayout,
  darkLayout,
} from "@app/core/config-objects/layout-config";
import { SSEService } from "../notification-service/service/sse.service";
import { MenuAsideService } from "@theme/metronic/app/core/_base/layout";
import { aside } from "@app/core/config-objects/aside-menu";
import { LocalStorageDataService } from "@app/shared/services/local-storage.service";
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from "rxjs";
import { filter, map } from "rxjs/operators";
import jwt_decode from "jwt-decode";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  layoutConfig = ClientLayout as LayoutConfigModel;
  logo: string;
  isMicrosoftUser = false;
  avatar: string;
  user: UserModel = new UserModel();
  isLoggedIn = false;
  isAdmin = false;
  isManager = false;
  roles: string[] = [];
  permissions: string[] = [];
  scopes: string[] = [];
  companyId: number;
  name: string;
  redirectUrl: string;
  expiryTime: Date;
  teamsApp = true;

  destinationUrl = '/dashboard';

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)));

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private layoutService: AppLayoutConfigService,
    private asideService: MenuAsideService,
    private sSEService: SSEService,
    private localStorageService: LocalStorageDataService
  ) {
    super();

    this.localStorageService.clearAllIfVersionUpdate();

    if (environment.teams) {
      microsoftTeams.initialize();

      microsoftTeams.getContext(async (c) => {
        localStorage.setItem('upn', c?.upn);
      });
    }

    this.teamsApp = environment.teams;
    this.canActivateProtectedRoutes$.subscribe(async (x) => {
      await this.initUserLogin();
    });
    // Useful for debugging:
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        //ignore
      } else {
        //ignore
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );
    });

    this.oauthService.events
      .pipe(filter((e) => ['token_received'].includes(e.type)))
      .subscribe(async (e) => {
        this.oauthService.loadUserProfile();
      });

    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe((e) => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();
  }
  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/login');
  }

  isValidToken() {
    //this.oauthService.getAccessTokenExpiration()
    return this.oauthService.hasValidAccessToken();
  }
  async silentRefresh() {
    return await this.oauthService.silentRefresh();
  }
  async login(state) {
    this.destinationUrl = state ?? this.router?.url ?? '/dashboard';
    if (environment.teams) {
      this.oauthService.oidc = false;
      await this.oauthService.fetchTokenUsingPasswordFlow(localStorage.getItem('upn'), localStorage.getItem('upn')).then(async (x) => {
        await this.initUserLogin();
        this.switchDisplayMode();
        this.layoutService.reloadHorizontalMenu();
        this.isAuthenticatedSubject$.next(true);
        this.router.navigateByUrl(this.destinationUrl);
      }).catch(error => {
        this.router.navigateByUrl(`/custom-error/${error?.error.error_description}`,)
      })
    }
    else {
      this.oauthService.initLoginFlow(this.router?.url || '/dashboard');
    }

  }
  logout() {
    this.oauthService.logOut();
    this.sSEService.disconnect();
  }
  async refreshToken() {
    if (environment.teams) {
      await this.oauthService.refreshToken().then(() => {
        this.initUserLogin().then(() => {
          this.asideService.menuList$.next(aside.items);
        });
      });
    } else {
      this.oauthService
        .silentRefresh()
        .then((info) => {
          this.initUserLogin().then(() => {
            this.asideService.menuList$.next(aside.items);
          });
        })
        .catch((err) => console.error("refresh error", err));
    }

  }
  containsScope(
    applicationScope: string,
    notForAdmin = false,
    forAdmin = false
  ): boolean {
    if (this.isAdmin) {
      if (forAdmin) {
        return true;
      }
      return !notForAdmin;
    }
    if (forAdmin) {
      return false;
    }

    const contains = this.scopes.find((x) => x === applicationScope);
    if (contains) {
      return true;
    }
    return false;
  }
  containsPermission(
    permission: string,
    notForAdmin = false,
    forAdmin = false
  ): boolean {
    if (this.isAdmin) {
      if (forAdmin) {
        return true;
      }
      return !notForAdmin;
    }
    if (forAdmin) {
      return false;
    }
    const contains = this.permissions.find((x) => x === permission);
    return contains ? true : false;
  }

  containsModulePermission(moduleName: string, notForAdmin = false): boolean {
    if (this.isAdmin) {
      return notForAdmin === true ? false : true;
    }
    const contains = this.permissions.findIndex((x) => x.includes(moduleName));
    return contains === -1 ? false : true;
  }
  getToken(): string {
    var token = this.oauthService.getAccessToken();
    return token;
  }

  async initUserLogin() {
    const receivedToken = this.oauthService.getAccessToken();
    let token: any;

    let encodedToken: any;
    if (receivedToken) {
      await this.localStorageService.resetRoles();
      encodedToken = receivedToken;
      token = jwt_decode(receivedToken) as any;
    } else {
      return this.isLoggedIn;
    }
    const role = token.role;
    const permissions = token.permission;
    if (permissions) {
      this.permissions =
        permissions instanceof Array ? permissions : [permissions];
    } else {
      this.permissions = [];
    }
    const applications = token.application;
    if (applications) {
      this.scopes =
        applications instanceof Array ? applications : [applications];
    } else {
      this.scopes = [];
    }
    if (role) {
      this.roles = role instanceof Array ? role : [role];
      const manager = this.roles.find((x) => x === "Manager");
      this.user.fullName = token.given_name;
      this.user.firstName = token.name;
      this.user.id = +token.sub;
      this.avatar = token.picture ? token.picture : "";
      this.logo = token.logo ? token.logo : "";
      this.isMicrosoftUser = token?.tenant_id?.length > 0;
      this.user.email = token.email;
      this.user.companyId = +token.company_id;
      this.user.signInMethodId = +token.signin_method_id;
      const d = new Date(0);
      d.setUTCSeconds(token.exp);
      this.expiryTime = d;
      //this.expiryTime= token.
      if (manager) {
        this.isLoggedIn = true;
        this.isManager = true;
        this.isAdmin = false;
      }
      const admin = this.roles.find((x) => x === "Admin");
      if (admin) {
        this.isLoggedIn = true;
        this.isAdmin = true;
        this.isManager = false;
      }
      const user = this.roles.find((x) => x === "PowerBI Client");
      if (user) {
        this.isLoggedIn = true;
        this.isAdmin = false;
        this.isManager = false;
      }
      this.switchDisplayMode()
    }
  }
  public runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        .loadDiscoveryDocument()

        // For demo purposes, we pretend the previous call was very slow
        .then(
          () => new Promise((resolve) => setTimeout(() => resolve(true), 1000))
        )

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLogin())

        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            this.layoutService.reloadHorizontalMenu();
            return Promise.resolve();
          }

          // 2. SILENT LOGIN:
          // Try to log in via a refresh because then we can prevent
          // needing to redirect the user:
          return this.oauthService
            .silentRefresh()
            .then(() => Promise.resolve())
            .catch((result) => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              const errorResponsesRequiringUserInteraction = [
                'interaction_required',
                'login_required',
                'account_selection_required',
                'consent_required',
              ];

              if (
                result &&
                result.reason &&
                errorResponsesRequiringUserInteraction.indexOf(
                  result.reason.error
                ) >= 0
              ) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // Enable this to ALWAYS force a user to login.
                // this.oauthService.initImplicitFlow();
                //
                // Instead, we'll now do this:

                return Promise.resolve();
              }

              // We can't handle the truth, just pass on the problem to the
              // next handler.
              return Promise.reject(result);
            });
        })
        .then(async () => {
          await this.initUserLogin();

          this.isDoneLoadingSubject$.next(true);

          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oauthService.state &&
            this.oauthService.state !== 'undefined' &&
            this.oauthService.state !== 'null'
          ) {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }

            this.router.navigateByUrl(stateUrl);
          } else {
            this.router.navigateByUrl('/dashboard');
          }
        })
        .catch(() => this.isDoneLoadingSubject$.next(true))
    );
  }
  switchDisplayMode() {
    // if (this.user && this.user.id) {
    //   this.sSEService.connect(this.user.id);
    // }
    if (this.isManager || this.isAdmin) {
      this.layoutConfig = darkLayout as LayoutConfigModel;
    } else {
      this.layoutConfig = ClientLayout as LayoutConfigModel;
    }
    this.layoutService.loadConfig(this.layoutConfig);
  }
}
