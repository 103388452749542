import { PBIDashboardServerModel } from "@app/core/services/dashboard-service/models/dashboard.server.model";
import { IconModel } from "@app/icons/model/icon.model";

export class PBIDashboardModel {
  id?: number | undefined;
  workspaceId?: number | undefined;
  iconId?: number | undefined;
  name?: string | undefined;
  token?: string | undefined;
  displayName?: string | undefined;
  description?: string | undefined;
  tags?: string[] | undefined;
  status?: number | undefined;
  height?: number | undefined;
  width?: number | undefined;
  icon?: IconModel | undefined;
  constructor(item?: PBIDashboardServerModel) {
    if (item) {
      this.id = item.id;
      this.iconId = item.iconId;
      this.workspaceId = item.workspaceId;
      this.name = item.name;
      this.token = item.token;
      this.displayName = item.displayName;
      this.status = item.status;
      this.height = item.height;
      this.width = item.width;
      this.description = item.description;
      this.tags = item.tags;
      this.icon = new IconModel(item.icon);
    }
  }
}
