import { Injectable } from "@angular/core";
import { ProductRestService } from "@core/services/product-services/product.rest.service";
import { Observable } from "rxjs";
import { Product } from "../models/product.model";
import { map } from "rxjs/operators";
import { PageResultModel } from "@app/core/models/page-result.server.model";
import { ProductSubscription } from "../models/product-subscription.model";

@Injectable()
export class ProductService {
  count: number;
  constructor(private service: ProductRestService) {}

  getProducts$(
    page: number,
    pageSize: number,
    searchTerm: string,
    field: string,
    order: string,
    getTotal: boolean
  ): Observable<Product[]> {
    return this.service
      .getProducts$(page, pageSize, searchTerm, field, order, getTotal)
      .pipe(
        map((res: any) => {
          if (getTotal) this.count = res.total;
          return res.result.map((p) => new Product(p));
        })
      );
  }
  getProductsCount$(): Observable<number> {
    return this.service.getProductsCount$();
  }
  getProduct$(id: number): Observable<Product> {
    return this.service.getProduct$(id).pipe(
      map((res) => {
        return new Product(res);
      })
    );
  }
  getSubscribedProducts$(): Observable<ProductSubscription[]> {
    return this.service.getSubscribedProducts$().pipe(
      map((res: any) => {
        return res.map((p) => new ProductSubscription(p));
      })
    );
  }
  updateProduct$(product: Product): Observable<Product> {
    return this.service.updateProduct$(product).pipe(
      map((res) => {
        return new Product(res);
      })
    );
  }
  addProduct$(product: Product): Observable<Product> {
    return this.service.addProduct$(product).pipe(
      map((res) => {
        return new Product(res);
      })
    );
  }
  deleteProduct$(productId: number): Observable<boolean> {
    return this.service.deleteProduct$(productId).pipe(
      map((res) => {
        if (res) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
