import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { share, map } from "rxjs/operators";
import { SubscriptionModel } from "../model/subscription.model";
import { SubscriptionRestService } from "@core/services/subscription-services/services/subscription.rest.service";
import { SubscriptionServerModel } from "@core/services/subscription-services/model/subscription.server.model";
import { SubscriptionUsage } from "../model/subscription-usage.model";
import { Coupon } from "../model/coupon.model";
import { Usage } from "../model/usage.model";
import { Feature } from "../../applications/model/feature.model";

@Injectable()
export class SubscriptionService {
  constructor(private service: SubscriptionRestService) {}
  getActiveSubscriptions$(): Observable<SubscriptionModel[]> {
    return this.service.getActiveSubscriptions$().pipe(
      map((res: SubscriptionServerModel[]) => {
        return res.map(subs => {
          return new SubscriptionModel(subs);
        });
      }),
      share()
    );
  }
  getSubscriptions$(): Observable<SubscriptionModel[]> {
    return this.service.getPaymentMethods$().pipe(
      map((res: SubscriptionServerModel[]) =>
        res.map(subs => new SubscriptionModel(subs))
      ),
      share()
    );
  }
  getSubscription$(subsId: number): Observable<SubscriptionModel> {
    return this.service.getSubscription$(subsId).pipe(
      map(res => new SubscriptionModel(res)),
      share()
    );
  }
  createSubscription$(subs: SubscriptionModel): Observable<SubscriptionModel> {
    return this.service.createSubscription$(subs).pipe(
      map((res: SubscriptionServerModel) => new SubscriptionModel(res)),
      share()
    );
  }
  cancelSubscription$(subsId: number): Observable<boolean> {
    return this.service.cancelSubscription$(subsId).pipe(share());
  }
  updateSubscriptionUsage$(
    subsId: number,
    subsUsage: Usage
  ): Observable<boolean> {
    return this.service.updateSubscriptionUsage$(subsId, subsUsage);
  }
  resetUsage(features: Feature[]) {
    return features.map(x => {
      switch (x.dataType.toLowerCase()) {
        case "list": {
          x.value = "[]";
          break;
        }
        case "boolean": {
          x.value = "false";
          break;
        }
        case "number": {
          x.value = "0";
          break;
        }
        default: {
          x.value = "";
          break;
        }
      }
      return x;
    });
  }
  public groupBy = <T>(
    array: Array<T>,
    property: (x: T) => number
  ): { [key: number]: Array<T> } =>
    array.reduce((memo: { [key: number]: Array<T> }, x: T) => {
      if (!memo[property(x)]) {
        memo[property(x)] = [];
      }
      memo[property(x)].push(x);
      return memo;
    }, {});
}
