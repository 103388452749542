<div ngbDropdown
	 placement="bottom-right"
	 *ngIf="this.authService.user"
	 class="kt-header__topbar-item kt-header__topbar-item--user">
	<div ngbDropdownToggle
		 class="kt-header__topbar-wrapper">
		<div class="kt-header__topbar-user"
			 [ngClass]="{'kt-header__topbar-icon': icon}">
			<span class="kt-header__topbar-welcome kt-hidden-mobile"
				  *ngIf="greeting">Hi,</span>
			<span class="kt-header__topbar-username kt-hidden-mobile"
				  *ngIf="greeting">{{authService.user.firstName}}</span>
			<i *ngIf="icon"
			   class="flaticon2-user-outline-symbol"></i>
			<ng-container *ngIf="authService.avatar;else default">
				<div class="kt-avatar__holder bg-transparent">
					<img alt="Pic"
						 (error)="authService.avatar = ''"
						 [attr.src]="getImage()" />
				</div>

			</ng-container>
			<ng-template #default>
				<ng-container>
					<div class="kt-avatar__holder text-center"
						 [ngStyle]="{'color': config.getRandomColor(authService.user.email,1)}">
						<span style="font-size: 28px;">{{config.transformPic(authService.user)}}</span>
					</div>
				</ng-container>
			</ng-template>
			<!-- <img *ngIf="avatar"
				 alt="Pic"
				 [attr.src]="authService.avatar"> -->
			<span *ngIf="badge"
				  class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{{authService.user.firstName|firstLetter}}</span>
		</div>
	</div>
	<div ngbDropdownMenu
		 *ngIf="!authService.teamsApp"
		 class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
		<!--begin: Head -->
		<div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
			 style="background-image: url(./assets/media/misc/bg-1.jpg)">
			<ng-container *ngIf="authService.avatar;else defaultPhoto">
				<div class="kt-user-card__avatar">
					<img alt="Pic"
						 (error)="authService.avatar = ''"
						 [attr.src]="getImage()" />

				</div>
			</ng-container>
			<ng-template #defaultPhoto>
				<ng-container>
					<div class="kt-user-card__avatar no-image"
						 [ngStyle]="{'color': config.getRandomColor(authService.user.email,1)}">
						<span style="font-size: 28px;">{{config.transformPic(authService.user)}}</span>
					</div>
				</ng-container>
			</ng-template>
			<div class="kt-user-card__name">
				{{authService.user.firstName}}
			</div>

		</div>
		<!--end: Head -->

		<!--begin: Navigation -->
		<div class="kt-notification">
			<a ngbDropdownItem
			   routerLink="/profile"
			   href="javascript:;"
			   class="kt-notification__item">
				<div class="kt-notification__item-icon">
					<i class="flaticon2-calendar-3 kt-font-success"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						Profile
					</div>

				</div>
			</a>

			<div class="kt-notification__custom">
				<a href="javascript:;"
				   (click)="authService.logout()"
				   class="btn btn-outline-brand btn-upper btn-sm btn-bold">Sign Out</a>
			</div>
		</div>
		<!--end: Navigation -->


	</div>
</div>