import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {
  SplashScreenService,
  TranslationService,
} from "@theme/metronic/app/core/_base/layout";
import { Subscription } from "rxjs";
// language list
import { locale as enLang } from "@theme/metronic/app/core/_config/i18n/en";
import { locale as chLang } from "@theme/metronic/app/core/_config/i18n/ch";
import { locale as esLang } from "@theme/metronic/app/core/_config/i18n/es";
import { locale as jpLang } from "@theme/metronic/app/core/_config/i18n/jp";
import { locale as deLang } from "@theme/metronic/app/core/_config/i18n/de";
import { locale as frLang } from "@theme/metronic/app/core/_config/i18n/fr";
import { CommonService } from "./core/services/common.service";
import { AppLayoutConfigService } from "./core/services/layout-config.service";
import { DOCUMENT } from "@angular/common";
import { AuthService } from "./core/services/Auth-service/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { SSEService } from "./core/services/notification-service/service/sse.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "body[kt-root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "qx-portal";
  loader: boolean;
  isDoneLoading: boolean = false;
  confirmationDialog = false;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutCongifService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private translationService: TranslationService,
    private dialog: MatDialog,
    private router: Router,
    private commonService: CommonService,
    private layoutConfigService: AppLayoutConfigService,
    private splashScreenService: SplashScreenService,
    public authService: AuthService,
    private sseService: SSEService,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.authService.runInitialLoginSequence();
    this.authService.isDoneLoading$.subscribe(x => {
      if (x) {
        if (this.authService.user && this.authService.user.id && !this.sseService.isConnected) {

          this.sseService.connect(this.authService.user.id);
        }
      }
    })
    //this.configureSingleSignOn();
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );

  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */

  async ngOnInit() {
    // enable/disable loader

    //

    this.loader = this.layoutConfigService.getConfig("loader.enabled");

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add("kt-page--loaded");
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);

  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
