<div class="kt-subheader  kt-grid__item custom"
     id="kt_subheader"
     [ngClass]="{'mt-24-' : dashboard.length == 0}">
     <div class="kt-container  kt-container--fluid ">
          <div class="kt-subheader__main">

               <h3 class="kt-subheader__title">Widgets</h3>
               <span class="kt-subheader__separator kt-subheader__separator--v"></span>
               <a href="javascript:;"
                  *ngIf="edit===false"
                  (click)="enableresizeAndDrag()"
                  title="Edit"
                  class="btn btn-outline-hover-info btn-icon-h kt-margin-l-10">
                    <i class="la la-pencil"></i>Edit
               </a>
               <a href="javascript:;"
                  *ngIf="edit===true"
                  (click)="saveChanges()"
                  title="Save"
                  class="btn btn-outline-hover-info btn-icon-h kt-margin-l-10">
                    <i class="la la-save"></i>Save
               </a>
               <a href="javascript:;"
                  *ngIf="edit===true"
                  (click)="cancelChanges()"
                  title="Discard"
                  class="btn btn-outline-hover-info kt-margin-l-10">
                    <i class="la la-remove"></i>Discard
               </a>

          </div>
          <div class="kt-subheader__main">



               <button class="btn btn-icon b-radius-0"
                       type="button"
                       (click)="toggleVisualHeader()">
                    <i class="fa fa-filter"
                       [ngClass]="{'text-icon':showHeader===false,'text-primary':showHeader===true}"
                       [title]="showHeader===true?'Hide Visual Header':'Show Visual Header'"></i>
               </button>
          </div>
     </div>
</div>
<div class="pulse-container"
     *ngIf="loading">
     <div class="dot-pulse"></div>

</div>
<gridster *ngIf="dashboard.length>0 && !loading"
          [options]="options">
     <gridster-item [item]="item"
                    *ngFor="let item of dashboard;let i=index;">
          <div *ngIf="edit===true"
               class="button-holder">
               <a class="btn btn-brand  m-btn--icon btn-sm"
                  (mousedown)="removeItem($event, item)"
                  (touchstart)="removeItem($event, item)"
                  href="javascript:;">
                    <i class="la la-trash"
                       title="Unpin"></i>
               </a>
          </div>
          <qx-pbi-embedded [page]="'false'"
                           [container]="'visual'+i"
                           [embeddedConfig]='visuals[i].config'>
          </qx-pbi-embedded>
     </gridster-item>
</gridster>
<table *ngIf="dashboard.length===0 && !loading"
       width="90%"
       height="90%"
       class="text-center"
       valign="center">
     <tbody>
          <tr>
               <td class="text-center empty-dashboard">

                    <img [attr.src]="'./assets/media/misc/1-platform.svg'"
                         class="max-100">

                    <br> <strong>Pin report pages or visuals</strong>
               </td>
          </tr>
     </tbody>
</table>
<!-- <div *ngIf="layout.length>0"
     class="settingButtons">
     <div class="col-12">
          <span *ngIf="edit===false"
                class="link kt-font-primary"
                (click)="enableresizeAndDrag()"
                title="Edit">
               <i class="la la-pencil"></i> Edit
          </span>
          <span *ngIf="edit===true"
                (click)="saveChanges()"
                title="Save">
               <i class="la la-save"></i> Save
          </span>
          <span *ngIf="edit===true"
                (click)="cancelChanges()"
                title="Discard">
               <i class="la la-remove"></i> Discard
          </span>

          <span (click)="AddItem()"
                title="Add">
               <i class="fa fa-plus"></i> Add
          </span>
     </div>
</div>

<div class="gridster-custom">
     <gridster [options]="options"
               style="background-color: transparent;"
               *ngIf="layout.length>0">
          <ng-container *ngFor="let widget of layout; let i=index;"
                        style="overflow: hidden;">
               <gridster-item [item]="widget"
                              (dragover)="setDropId(widget.id)">

                    <div *ngIf="edit===true"
                         class="widget-header">
                         <a class="btn btn-brand m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill"
                            (click)="remove(widget.id)"
                            href="javascript:;">
                              <i class="la la-trash"
                                 title="Unpin"></i>
                         </a>
                    </div>
                    Hellow
                    

               </gridster-item>
          </ng-container>

     </gridster>
</div> -->