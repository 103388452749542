import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { PBIDashboardServerModel } from "../models/dashboard.server.model";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable({ providedIn: "root" })
export class PowerBIDashboardRestService extends BaseService {
  routUrl = "";
  page = 0;
  pageSize = 5;
  name = "";
  sortOrder = "name_asc";

  constructor(private http: HttpClient) {
    super();
  }
  getAllDashboards$(
    page: number,
    pageSize: number,
    query: string,
    field: string,
    order: string,
    getTotal: boolean,
    status: boolean
  ): Observable<PageResultModel<PBIDashboardServerModel>> {
    let url_ = this.baseUrl + `/dashboards/company?`;
    url_ += "page=" + encodeURIComponent("" + page) + "&";
    url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";
    url_ += "name=" + encodeURIComponent("" + query) + "&";
    url_ += "sortOrder=" + encodeURIComponent("" + field + "_" + order) + "&";
    url_ += "getTotal=" + encodeURIComponent("" + getTotal) + "&";
    url_ += "status=" + encodeURIComponent("" + status);
    return this.http.get<PageResultModel<PBIDashboardServerModel>>(url_);
  }
  getRecentDashboards$(): Observable<PBIDashboardServerModel[]> {
    this.routUrl = this.baseUrl + `/dashboards/recent/my`;
    return this.http.get<PBIDashboardServerModel[]>(this.routUrl);
  }
  getFrequentDashboards$(): Observable<PBIDashboardServerModel[]> {
    this.routUrl = this.baseUrl + `/dashboards/frequent/my`;
    return this.http.get<PBIDashboardServerModel[]>(this.routUrl);
  }
  getDashboards$(
    workspaceId: number,
    search?: string
  ): Observable<PageResultModel<PBIDashboardServerModel>> {
    const _url = this.baseUrl + `/workspaces/${workspaceId}/dashboards`;
    let url_ = _url + "?";
    url_ += "page=" + encodeURIComponent("" + this.page) + "&";
    url_ += "pageSize=" + encodeURIComponent("" + this.pageSize) + "&";
    url_ += "name=" + encodeURIComponent("" + search) + "&";
    url_ += "sortOrder=" + encodeURIComponent("" + this.sortOrder);

    return this.http.get<PageResultModel<PBIDashboardServerModel>>(_url);
    //return of(this.dashboards.filter(x => x.workspaceId == workspaceId));
  }
  getUserDashboards$(): Observable<PBIDashboardServerModel[]> {
    const _url = this.baseUrl + `/application/powerbi/dashboards/my`;
    let url_ = _url + "?";
    url_ += "page=" + encodeURIComponent("" + this.page) + "&";
    url_ += "pageSize=" + encodeURIComponent("" + this.pageSize) + "&";
    url_ += "name=" + encodeURIComponent("" + this.name) + "&";
    url_ += "sortOrder=" + encodeURIComponent("" + this.sortOrder);

    return this.http.get<PBIDashboardServerModel[]>(_url);
  }
  getDashboardsCount$(): Observable<number> {
    const _url = this.baseUrl + `/dashboards/company/count`;
    return this.http.get<number>(_url);
  }
  getDashboardsCountByStatus$(status: number): Observable<number> {
    return of(this.dashboards.filter((x) => x.status == status).length);
  }
  getDashboard$(id: number): Observable<PBIDashboardServerModel> {
    this.routUrl = this.baseUrl + `/dashboards/${id}`;
    return this.http.get<PBIDashboardServerModel>(this.routUrl);
    //return of(this.dashboards.find(x => x.id == id));
  }
  updateDashboard$(
    dashboard: PBIDashboardServerModel
  ): Observable<PBIDashboardServerModel> {
    this.routUrl = this.baseUrl + `/dashboards`;
    return this.http.put<PBIDashboardServerModel>(this.routUrl, dashboard);
  }
  remove$(dashboard: PBIDashboardServerModel): Observable<boolean> {
    this.routUrl = this.baseUrl + `/dashboards/remove`;
    return this.http.put<boolean>(this.routUrl, dashboard);
  }
  syncDashboard$(id: number): Observable<boolean> {
    this.routUrl = this.baseUrl + `/dashboards/${id}/sync`;
    return this.http.get<boolean>(this.routUrl);
  }
  getDashboardEmbedConfig$(
    dashboardId: number
  ): Observable<PowerBIEmbedConfig> {
    this.routUrl =
      this.baseUrl +
      `/application/powerbi/dashboards/${dashboardId}/embed-config`;
    return this.http.get<PowerBIEmbedConfig>(this.routUrl);
  }
}
