import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject, of, Observable } from "rxjs";
import { MessageParams } from "../model/messageParams";
import { BaseService } from "@core/services/base.service";
import { AuthenticationMethodModel } from "@app/power-bi/components/power-bi-details/workspace-details/reports/model/authentication-method.model";
import { RoleModel } from "@app/roles-management/model/role.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";
import { RoleServerModel } from "@app/core/services/role-service/model/role.server.model";
import { COUNTRIES } from "./countries";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class LocalStorageDataService extends BaseService {
  signInMethods = [
    { id: 1, name: "Quick Analytix" },
    { id: 2, name: "Microsoft" }
  ];
  private authMethodsUrl = "";
  private rolesUrl = this.baseUrl + "/roles";
  private unsubscribe$$ = new Subject<void>();
  private get errorString(){
    return "Failed to {_action_} {_object_}.";
  }
  private get successString(){
    return "{_object_} has been {_action_}d successfully.";
  }
  constructor(private http: HttpClient) {
    super();
    this.authMethodsUrl = this.baseUrl + "/authentication-methods";
  }
  async resetRoles() {
    if (!localStorage.getItem("roles")?.length) {
      const roles = await this.http
        .get<PageResultModel<RoleServerModel>>(this.rolesUrl)
        .toPromise();
      if (roles) {
        localStorage.setItem("roles", JSON.stringify(roles.result));
        }
    }
  }
  getCountries$(): Observable<any[]> {
    return of(COUNTRIES)
  }
  getCountryFlag(countryName: string) {
    let flag = "";
    this.getCountries$()
      .toPromise()
      .then(x => {
        const country = x.find(
          x => x.name.toLowerCase() === countryName.toLowerCase()
        );
        if (country) {
          const code = country.alpha2Code;
          flag = `https://countryflagsapi.com/png/${code}`;
        }
      });
    return flag;
  }
  getMessage(param: MessageParams) {
    return (param.type == "error" ? this.errorString: this.successString)
        .replace("{_object_}", param.object)
        .replace("{_action_}", param.crud)
        .replace("pind", "pinned")
        .replace("syncd", "synced")
        .replace("refundd", "refunded")
      .replace("Dns", "DNS")
        .replace(/^"(.*)"$/, "$1");
  }
  getAuthMethods$(): Observable<AuthenticationMethodModel[]> {
    let authMethodsString = localStorage.getItem("authMethods");
    if (authMethodsString && JSON.parse(authMethodsString).length > 0) {
      return of(JSON.parse(authMethodsString));
    }
    return this.http
      .get<AuthenticationMethodModel[]>(this.authMethodsUrl)
      .pipe(map(x => {
        localStorage.setItem("authMethods", JSON.stringify(x));
        return x;
      }));
  }
  getRoles$(): Observable<RoleModel[]> {
    let rolesString = localStorage.getItem("roles");
    if (rolesString && JSON.parse(rolesString).length > 0) {
      return of(JSON.parse(rolesString));
    }
    return this.http
      .get<PageResultModel<RoleServerModel>>(this.rolesUrl)
      .pipe(map(x => {
        localStorage.setItem("roles", JSON.stringify(x.result));
        return x.result;
      }));
  }
  clearAllIfVersionUpdate() {
    var version = localStorage.getItem('appVersion');
    if (!version?.length || version !== environment.appVersion) {
      localStorage.clear();
      localStorage.setItem('appVersion', environment.appVersion)
    }
  }
  ngOnDestroy() {
    this.unsubscribe$$.next();
  }
}
