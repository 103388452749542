import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PowerBIRenderComponent } from "./power-bi-render.component";
import { Routes, RouterModule } from "@angular/router";
import { RenderReportComponent } from "./render-report/render-report.component";
import { PowerBIReportService } from "@app/power-bi/components/power-bi-details/workspace-details/reports/service/power-bi-report.service";
import { PowerBIPageService } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/pages/service/power-bi-page.service";
import { RenderPageComponent } from "./render-page/render-page.component";
import { RenderDashboardComponent } from "./render-dashboard/render-dashboard.component";
import { RenderBookmarkComponent } from "./render-bookmark/render-bookmark.component";
import { PowerBIDashboardService } from "@app/power-bi/components/power-bi-details/workspace-details/dashboards/service/power-bi-dashboard.service";
import { PowerBIBookmarkService } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/bookmarks/service/power-bi-bookmark.service";
import { PowerBIDashboardVisualService } from "./service/pbi-dashboard-visual.service";
import { PinnedVisualsComponent } from "./pinned-visuals/pinned-visuals.component";
import { ShareDatastoryComponent } from "./share-data-story/share-data-story.component";
import { PortletModule } from "@theme/metronic/app/views/partials/content/general/portlet/portlet.module";
import { SharedModules } from "@app/shared/module/shared.modules";
import { DataStoryService } from "./service/data-story.service";
import { TeamService } from "@app/teams/service/team.service";
import { UserService } from "@app/users-management/service/user.service";
import { RenderDatastoryComponent } from "./render-datastory/render-datastory.component";
import { PBIEmbeddedComponent } from "./pbi-embedded/pbi-embedded.component";
import { GridsterModule } from "angular-gridster2";

const routes: Routes = [
  {
    path: "",
    component: PowerBIRenderComponent,
    children: [
      {
        path: "widgets",
        component: PinnedVisualsComponent,
      },
      {
        path: "dashboard/:id",
        component: RenderDashboardComponent,
      },
      {
        path: "report/:id",
        component: RenderReportComponent,
      },
      {
        path: "report/:id/:page",
        component: RenderReportComponent,
      },
      {
        path: "page/:id",
        component: RenderPageComponent,
      },
      {
        path: "bookmark/:id",
        component: RenderBookmarkComponent,
      },
      {
        path: "datastory/:id",
        component: RenderDatastoryComponent,
      },
    ],
  },
];
@NgModule({
  imports: [
    CommonModule,
    SharedModules,
    PortletModule,
    RouterModule.forChild(routes),
    GridsterModule,
  ],
  declarations: [
    PowerBIRenderComponent,
    RenderReportComponent,
    PBIEmbeddedComponent,
    RenderPageComponent,
    RenderDashboardComponent,
    RenderBookmarkComponent,
    PinnedVisualsComponent,
    ShareDatastoryComponent,
    RenderDatastoryComponent,
  ],
  entryComponents: [ShareDatastoryComponent],
  providers: [
    PowerBIReportService,
    PowerBIPageService,
    PowerBIDashboardService,
    PowerBIBookmarkService,
    PowerBIDashboardVisualService,
    DataStoryService,
    TeamService,
    UserService,
  ],
})
export class PowerBIRenderModule {}
