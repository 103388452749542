// Angular
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
// RxJS
import { filter } from "rxjs/operators";
// Object-Path
import * as objectPath from "object-path";
// Layout
import {
  LayoutConfigService,
  MenuConfigService,
  MenuHorizontalService,
  MenuOptions,
  OffcanvasOptions,
} from "../../../../core/_base/layout";
// HTML Class
import { HtmlClassService } from "../../html-class.service";
import { DataStoryModel } from "@app/power-bi-render/model/data-story.model";
import { NotifyService } from "@app/shared/services/notify.service";
import { ShareDatastoryComponent } from "@app/power-bi-render/share-data-story/share-data-story.component";
import { UserAction, systemObject } from "@app/shared/model/user-action.enum";
import { MatDialog } from "@angular/material/dialog";
import { HorizontalMenuModel } from "@app/core/services/user-menu-service/model/horizontal-menu.model";
import { AuthService } from "@app/core/services/Auth-service/auth.service";

@Component({
  selector: "kt-menu-horizontal",
  templateUrl: "./menu-horizontal.component.html",
  styleUrls: ["./menu-horizontal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHorizontalComponent implements OnInit, AfterViewInit {
  // Public properties
  currentRouteUrl: any = "";

  rootArrowEnabled: boolean;

  menuOptions: MenuOptions = {
    submenu: {
      desktop: "dropdown",
      tablet: "accordion",
      mobile: "accordion",
    },
    accordion: {
      slideSpeed: 200, // accordion toggle slide speed in milliseconds
      expandAll: false, // allow having multiple expanded accordions in the menu
    },
    dropdown: {
      timeout: 50,
    },
  };

  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: "kt-header-menu-wrapper",
    closeBy: "kt_header_menu_mobile_close_btn",
    toggleBy: {
      target: "kt_header_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active",
    },
  };

  /**
   * Component Conctructor
   *
   * @param el: ElementRef
   * @param htmlClassService: HtmlClassService
   * @param menuHorService: MenuHorService
   * @param menuConfigService: MenuConfigService
   * @param layoutConfigService: LayouConfigService
   * @param router: Router
   * @param render: Renderer2
   * @param cdr: ChangeDetectorRef
   */
  constructor(
    private el: ElementRef,
    public htmlClassService: HtmlClassService,
    public menuHorService: MenuHorizontalService,
    private menuConfigService: MenuConfigService,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private render: Renderer2,
    private cdr: ChangeDetectorRef,
    private notifyService: NotifyService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * After view init
   */
  ngAfterViewInit(): void {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.rootArrowEnabled = this.layoutConfigService.getConfig(
      "header.menu.self.root-arrow"
    );

    this.currentRouteUrl = this.router.url;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.currentRouteUrl = this.router.url;
        this.cdr.markForCheck();
      });
  }

  /**
   * Return Css Class Name
   * @param item: any
   */
  getItemCssClasses(item) {
    let classes = "kt-menu__item";

    if (objectPath.get(item, "submenu")) {
      classes += " kt-menu__item--submenu";
    }

    if (!item.submenu && this.isMenuItemIsActive(item)) {
      classes += " kt-menu__item--active kt-menu__item--here";
    }

    if (item.submenu && this.isMenuItemIsActive(item)) {
      classes += " kt-menu__item--open kt-menu__item--here";
    }

    if (objectPath.get(item, "resizer")) {
      classes += " kt-menu__item--resize";
    }

    const menuType = objectPath.get(item, "submenu.type") || "classic";
    if (
      (objectPath.get(item, "root") && menuType === "classic") ||
      parseInt(objectPath.get(item, "submenu.width"), 10) > 0
    ) {
      classes += " kt-menu__item--rel";
    }

    const customClass = objectPath.get(item, "custom-class");
    if (customClass) {
      classes += " " + customClass;
    }

    if (objectPath.get(item, "icon-only")) {
      classes += " kt-menu__item--icon-only";
    }

    return classes;
  }

  /**
   * Returns Attribute SubMenu Toggle
   * @param item: any
   */
  getItemAttrSubmenuToggle(item) {
    let toggle = "hover";
    if (objectPath.get(item, "toggle") === "click") {
      toggle = "click";
    } else if (objectPath.get(item, "submenu.type") === "tabs") {
      toggle = "tabs";
    } else {
      // submenu toggle default to 'hover'
    }

    return toggle;
  }

  /**
   * Returns Submenu CSS Class Name
   * @param item: any
   */
  getItemMenuSubmenuClass(item) {
    let classes = "";

    const alignment = objectPath.get(item, "alignment") || "right";

    if (alignment) {
      classes += " kt-menu__submenu--" + alignment;
    }

    const type = objectPath.get(item, "type") || "classic";
    if (type === "classic") {
      classes += " kt-menu__submenu--classic";
    }
    if (type === "tabs") {
      classes += " kt-menu__submenu--tabs";
    }
    if (type === "mega") {
      if (objectPath.get(item, "width")) {
        classes += " kt-menu__submenu--fixed";
      }
    }

    if (objectPath.get(item, "pull")) {
      classes += " kt-menu__submenu--pull";
    }

    return classes;
  }

  /**
   * Check Menu is active
   * @param item: any
   */
  isMenuItemIsActive(item): boolean {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return this.currentRouteUrl.indexOf(item.page) !== -1;
  }

  /**
   * Check Menu Root Item is active
   * @param item: any
   */
  isMenuRootItemIsActive(item): boolean {
    if (item.submenu.items) {
      for (const subItem of item.submenu.items) {
        if (this.isMenuItemIsActive(subItem)) {
          return true;
        }
      }
    }

    if (item.submenu.columns) {
      for (const subItem of item.submenu.columns) {
        if (this.isMenuItemIsActive(subItem)) {
          return true;
        }
      }
    }

    if (typeof item.submenu[Symbol.iterator] === "function") {
      for (const subItem of item.submenu) {
        const active = this.isMenuItemIsActive(subItem);
        if (active) {
          return true;
        }
      }
    }

    return false;
  }
  isReport(page: string) {
    return page && page.toLowerCase().includes("report");
  }

  isBookmark(item: any) {
    return item && item.title.toLowerCase().includes("bookmark");
  }
  isParentReport(parent: any) {
    if (parent == undefined || parent == null) return false;
    return parent.title === 'Report';
  }
  getUrl(page: string) {
    let result = "javascript:;";
    if (page && page.includes("report")) {
      return "";
    }
  }

  isDatastory(item: HorizontalMenuModel) {
    return item.page && item.page.includes("datastory") && item.isPersonal;
  }
  shareDatastory(id: number) {
    const bmk: DataStoryModel = new DataStoryModel();
    bmk.id = id;

    this.dialog
      .open(ShareDatastoryComponent, {
        data: { dataStory: { ...{}, ...bmk }, audience: true },
      })
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          this.notifyService.success({
            crud: UserAction.Crud.share,
            object: systemObject.dataStory,
          });
        }
      });
  }
  goTo(page: string) {
    if (page && page.includes("report")) {
      this.router.navigateByUrl(page);
    }
  }
  isClient() {
    return !this.authService.isAdmin && !this.authService.isManager;
  }
}
