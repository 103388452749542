import { Injectable } from "@angular/core";
import { UserModel } from "../model/user.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserRestService } from "@app/core/services/user-service/service/user.rest.service";
import { UserServerModel } from "@app/core/services/user-service/model/user.server.model";
import { UpdatePasswordModel } from "../model/update-password.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable()
export class UserService {
  constructor(private service: UserRestService) { }
  userPageResult: PageResultModel<UserModel> = new PageResultModel<UserModel>();
  getUsers$(
    pageNumber?: number,
    pageSize?: number,

    roleIds?: number[],
    teamIds?: number[],
    query?: string,
    getTotal?: boolean
  ): Observable<PageResultModel<UserModel>> {
    return this.service
      .getUsers$(pageNumber, pageSize, roleIds, teamIds, query, getTotal)
      .pipe(
        map((res: PageResultModel<UserServerModel>) => {
          this.userPageResult.result = res.result.map(r => new UserModel(r));
          this.userPageResult.total = res.total;
          return new PageResultModel<UserModel>(res);
        })
      );
  }
   syncUsers$(){
    return this.service.syncUsers$()
    .pipe(
      map((res:UserServerModel[])=>{
        return res.map(r=> new UserModel(r));
      })
    )
  }
  getAssociatedUsers$(pageId: number): Observable<UserModel[]> {
    return this.service.getAssociatedUsers$(pageId).pipe(
      map((res: UserServerModel[]) => {
        return res.map(r => new UserModel(r));
      })
    );
  }
  getUsersByCompany$(
    companyId?: number,
    pageNumber?: number,
    pageSize?: number,
    roleIds?: number[],
    query?: string,
    getTotal?: boolean
  ): Observable<PageResultModel<UserModel>> {
    return this.service
      .getUsersByCompany$(
        companyId,
        pageNumber,
        pageSize,
        roleIds,
        query,
        getTotal
      )
      .pipe(
        map((res: PageResultModel<UserServerModel>) => {
          this.userPageResult.result = res.result.map(r => new UserModel(r));
          this.userPageResult.total = res.total;
          return new PageResultModel<UserModel>(this.userPageResult);
        })
      );
  }
  getCompanyUsers$(
    pageNumber?: number,
    pageSize?: number,
    teamIds?: number[],
    roleIds?: number[],
    query?: string,
    getTotal?: boolean,
  ): Observable<PageResultModel<UserModel>> {
    return this.service
      .getCompanyUsers$(pageNumber, pageSize, teamIds, roleIds, query, getTotal)
      .pipe(
        map((res: PageResultModel<UserServerModel>) => {
          this.userPageResult.result = res.result.map(r => new UserModel(r));
          this.userPageResult.total = res.total;
          return new PageResultModel<UserModel>(this.userPageResult);
        })
      );
  }
  getUser$(userId: number): Observable<UserModel> {
    return this.service.getUser$(userId).pipe(map(res => new UserModel(res)));
  }
  getCurrentUserDetail$(): Observable<UserModel> {
    return this.service
      .getCurrentUserDetail$()
      .pipe(map(res => new UserModel(res)));
  }
  updatePassword$(password: UpdatePasswordModel): Observable<boolean> {
    return this.service.updatePassword$(password);
  }
  getCompanyUsersCount$(): Observable<number> {
    return this.service.getCompanyUsersCount$();
  }
  getUsersCountByCompany$(companyId: number): Observable<number> {
    return this.service.getUsersCountByCompany$(companyId);
  }
  createUser$(user: UserModel): Observable<UserModel> {
    return this.service.createUser$(user).pipe(map(res => new UserModel(res)));
  }
  updateUser$(user: UserModel): Observable<UserModel> {
    return this.service.updateUser$(user).pipe(map(res => new UserModel(res)));
  }
  createCompanyUser$(user: UserModel): Observable<UserModel> {
    return this.service
      .createCompanyUser$(user)
      .pipe(map(res => new UserModel(res)));
  }
  updateCompanyUser$(user: UserModel): Observable<UserModel> {
    return this.service
      .updateCompanyUser$(user)
      .pipe(map(res => new UserModel(res)));
  }
  updateSyncUsers$(users:UserModel[]){
    return this.service.updateSyncUsers$(users)
  }
  updateMyDetails$(user: UserModel): Observable<UserModel> {
    return this.service
      .updateMyDetails$(user)
      .pipe(map(res => new UserModel(res)));
  }
  uploadPhoto$(file: File): Observable<UserModel> {
    return this.service.uploadPhoto$(file).pipe(map(res => new UserModel(res)));
  }
}
