import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { BookmarkModel } from "../model/bookmark.model";
import { map } from "rxjs/operators";
import { BookmarkServerModel } from "@app/core/services/bookmark-service/models/bookmark.server.model";
import { PowerBIBookmarkRestService } from "@app/core/services/bookmark-service/services/power-bi-bookmark.rest.service";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";

@Injectable()
export class PowerBIBookmarkService {
  constructor(private service: PowerBIBookmarkRestService) {}

  getBookmarks$(reportId: number): Observable<BookmarkModel[]> {
    return this.service.getBookmarks$(reportId).pipe(
      map((res: BookmarkServerModel[]) => {
        return res.map(x => new BookmarkModel(x));
      })
    );
  }
  getBookmark$(id: number): Observable<BookmarkModel> {
    return this.service
      .getBookmark$(id)
      .pipe(map((res: BookmarkServerModel) => new BookmarkModel(res)));
  }
  updateBookmark$(bookmark: BookmarkModel): Observable<BookmarkModel> {
    return this.service
      .updateBookmark$(bookmark)
      .pipe(map((res: BookmarkServerModel) => new BookmarkModel(res)));
  }
  remove$(bookmark: BookmarkModel): Observable<boolean> {
    return this.service.remove$(bookmark);
  }
  syncBookmark$(id: number, bmks: BookmarkModel[]): Observable<boolean> {
    return this.service.syncBookmark$(id, bmks);
  }
  getBookmarkEmbedConfig$(bookmarkId: number): Observable<PowerBIEmbedConfig> {
    return this.service.getBookmarkEmbedConfig$(bookmarkId);
  }
}
