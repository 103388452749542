<kt-portlet>
  <!-- PORTLET LOADING | Binded to TABLE Datasource -->
  <!-- See prop => '~/core/_crud/models/data-sources/_base.datasource.ts' (loading$) -->
  <kt-portlet-header [title]="'Data Story'">
    <ng-container ktPortletTools>
      <button (click)="cancel()"
              class="btn  btn-sm btn-icon btn-icon-md mr-2"
              title="Close">
        <i class="fa fa-times"></i>
      </button>
    </ng-container>
  </kt-portlet-header>
  <!-- end::Header -->
</kt-portlet>
<!-- start::Body (attribute: ktPortletBody) -->
<kt-portlet>

  <kt-portlet-body>
    <form #form="ngForm"
          (ngSubmit)="onSubmit()"
          novalidate
          [formGroup]="storyForm">

      <div class="col-12"
           *ngIf="!data.dataStory.id || data.dataStory.id <1">
        <label> Title <sup>*</sup>:</label>
        <mat-form-field appearance="fill"
                        class="mat-form-field-fluid">
          <input matInput
                 formControlName="title"
                 placeholder="Title" />
          <div class="form-control-error">
            <mat-error *ngIf="storyForm.get('title').errors?.required">Title is
              required
            </mat-error>
            <mat-error *ngIf="storyForm.get('title').errors?.minlength">Title should be at least
              3 characters long.
            </mat-error>
            <mat-error *ngIf="storyForm.get('title').errors?.maxlength">Title should not be longer than
              50 characters.
            </mat-error>
          </div>
        </mat-form-field>

      </div>

      <div class="col-12 example-chip-list"
           *ngIf="data?.audience">
        <label>Members: </label>
        <mat-form-field appearance="fill"
                        class="mat-form-field-fluid">
          <mat-chip-list #chipList
                         aria-label="Member selection">
            <mat-chip *ngFor="let user of users"
                      [selectable]="selectable"
                      [removable]="removable"
                      (removed)="remove(user)">
              {{user}}
              <mat-icon matChipRemove
                        *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Add Member..."
                   #userInput
                   [formControl]="userCtrl"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete"
                            (optionSelected)="selected($event)">
            <mat-option *ngFor="let user of filteredUsers | async"
                        [value]="user">
              {{user}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="col-12  tArea"
           *ngIf="!data.dataStory.id || data.dataStory.id <1">
        <label> Description:</label>
        <mat-form-field appearance="fill"
                        class="mat-form-field-fluid">
          <textarea matInput
                    formControlName="description"></textarea>


        </mat-form-field>
      </div>
      <div class="col-12"
           *ngIf="!data.dataStory.id || data.dataStory.id <1">
        <label> Expiry Date<sup>*</sup>:</label>
        <span class="datepicker">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput
                   placeholder=""
                   [matDatepicker]="datepickerRef"
                   (focus)="datepickerRef.open()"
                   [min]="minDate"
                   formControlName="expiryDate" />
            <mat-datepicker-toggle [for]="datepickerRef"
                                   matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datepickerRef></mat-datepicker>
            <div class="form-control-error">
              <mat-error *ngIf="storyForm.get('expiryDate').errors?.required">Expiry Date is
                required
              </mat-error>
            </div>
          </mat-form-field>
        </span>

      </div>


      <div class="col-12 text-right">
        <button class="btn btn-primary dialog-ftr  mr-2"
                [ngClass]="{
        'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light': loading
      }"
                color="success"
                type="submit">{{!data.dataStory.id || data.dataStory.id <1?"Capture":"Share"}} </button>
            <button class="btn btn-secondary dialog-ftr mr-2"
                    type="button"
                    (click)="cancel()">Cancel
            </button>
      </div>

    </form>

  </kt-portlet-body>
</kt-portlet>
