import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { BaseService } from "../../base.service";
import { CatalogRestModel } from "../model/catalog-rest.model";
import { Observable } from "rxjs";
import { Catalog } from "@app/powerbi-server/components/powerbi-server-detail/models/catalog.model";
import { map } from "rxjs/operators";
import { OnPremPageServerModel } from "../model/on-prem-page-server.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";
@Injectable({
  providedIn: "root",
})
export class PowerBIServerRestService extends BaseService {
  routUrl = "";
  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + `/powerbionprem/`;
  }
  catalogs: CatalogRestModel[] = [];
  // make get call for api and return observable of type in power-bi/models/*.ts
  getByParentFolderId$(
    profileId: number,
    parentFolderId: string,
    page?: number,
    pageSize?: number,
    query?: string
  ): Observable<CatalogRestModel[]> {
    let url_ = this.routUrl + `catalogs?`;
    url_ += "profileId=" + encodeURIComponent("" + profileId) + "&";
    if (parentFolderId != undefined)
      url_ += "parentId=" + encodeURIComponent("" + parentFolderId) + "&";
    if (page != undefined)
      url_ += "page=" + encodeURIComponent("" + page) + "&";
    if (pageSize != undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";
    if (query != undefined || null)
      url_ += "searchTerm=" + encodeURIComponent("" + query) + "&";

    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<CatalogRestModel[]>(url_);
  }
  getAllReports$(
    page: number,
    pageSize: number,
    query: string,
    reportId: number,
    sort: string,
    getTotal: boolean,
    status: boolean
  ): Observable<PageResultModel<Catalog>> {
    let url_ = this.routUrl + "?";
    if (page !== null && page !== undefined)
      url_ += "page=" + encodeURIComponent("" + page) + "&";
    if (pageSize !== null && pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";
    if (reportId !== null && reportId !== undefined)
      url_ += "reportId=" + encodeURIComponent("" + reportId) + "&";
    if (query !== null && query !== undefined)
      url_ += "searchTerm=" + encodeURIComponent("" + query) + "&";
    if (sort !== null && sort !== undefined)
      url_ += "sortOrder=" + encodeURIComponent("" + sort) + "&";
    if (getTotal !== null && getTotal !== undefined)
      url_ += "getTotal=" + encodeURIComponent("" + getTotal) + "&";
    if (status !== null && status !== undefined)
      url_ += "status=" + encodeURIComponent("" + status);

    return this.http.get<PageResultModel<Catalog>>(url_);
  }
  getReportsCount$(): Observable<number> {
    const url = this.routUrl + `reports/active/count`;
    return this.http.get<number>(url);
  }
  renderReport$(reportId: number): Observable<any> {
    const url = this.routUrl + `reports` + `/${reportId}/render/url`;
    return this.http.get<any>(url);
  }
  getActiveReports$(query?: string): Observable<Catalog[]> {
    let url = this.routUrl + `reports/active?`;
    if (query != undefined || null)
      url += "searchTerm=" + encodeURIComponent("" + query) + "&";

    url = url.replace(/[?&]$/, "");
    return this.http.get<CatalogRestModel[]>(url);
  }
  getMyOnPremReports$(query?: string): Observable<Catalog[]> {
    let url = this.routUrl + `reports/my?`;
    if (query != undefined || null)
      url += "searchTerm=" + encodeURIComponent("" + query) + "&";

    url = url.replace(/[?&]$/, "");
    return this.http.get<CatalogRestModel[]>(url);
  }

  getFavouriteReports$(): Observable<CatalogRestModel[]> {
    const url = this.routUrl + `reports/favourite/my`;
    return this.http.get<CatalogRestModel[]>(url);
  }
  addFavourite$(id: number): Observable<boolean> {
    const url = this.routUrl + `reports/${id}/favourite`;
    return this.http.post<any>(url, id).pipe(map((x) => (x ? true : false)));
  }
  removeFavourite$(id: number): Observable<boolean> {
    const url = this.routUrl + `reports/${id}/unfavourite`;
    return this.http.delete<boolean>(url).pipe(map(() => true));
  }
  updateReportStatus$(catalogId: number, status: number): Observable<boolean> {
    const url = this.routUrl + `reports/${catalogId}/status/${status}`;
    return this.http.patch<boolean>(url, null);
  }
  updateReport$(catalog: Catalog): Observable<CatalogRestModel> {
    const url = this.routUrl + `reports`;
    return this.http.put<CatalogRestModel>(url, catalog);
  }
  syncReportPages$(reportId: number, pages: string[]) {
    const url = this.routUrl + `reports/${reportId}/sync`;
    return this.http.post(url, pages);
  }

  // Pages

  getPages$(reportId: number, status?: boolean): Observable<OnPremPageServerModel[]> {
    let url_ = this.routUrl + `reports/${reportId}/pages`;
    if (status) {
      url_ = url_ + "?";
      url_ += "status=" + encodeURIComponent("" + status);
    }
    return this.http.get<OnPremPageServerModel[]>(url_);
  }
  getPage$(id: number): Observable<OnPremPageServerModel> {
    let url_ = this.routUrl + `pages/${id}`;
    return this.http.get<OnPremPageServerModel>(this.url_);
  }
  updatePage$(page: OnPremPageServerModel): Observable<OnPremPageServerModel> {
    let url_ = this.routUrl + `pages/${page.id}`;
    return this.http.put<OnPremPageServerModel>(url_, page);
  }
  updatePageOrder$(pages: OnPremPageServerModel[]): Observable<OnPremPageServerModel[]> {
    let url_ = this.routUrl + `pages/orders`;
    return this.http.put<OnPremPageServerModel[]>(url_, pages);
  }
  remove$(page: OnPremPageServerModel): Observable<boolean> {
    let url_ = this.routUrl + `pages/${page.id}/remove`;
    return this.http.put<boolean>(url_, page);
  }

}
