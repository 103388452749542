import { Component, OnInit } from "@angular/core";

@Component({
  selector: "qx-power-bi-render",
  template:`<router-outlet></router-outlet>`
})
export class PowerBIRenderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
