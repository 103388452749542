
// export enum UserAction {
//   crud = "crud",
//   other = "other"
// }

export namespace UserAction {
  export enum Crud {
    get = "load",
    create = "create",
    update = "update",
    delete = "remove",
    pin = "pin",
    refund = "refund",
    refresh = "synchronize",
    share = "share",
    sync = "sync",
    CreateAndShare = "created and share",
  }

  export enum Other {
    create = "refund",
    update = "update",
    delete = "remove",
  }
}
export enum notifyTitle {
  get = "load",
  create = "new",
  update = "modified",
  delete = "removed",
  pin = "pin",
  refund = "refund",
  refresh = "sync",
  sync = "sync",
  share = "share",
}
export enum systemObject {
  role = "Role",
  permission = "Permission",
  product = "Products",
  package = "Package",
  paymentCard = "Payment Method",
  invoice = "Invoice",
  coupon = "Coupon",
  subscription = "Subscription",

  application = "Application",
  feature = "Feature",

  pbiProfile = "Power BI Profile",
  workspace = "Workspace",
  dashboard = "Dashboard",
  report = "Report",
  OnPremReport = "OnPremReport",
  reportDataset = "Report Dataset",
  page = "Page",
  dataStory = "Data Story",
  visual = "Visual",
  bookmark = "Bookmark",
  team = "Team",
  teamUsers = "Team Users",
  teamDashboards = "Team Dashboards",
  teamPages = "Team Pages",
  user = "User",
  company = "Company",
  logo = "Logo",
  profile = "Profile",
  profilePhoto = "Profile Photo",
  password = "Password",
  serverReport = "PowerBI Server Report",
  teamServerReports = "PowerBI Server Team Reports",
  widget = "Widget",
  icon = "Icon",
  dns = "DNS Record"
}
