import { OnInit, ChangeDetectorRef, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotifyService } from "@app/shared/services/notify.service";
import { UserModel } from "@app/users-management/model/user.model";
import { TeamModel } from "@app/teams/model/team.model";
import { TeamService } from "@app/teams/service/team.service";
import { DataStoryService } from "../service/data-story.service";
import { UserAction, systemObject } from "@app/shared/model/user-action.enum";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { UserService } from "@app/users-management/service/user.service";
import { take, takeUntil, startWith, map } from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import { AppLayoutConfigService } from "@app/core/services/layout-config.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, ViewChild } from "@angular/core";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
  selector: "qx-share-data-story",
  templateUrl: "./share-data-story.component.html",
  styleUrls: ["./share-data-story.component.scss"],
})
export class ShareDatastoryComponent implements OnInit, OnDestroy {
  storyForm: FormGroup;
  minDate = new Date();
  loading = false;
  title = "Capture & Share Data Story";
  teams: TeamModel[] = [];
  private unsubscribe$$ = new Subject<void>();
  constructor(
    private fb_storyForm: FormBuilder,
    private teamService: TeamService,
    private userService: UserService,
    private dialogRef: MatDialogRef<ShareDatastoryComponent>,
    private layoutConfig: AppLayoutConfigService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifyService: NotifyService,
    private dataStoryService: DataStoryService
  ) {
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((user: string | null) =>
        user ? this._filter(user) : this.allUsers.map((x) => x.email)
      )
    );
  }

  initForm() {
    if (this.data.dataStory.id && this.data.dataStory.id > 0) {
      this.storyForm = this.fb_storyForm.group({
        userIds: [[]],
      });
    } else {
      this.storyForm = this.fb_storyForm.group({
        title: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(50),
          ],
        ],
        userIds: [[]],
        expiryDate: ["", [Validators.required]],
        description: [""],
      });
    }
  }

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  filteredUsers: Observable<string[]>;
  users: string[] = [];
  allUsers: UserModel[] = [];

  @ViewChild("userInput", { static: false }) userInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      if (this.allUsers.map((x) => x.email).indexOf(value.trim()) >= 0) {
        if (this.users.indexOf(value) < 0) {
          this.users.push(value.trim());
        }
      }
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.userCtrl.setValue(null);
  }

  remove(user: string): void {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users = this.users.filter(x => x != user);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.users.indexOf(event.option.value) < 0) {
      this.users.push(event.option.viewValue);
      this.userInput.nativeElement.value = "";
      this.userCtrl.setValue(null);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUsers
      .map((x) => x.email)
      .filter((user) => user.toLowerCase().indexOf(filterValue) === 0);
  }


  async ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() + 7);
    this.initForm();
    if (this.data.dataStory.id && this.data.dataStory.id > 0) {
      this.dataStoryService
        .getDataStory$(this.data.dataStory.id)
        .pipe(take(1), takeUntil(this.unsubscribe$$))
        .subscribe(
          async (x) => {
            if (this.data.audience) {
              await this.userService
                .getAssociatedUsers$(x.pageId)
                .pipe(take(1), takeUntil(this.unsubscribe$$))
                .subscribe(
                  (res) => {
                    this.allUsers = res;
                    console.log(this.allUsers);
                  },
                  (err) => {
                    if (err) {
                      this.notifyService.error({
                        crud: UserAction.Crud.get,
                        object: systemObject.user,
                      });
                    }
                  }
                );
            }
          },
          (err) => {
            if (err) {
              this.notifyService.error({
                crud: UserAction.Crud.get,
                object: systemObject.dataStory,
              });
            }
          }
        );
    } else {
      await this.userService
        .getAssociatedUsers$(this.data.dataStory.pageId)
        .pipe(take(1), takeUntil(this.unsubscribe$$))
        .subscribe(
          (res) => {
            this.allUsers = res;
            console.log(this.allUsers);
          },
          (err) => {
            if (err) {
              this.notifyService.error({
                crud: UserAction.Crud.get,
                object: systemObject.user,
              });
            }
          }
        );
    }
  }

  onSubmit() {
    this.loading = true;
    if (this.storyForm.invalid) {
      this.storyForm.value.markAllAsTouched();
      this.loading = false;
      return;
    }
    if (this.data.dataStory.pageId && this.data.dataStory.pageId > 0) {
      Object.assign(this.data.dataStory, this.storyForm.value);
      if (this.users?.length == 0) {
        this.data.dataStory.userIds = [];
      } else {
        this.users.forEach((u) => {
                  const user = this.allUsers.find((x) => x.email == u);
                  this.data.dataStory.userIds.push(user.id);
        });
      }

      if (!this.data?.dataStory?.teamIds?.length || this.data?.dataStory?.teamIds == "") {
        this.data.dataStory.teamIds = [];
      }
      this.dataStoryService
        .createDataStory$(this.data.dataStory)
        .toPromise()
        .then((x) => {
          this.loading = false;
          this.layoutConfig.reloadHorizontalMenu();
          this.dialogRef.close(this.data.dataStory.teamIds.lenght || this.data.dataStory.userIds ? UserAction.Crud.CreateAndShare : UserAction.Crud.create);
        })
        .catch((err) => {
          this.loading = false;
          if (err) {
            this.notifyService.error({
              crud: UserAction.Crud.create,
              object: systemObject.dataStory,
            });
          }
        });
    }
    if (this.data.dataStory.id && this.data.dataStory.id > 0) {
      const id = this.data.dataStory;
      Object.assign(this.data.dataStory, this.storyForm.value);
      this.data.dataStory = id;
      if (this.users) {
        this.users.forEach((u) => {
          const user = this.allUsers.find((x) => x.email == u);
          this.data.dataStory.userIds.push(user.id);
        });
      }
      if (!this.users) {
        this.data.dataStory.userIds = [];
      }
      if (!this.data.dataStory.teamIds || this.data.dataStory.teamIds == "") {
        this.data.dataStory.teamIds = [];
      }
      this.dataStoryService
        .shareDataStory$(this.data.dataStory)
        .toPromise()
        .then((x) => {
          this.loading = false;
          this.dialogRef.close(x);
        })
        .catch((err) => {
          this.loading = false;
          if (err) {
            this.notifyService.error({
              crud: UserAction.Crud.share,
              object: systemObject.dataStory,
            });
          }
        });
    }
  }
  cancel() {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this.unsubscribe$$.next();
  }
}
