import { Injectable } from "@angular/core";
import { Observable, of, from } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../../base.service";
import { SearchRestModel } from "../model/search-rest.model";

@Injectable({ providedIn: "root" })
export class SearchRestService extends BaseService {
  routUrl = "";

  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + "/search";
  }

  search$(search: string): Observable<SearchRestModel[]> {
    let url_ = this.routUrl + "?";
    if (search !== undefined)
      url_ += "search=" + encodeURIComponent("" + search);
    return this.http.get<SearchRestModel[]>(url_);
  }
}
