import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { PageModel } from "../model/page.model";
import { map } from "rxjs/operators";
import { PageServerModel } from "@app/core/services/page-service/models/page.server.model";
import { PowerBIPageRestService } from "@app/core/services/page-service/services/power-bi-page.rest.service";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";

@Injectable()
export class PowerBIPageService {
  constructor(private service: PowerBIPageRestService) {}

  getPages$(reportId: number, status?: boolean): Observable<PageModel[]> {
    return this.service.getPages$(reportId, status).pipe(
      map((res: PageServerModel[]) => {
        return res.map((x) => new PageModel(x));
      })
    );
  }
  getRecentPages$(): Observable<PageModel[]> {
    return this.service.getRecentPages$().pipe(
      map((res: PageServerModel[]) => {
        return res.map((x) => new PageModel(x));
      })
    );
  }
  getFrequentPages$(): Observable<PageModel[]> {
    return this.service.getFrequentPages$().pipe(
      map((res: PageServerModel[]) => {
        return res.map((x) => new PageModel(x));
      })
    );
  }
  getFavoritePages$(): Observable<PageModel[]> {
    return this.service.getFavoritePages$().pipe(
      map((res: PageServerModel[]) => {
        return res.map((x) => new PageModel(x));
      })
    );
  }
  getPage$(id: number): Observable<PageModel> {
    return this.service
      .getPage$(id)
      .pipe(map((res: PageServerModel) => new PageModel(res)));
  }
  updatePage$(page: PageModel): Observable<PageModel> {
    return this.service
      .updatePage$(page)
      .pipe(map((res: PageServerModel) => new PageModel(res)));
  }
  updatePageOrder$(page: PageModel): Observable<PageModel> {
    return this.service
      .updatePageOrder$(page)
      .pipe(map((res: PageServerModel) => new PageModel(res)));
  }
  remove$(page: PageModel): Observable<boolean> {
    return this.service.remove$(page);
  }
  syncPage$(id: number): Observable<boolean> {
    return this.service.syncPage$(id);
  }
  addFavourite$(id: number): Observable<boolean> {
    return this.service.addFavourite$(id);
  }

  removeFavourite$(id: number): Observable<boolean> {
    return this.service.removeFavourite$(id);
  }
  getPageEmbedConfig$(pageId: number): Observable<PowerBIEmbedConfig> {
    return this.service.getPageEmbedConfig$(pageId);
  }
}
