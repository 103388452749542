<div class="d-flex justify-content-between report-pageNames">

   <div class="btn-group"
        role="group"
        aria-label="Button group with nested dropdown">
      <button type="button"
              class="btn btn-secondary b-radius-0"
              [ngClass]="{'border-bottom-blue':true}"> {{page.displayName}}</button>
   </div>



   <div class="text-right col-4 reportActions-container">

      <a class="btn btn-icon"
         (click)="fullScreen()"
         href="javascript:;">
         <i class="la la-expand text-dark"
            title="Full Screen"></i>
      </a>
      <a class="btn btn-icon"
         (click)="refreshDataset()"
         href="javascript:;">
         <i class="la la-refresh text-dark"
            title="Refresh"></i>
      </a>
      <a class="btn btn-icon"
         (click)="refreshReport()"
         href="javascript:;">
         <i class="la la-recycle text-dark"
            title="Reset to Default"></i>
      </a>
      <a class="btn btn-icon"
         (click)="printReport()"
         href="javascript:;">
         <i class="la la-print text-dark"
            title="Print"></i>
      </a>
      <a *ngIf="embedConfig?.settings?.visualSettings?.visualHeaders"
         class="btn btn-icon"
         (click)="removeVisualHeader()"
         href="javascript:;">
         <i class="fa fa-filter"
            [ngClass]="{'text-secondary':visualHeaderVisible===false,'text-primary':visualHeaderVisible===true}"
            [title]="visualHeaderVisible?'Hide Visual Header':'Show Visual Header'"></i>
      </a>
      <a class="btn btn-icon "
         (click)="addRemoveFavourite()"
         href="javascript:;">
         <i class="la la-star"
            [ngClass]="{'text-secondary':isFavourite===false,'text-warning':isFavourite===true}"
            title="Favourite"></i>
      </a>
      <a class="btn btn-icon "
         (click)="captureBookmark()"
         href="javascript:;">
         <i class="la la-camera text-dark"
            title="Capture Data Story"></i>
      </a>
      <a class="btn btn-icon "
         (click)="pinToDashbaord()"
         href="javascript:;">
         <i class="la la-thumb-tack text-dark"
            title="Pin to Dashboard"></i>
      </a>
   </div>


</div>
<div class="reports-container">
   <qx-pbi-embedded [page]="page"
                    [embeddedConfig]='embedConfig'></qx-pbi-embedded>
</div>