import { CompanyServerModel } from "@app/core/services/company-service/model/company.server.model";

export class CompanyModel {
  id?: number | undefined;
  statusId?: number | undefined;
  timeZone?: string | undefined;
  name?: string | undefined;
  address?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  country?: string | undefined;
  webUrl?: string | undefined;
  supportNumber?: string | undefined;
  faxNumber?: string | undefined;
  email?: string | undefined;
  zipCode?: number | undefined;
  otherInfo?: string | undefined;
  logo?: string | undefined;
  domainName?: string | undefined;
  isAutoSyncEnabled?: boolean | undefined;
  constructor(comp?: CompanyServerModel) {
    if (comp) {
      this.id = comp.id;
      this.logo = comp.logo;
      this.name = comp.name;
      this.otherInfo = comp.otherInfo;
      this.state = comp.state;
      this.statusId = comp.statusId;
      this.supportNumber = comp.supportNumber;
      this.timeZone = comp.timeZone;
      this.webUrl = comp.webUrl;
      this.zipCode = comp.zipCode;
      this.address = comp.address;
      this.isAutoSyncEnabled = comp.isAutoSyncEnabled;
      this.city = comp.city;
      this.country = comp.country;
      this.domainName = comp.domainName;
      this.email = comp.email;
      this.faxNumber = comp.faxNumber;
    }
  }
}
