import { ModuleWithProviders, NgModule } from "@angular/core";
import { AssetsPipe } from "../pipes/assets.pipe";
import { ImagePipe } from "../pipes/image.pipe";
import { StatusPipe } from "../pipes/status.pipe";
import { SignInMethodSvgPipe } from "../pipes/signInMethod-svg.pipe";
import { TimeAgoPipe } from "../pipes/timeAgo.pipe";

@NgModule({
  imports: [],
  declarations: [
    AssetsPipe,
    ImagePipe,
    StatusPipe,
    SignInMethodSvgPipe,
    TimeAgoPipe
  ],
  exports: [AssetsPipe, ImagePipe, StatusPipe, SignInMethodSvgPipe, TimeAgoPipe]
})
export class PipeModule {
  static forRoot(): ModuleWithProviders<PipeModule> {
    return {
        ngModule: PipeModule,
        providers: []
    };
}
}
