import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { models } from "powerbi-client";
import { DashboardVisualModel } from "../model/dashboard-visual.model";
import { DashboardVisualServerModel } from "@app/core/services/pbi-dashboar-visual-service/model/dashboard-visual.model";
import { PowerBIDashboarVisualRestService } from "@app/core/services/pbi-dashboar-visual-service/service/pbi-dashboar-visual.rest.service";

@Injectable()
export class PowerBIDashboardVisualService {
  constructor(private service: PowerBIDashboarVisualRestService) {}
  getAllDashboardVisuals$(): Observable<DashboardVisualModel[]> {
    return this.service.getAllDashboardVisuals$().pipe(
      map((res: DashboardVisualServerModel[]) => {
        return res.map(x => new DashboardVisualModel(x));
      })
    );
  }
  getUserDashboardVisuals$(): Observable<DashboardVisualModel[]> {
    return this.service.getUserDashboardVisuals$().pipe(
      map((res: DashboardVisualServerModel[]) => {
        return res.map(x => new DashboardVisualModel(x));
      })
    );
  }
  getDashboardVisual$(id: number): Observable<DashboardVisualModel> {
    return this.service
      .getDashboardVisual$(id)
      .pipe(
        map((res: DashboardVisualServerModel) => new DashboardVisualModel(res))
      );
  }
  addDashboardVisual$(
    dashboardVisual: DashboardVisualModel
  ): Observable<DashboardVisualModel> {
    return this.service.addDashboardVisual$(dashboardVisual);
  }
  removeDashboardVisual$(
    dashboardVisual: DashboardVisualModel
  ): Observable<boolean> {
    return this.service.removeDashboardVisual$(dashboardVisual);
  }
  updateDashboardVisuals$(
    dashboardVisuals: DashboardVisualModel[]
  ): Observable<boolean> {
    return this.service.updateDashboardVisuals$(dashboardVisuals);
  }
  getDashboardVisualEmbedConfig$(
    dashboardVisualId: number
  ): Observable<PowerBIEmbedConfig> {
    return this.service.getDashboardVisualEmbedConfig$(dashboardVisualId);
  }
}
