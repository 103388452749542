import { take, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'qx-custom-error',
  templateUrl: './custom-error.component.html',
  styleUrls: ['./custom-error.component.scss']
})
export class CustomErrorComponent implements OnInit, OnDestroy {
  error: string = "";
  unsubscribe$$ = new Subject<void>();
  constructor(private route: ActivatedRoute) { }
  ngOnDestroy(): void {
    this.unsubscribe$$.next();
  }

  ngOnInit() {
    this.route.params.pipe(take(1), takeUntil(this.unsubscribe$$)).subscribe(params => {
      this.error = params['error'];
    });
    console.log(this.error);
  }

}
