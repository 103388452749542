import { IconServerModel } from "@app/core/services/icon-service/model/icon.server.model";

export class IconModel {
  id?: number | undefined;
  name?: string | undefined;
  description?: string | undefined;
  tags?: string[] | undefined;
  url?: string | undefined;

  constructor(icon?: IconServerModel) {
    if (icon) {
      this.id = icon.id;
      this.name = icon.name;
      this.description = icon.description;
      this.tags = icon.tags;
      this.url = icon.url;
    }
  }
}
