import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TeamModel } from "../model/team.model";
import { TeamRestService } from "@app/core/services/team-service/service/team.rest.service";
import { TeamServerModel } from "@app/core/services/team-service/model/team.server.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable()
export class TeamService {
  count: number;
  constructor(private service: TeamRestService) {}

  getTeams$(
    page?: number,
    pageSize?: number,
    query?: string,
    getTotal?: boolean
  ): Observable<TeamModel[]> {
    return this.service.getTeams$(page, pageSize, query, getTotal).pipe(
      map((res: PageResultModel<TeamServerModel>) => {
        this.count = res.total;
        return res.result.map((x) => new TeamModel(x));
      })
    );
  }
  getTeamsByPageId$(pageId: number): Observable<TeamModel[]> {
    return this.service.getTeamsByPageId$(pageId).pipe(
      map((res: TeamServerModel[]) => {
        return res.map((x) => new TeamModel(x));
      })
    );
  }
  getTeamsCount$(): Observable<number> {
    return this.service.getTeamsCount$();
  }
  createTeam$(team: TeamModel): Observable<TeamModel> {
    return this.service
      .createTeam$(team)
      .pipe(map((res) => new TeamModel(res)));
  }
  updateTeam$(team: TeamModel): Observable<TeamModel> {
    return this.service
      .updateTeam$(team)
      .pipe(map((res) => new TeamModel(res)));
  }
  updateTeamPages$(id: number, pageIds: number[]): Observable<TeamModel> {
    return this.service.updateTeamPages$(id, pageIds);
  }
  updateTeamUsers$(id: number, userIds: number[]): Observable<TeamModel> {
    return this.service.updateTeamUsers$(id, userIds);
  }
  updateTeamReportServerReports$(
    id: number,
    premReportIds: number[]
  ): Observable<TeamModel> {
    return this.service.updateTeamPremReports$(id, premReportIds);
  }
  updateTeamDashboards$(
    id: number,
    dashboardIds: number[]
  ): Observable<TeamModel> {
    return this.service.updateTeamDashboards$(id, dashboardIds);
  }
}
