// Angular
import { Component, OnInit } from "@angular/core";

import { NotificationService } from "./service/notification.service";
import {
  NotificationModel,
  OperationStatus,
  SyncNotificationModel,
} from "./model/notification.model";
import { OffcanvasOptions } from "@theme/metronic/app/core/_base/layout";
import { LogModel } from "./model/logs.model";
// Layout

@Component({
  selector: "kt-quick-panel",
  templateUrl: "./quick-panel.component.html",
  styleUrls: ["./quick-panel.component.scss"],
})
export class QuickPanelComponent implements OnInit {
  notificationsIds: number[] = [];
  logs: LogModel[] = [];
  page: number = 0;
  pageSize = 10;
  // Public properties
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: "kt-quick-panel",
    closeBy: "kt_quick_panel_close_btn",
    toggleBy: "kt_quick_panel_toggler_btn",
  };
  constructor(public notificationService: NotificationService) {}
  async ngOnInit() {
    await this.notificationService
      .getNotification$(this.page, this.pageSize)
      .toPromise();
  }
  isCompleted(model: SyncNotificationModel) {
    return (
      model.message.status.toLowerCase() ===
      OperationStatus.Completed.toLowerCase()
    );
  }
  isFailed(model: SyncNotificationModel) {
    return (
      model.message.status.toLowerCase() ===
      OperationStatus.Failed.toLowerCase()
    );
  }
  inProgress(model: SyncNotificationModel) {
    return (
      model.message.status.toLowerCase() ===
      OperationStatus.InProgress.toLowerCase()
    );
  }
  clearAll(severity: number) {
    if (severity === 1) {
      this.notificationService
        .clearAll$(this.notificationService.notifications.map((x) => x.id))
        .toPromise()
        .then((x) => {
          if (x) {
            //   this.ngOnInit();
          }
        });
    } else {
      this.notificationService
        .clearAll$(this.notificationService.alerts.map((x) => x.id))
        .toPromise()
        .then((x) => {
          if (x) {
            //   this.ngOnInit();
          }
        });
    }
  }
  removeNotification(notification: NotificationModel) {
    if (notification.severity === 1) {
      this.notificationService
        .removeNotification$(notification.id)
        .toPromise()
        .then((x) => {});
    } else {
      this.notificationService
        .removeAlert$(notification.id)
        .toPromise()
        .then((x) => {});
    }
  }
  async getLogs() {
    const logs = await this.notificationService
      .getLogs$(this.page, this.pageSize, "")
      .toPromise();
    this.logs = logs.result;
  }
}
