import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Report } from "report";
import * as pbi from "powerbi-client";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { PBIEmbeddedComponent } from "../pbi-embedded/pbi-embedded.component";
import { NotifyService } from "@app/shared/services/notify.service";
import { PageModel } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/pages/model/page.model";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { PowerBIPageService } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/pages/service/power-bi-page.service";
import { PowerBIReportService } from "@app/power-bi/components/power-bi-details/workspace-details/reports/service/power-bi-report.service";
import { DashboardVisualModel } from "../model/dashboard-visual.model";
import { UserAction, systemObject } from "@app/shared/model/user-action.enum";
import { PowerBIDashboardVisualService } from "../service/pbi-dashboard-visual.service";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DataStoryModel } from "../model/data-story.model";
import { ShareDatastoryComponent } from "../share-data-story/share-data-story.component";
import { models } from "powerbi-client";

@Component({
  selector: "qx-render-page",
  templateUrl: "./render-page.component.html",
  styleUrls: ["./render-page.component.scss"],
})
export class RenderPageComponent implements OnInit, OnDestroy {
  isFavourite = false;
  reportToken = "";
  reportRendered = false;
  displayname = "";
  id: number;
  private sub: any;
  embedConfig: PowerBIEmbedConfig;
  page: PageModel = new PageModel();
  visualHeaderVisible = false;
  powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
    pbi.Report
  );
  @ViewChild(PBIEmbeddedComponent, { static: true })
  public embeddedReport: PBIEmbeddedComponent;
  private unsubscribe$$ = new Subject<void>();
  constructor(
    private visualService: PowerBIDashboardVisualService,
    private dialog: MatDialog,
    private notifyService: NotifyService,
    private router: ActivatedRoute,
    public pageService: PowerBIPageService
  ) {}

  ngOnInit() {
    this.router.paramMap.pipe(takeUntil(this.unsubscribe$$)).subscribe((x) => {
      this.id = +x.get("id");
      this.pageService
        .getPage$(this.id)
        .toPromise()
        .then((page) => {
          this.page = page;
        });
      this.renderPage();
    });
  }
  renderPage() {
    this.pageService
      .getPageEmbedConfig$(this.id)
      .toPromise()
      .then((config) => {
        this.isFavourite = config.isFavourite;
        if (this.reportToken === config.id) {
          this.page.token = config.pageName;
          this.ChangePage(this.page);
        } else {
          this.removeReport();
          this.embedConfig = config;
          this.reportToken = config.id;
          this.visualHeaderVisible = this.embedConfig.settings.visualSettings.visualHeaders[0].settings.visible;
        }
      });
  }

  pinToDashbaord() {
    const visual: DashboardVisualModel = {
      pageId: this.page.id,
      token: this.page.token,
      typeId: 1,
      height: 30,
      width: 30,
      left: 30,
      top: 30,
    };
    this.visualService
      .addDashboardVisual$(visual)
      .toPromise()
      .then((x) => {
        if (x) {
          this.notifyService.success({
            crud: UserAction.Crud.pin,
            object: systemObject.page,
          });
        } else {
          this.notifyService.error({
            crud: UserAction.Crud.pin,
            object: systemObject.page,
          });
        }
      })
      .catch((err) => {
        if (err) {
          this.notifyService.error({
            crud: UserAction.Crud.pin,
            object: systemObject.page,
          });
        }
      });
  }
  fullScreen() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.fullscreen();
  }
  removeReport() {
    this.powerbi.reset(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    );
  }
  printReport() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.print();
  }
  removeVisualHeader() {
    this.visualHeaderVisible = !this.visualHeaderVisible;
    const newSettings = {
      visualSettings: {
        visualHeaders: [
          {
            settings: {
              visible: this.visualHeaderVisible,
            },
            // No selector - Hide visual header for all the visuals in the report
          },
        ],
      },
    };
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report
      .updateSettings(newSettings)
      .then(() => {
        console.log(
          "Visual header was successfully hidden for all the visuals in the report."
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }
  ChangePage(page: PageModel) {
    const newSettings = {
      filterPaneEnabled: page.filterPane,
      navContentPaneEnabled: false,
      background: page.background === true ? 0 : 1,

      visualSettings: {
        visualHeaders: [
          {
            settings: {
              visible: page.visualHeader,
            },
            // No selector - Hide visual header for all the visuals in the report
          },
        ],
      },
    };
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report
      .updateSettings(newSettings)
      .then((x) => {
        report
          .setPage(page.token)
          .then((succes) => {})
          .catch((errors) => {});
      })
      .catch((errr) => {});
  }
  refreshReport() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.reload();
  }
  addRemoveFavourite() {
    if (this.isFavourite === true) {
      this.pageService
        .removeFavourite$(this.page.id)
        .toPromise()
        .then((x) => {
          this.isFavourite = !x;
        });
    } else {
      this.pageService
        .addFavourite$(this.page.id)
        .toPromise()
        .then((x) => {
          this.isFavourite = x;
        });
    }
  }
  captureBookmark() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.bookmarksManager.capture().then((bookmark) => {
      const bmk: DataStoryModel = {};
      bmk.state = bookmark.state;
      bmk.pageId = this.id;
      this.dialog
        .open(ShareDatastoryComponent, {
          data: { dataStory: { ...{}, ...bmk } },
        })
        .afterClosed()
        .toPromise()
        .then((result) => {
          if (result) {
            if (UserAction.Crud.CreateAndShare === result) {
              this.notifyService.success({
                crud: UserAction.Crud.CreateAndShare,
                object: systemObject.dataStory,
              });
            } else {
            this.notifyService.success({
              crud: UserAction.Crud.create,
              object: systemObject.dataStory,
            });
          }
          }
        });
    });
  }
  refreshDataset() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.refresh();
  }
  ngOnDestroy() {
    this.unsubscribe$$.next();
  }
}
