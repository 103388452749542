import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../../base.service";
import { Usage } from "@app/payment-management/components/suscriptions/model/usage.model";
import { Observable } from "rxjs";
import { UsageServerModel } from "../model/usage.server.model";
import { ProductServerModel } from "../../product-services/models/product.server.model";

@Injectable({
  providedIn: "root"
})
export class UsageRestService extends BaseService {
  routUrl = "";
  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl;
  }
  getLastUsageByApplication(
    applicationId: number
  ): Observable<UsageServerModel[]> {
    let url_ = this.routUrl + `/applications/${applicationId}/usage/last`;
    return this.http.get<UsageServerModel[]>(url_);
  }
  getSubscribedProducts$(): Observable<ProductServerModel[]> {
    let url_ = this.routUrl + `/products/subscribed`;
    return this.http.get<ProductServerModel[]>(url_);
  }
}
