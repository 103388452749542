import { PageServerModel } from "@app/core/services/page-service/models/page.server.model";
import { IconModel } from "@app/icons/model/icon.model";

export class PageModel {
  id?: number | undefined;
  reportId?: number | undefined;
  iconId?: number | undefined;
  name?: string | undefined;
  token?: string | undefined;
  displayName?: string | undefined;
  description?: string | undefined;
  tags?: string[] | undefined;
  status?: number | undefined;
  background?: boolean | undefined;
  filterPane?: boolean | undefined;
  visualHeader?: boolean | undefined;
  sortId?: number | undefined;
  isFavourite?: boolean | undefined;
  isRecent?: boolean | undefined;
  isFrequent?: boolean | undefined;
  icon?: IconModel | undefined;
  constructor(item?: PageServerModel) {
    if (item) {
      this.id = item.id;
      this.reportId = item.reportId;
      this.iconId = item.iconId;
      this.name = item.name;
      this.token = item.token;
      this.displayName = item.displayName;
      this.description = item.description;
      this.tags = item.tags;
      this.status = item.status;
      this.background = item.background;
      this.filterPane = item.filterPane;
      this.visualHeader = item.visualHeader;
      this.sortId = item.sortId;
      this.isFavourite = item.isFavourite;
      this.isRecent = item.isRecent;
      this.isFrequent = item.isFrequent;
      this.icon = new IconModel(item.icon);
    }
  }
}
