import { PowerBIServerProfileRestModel } from "@app/core/services/powerbi-server-service/model/power-bi-server-profile.model";

export class PowerBIServerProfile {
  id?: number | undefined;
  name: string;
  apiUrl: string;
  domain: string;
  userName: string;
  password: string;
  isCompanyProfile?: boolean | undefined;
  isDefaultProfile?: boolean | undefined;
  useProxy: boolean | undefined;

  constructor(profile?: PowerBIServerProfileRestModel) {
    if (profile) {
      this.id = profile.id;
      this.name = profile.name;
      this.domain = profile.domain;
      this.apiUrl = profile.apiUrl;
      this.userName = profile.userName;
      this.password = profile.password;
      this.isCompanyProfile = profile.isCompanyProfile;
      this.isDefaultProfile = profile.isDefaultProfile;
      this.useProxy = profile.useProxy;

    }
  }
}
