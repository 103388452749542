export const darkLayout = {
  demo: "demo1",
  // == Base Layout
  self: {
    layout: "fluid", // fluid|boxed
    body: {
      "background-image": "./assets/media/misc/bg-1.jpg",
    },
    logo: {
      dark: "./assets/media/logos/QuickAnalytix-lg.png",
      light: "./assets/media/logos/QuickAnalytix-lg.png",
      brand: "./assets/media/logos/QuickAnalytix-lg.png",
      green: "./assets/media/logos/QuickAnalytix-lg.png",
    },
  },
  // == Page Splash Screen loading
  loader: {
    enabled: true,
    type: "spinner-logo",
    logo: "./assets/media/logos/QuickAnalytix-lg.png",
    message: "Please wait...",
  },
  // == Colors for javascript
  colors: {
    state: {
      brand: "#5d78ff",
      dark: "#282a3c",
      light: "#ffffff",
      primary: "#5867dd",
      success: "#34bfa3",
      info: "#36a3f7",
      warning: "#ffb822",
      danger: "#fd3995",
    },
    base: {
      label: ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"],
      shape: ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"],
    },
  },
  header: {
    self: {
      skin: "dark",
      fixed: {
        desktop: true,
        mobile: true,
      },
    },
    menu: {
      self: {
        scroll: true,
        display: true,
        layout: "default",
        "root-arrow": false,
      },
      desktop: {
        arrow: true,
        toggle: "click",
        submenu: {
          skin: "dark",
          arrow: true,
        },
      },
      mobile: {
        submenu: {
          skin: "dark",
          accordion: true,
        },
      },
    },
  },
  subheader: {
    display: false,
    layout: "subheader-v1",
    fixed: true,
    width: "fluid",
    style: "solid",
  },
  content: {
    width: "fluid",
  },
  brand: {
    self: {
      skin: "dark",
      display: true,
    },
  },
  aside: {
    self: {
      skin: "dark",
      display: true,
      fixed: true,
      minimize: {
        toggle: true,
        default: false,
      },
    },
    menu: {
      dropdown: false,
      scroll: true,
      submenu: {
        accordion: true,
        dropdown: {
          arrow: true,
          "hover-timeout": 500,
        },
      },
    },
  },
  footer: {
    self: {
      display: false,
    },
  },
};
export const lightLayout = {
  demo: "demo1",
  // == Base Layout
  self: {
    layout: "fluid", // fluid|boxed
    body: {
      "background-image": "./assets/media/misc/bg-1.jpg",
    },
    logo: {
      dark: "./assets/media/logos/QuickAnalytix-lg.png",
      light: "./assets/media/logos/QuickAnalytix-lg.png",
      brand: "./assets/media/logos/QuickAnalytix-lg.png",
      green: "./assets/media/logos/QuickAnalytix-lg.png",
    },
  },
  // == Page Splash Screen loading
  loader: {
    enabled: true,
    type: "spinner-logo",
    logo: "./assets/media/logos/QuickAnalytix-lg.png",
    message: "Please wait...",
  },
  // == Colors for javascript
  colors: {
    state: {
      brand: "#5d78ff",
      dark: "#282a3c",
      light: "#ffffff",
      primary: "#5867dd",
      success: "#34bfa3",
      info: "#36a3f7",
      warning: "#ffb822",
      danger: "#fd3995",
    },
    base: {
      label: ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"],
      shape: ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"],
    },
  },
  header: {
    self: {
      skin: "light",
      fixed: {
        desktop: true,
        mobile: true,
      },
    },
    menu: {
      self: {
        scroll: true,
        display: true,
        layout: "default",
        "root-arrow": false,
      },
      desktop: {
        arrow: true,
        toggle: "click",
        submenu: {
          skin: "light",
          arrow: true,
        },
      },
      mobile: {
        submenu: {
          skin: "light",
          accordion: true,
        },
      },
    },
  },
  subheader: {
    display: false,
    layout: "subheader-v1",
    fixed: true,
    width: "fluid",
    style: "solid",
  },
  content: {
    width: "fluid",
  },
  brand: {
    self: {
      skin: "light",
    },
  },
  aside: {
    self: {
      skin: "light",
      display: false,
      fixed: true,
      minimize: {
        toggle: true,
        default: false,
      },
    },
    footer: {
      self: {
        display: true,
      },
    },
    menu: {
      dropdown: false,
      scroll: true,
      submenu: {
        accordion: true,
        dropdown: {
          arrow: true,
          "hover-timeout": 500,
        },
      },
    },
  },
};

export const ClientLayout = {
  demo: "demo1",
  // == Base Layout
  self: {
    layout: "fluid", // fluid|boxed
    body: {
      "background-image": "./assets/media/misc/bg-1.jpg",
    },
    logo: {
      dark: "./assets/media/logos/QuickAnalytix-lg.png",
      light: "./assets/media/logos/QuickAnalytix-lg.png",
      brand: "./assets/media/logos/QuickAnalytix-lg.png",
      green: "./assets/media/logos/QuickAnalytix-lg.png",
    },
  },
  // == Page Splash Screen loading
  loader: {
    enabled: true,
    type: "spinner-logo",
    logo: "./assets/media/logos/QuickAnalytix-lg.png",
    message: "Please wait...",
  },
  // == Colors for javascript
  colors: {
    state: {
      brand: "#5d78ff",
      dark: "#282a3c",
      light: "#ffffff",
      primary: "#5867dd",
      success: "#34bfa3",
      info: "#36a3f7",
      warning: "#ffb822",
      danger: "#fd3995",
    },
    base: {
      label: ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"],
      shape: ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"],
    },
  },
  header: {
    self: {
      skin: "dark",
      fixed: {
        desktop: true,
        mobile: true,
      },
    },
    menu: {
      self: {
        scroll: true,
        display: true,
        layout: "default",
        "root-arrow": false,
      },
      desktop: {
        arrow: true,
        toggle: "click",
        submenu: {
          skin: "dark",
          arrow: true,
        },
      },
      mobile: {
        submenu: {
          skin: "dark",
          accordion: true,
        },
      },
    },
  },
  subheader: {
    display: false,
    layout: "subheader-v1",
    fixed: true,
    width: "fluid",
    style: "solid",
  },
  content: {
    width: "fluid",
  },
  brand: {
    self: {
      skin: "dark",
      display: true,
    },
  },
  aside: {
    self: {
      skin: "dark",
      display: false,
      fixed: true,
      minimize: {
        toggle: true,
        default: false,
      },
    },
    menu: {
      dropdown: false,
      scroll: true,
      submenu: {
        accordion: true,
        dropdown: {
          arrow: true,
          "hover-timeout": 500,
        },
      },
    },
  },
  footer: {
    self: {
      display: false,
    },
  },
};
export const lightClientLayout = {
  demo: "demo1",
  // == Base Layout
  self: {
    layout: "fluid", // fluid|boxed
    body: {
      "background-image": "./assets/media/misc/bg-1.jpg",
    },
    logo: {
      dark: "./assets/media/logos/QuickAnalytix-lg.png",
      light: "./assets/media/logos/QuickAnalytix-lg.png",
      brand: "./assets/media/logos/QuickAnalytix-lg.png",
      green: "./assets/media/logos/QuickAnalytix-lg.png",
    },
  },
  // == Page Splash Screen loading
  loader: {
    enabled: true,
    type: "spinner-logo",
    logo: "./assets/media/logos/QuickAnalytix-lg.png",
    message: "Please wait...",
  },
  // == Colors for javascript
  colors: {
    state: {
      brand: "#5d78ff",
      dark: "#282a3c",
      light: "#ffffff",
      primary: "#5867dd",
      success: "#34bfa3",
      info: "#36a3f7",
      warning: "#ffb822",
      danger: "#fd3995",
    },
    base: {
      label: ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"],
      shape: ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"],
    },
  },
  header: {
    self: {
      skin: "light",
      fixed: {
        desktop: true,
        mobile: true,
      },
    },
    menu: {
      self: {
        scroll: true,
        display: true,
        layout: "default",
        "root-arrow": false,
      },
      desktop: {
        arrow: true,
        toggle: "click",
        submenu: {
          skin: "light",
          arrow: true,
        },
      },
      mobile: {
        submenu: {
          skin: "light",
          accordion: true,
        },
      },
    },
  },
  subheader: {
    display: false,
    layout: "subheader-v1",
    fixed: true,
    width: "fluid",
    style: "solid",
  },
  content: {
    width: "fluid",
  },
  brand: {
    self: {
      skin: "light",
      display: true,
    },
  },
  aside: {
    self: {
      skin: "light",
      display: false,
      fixed: true,
      minimize: {
        toggle: true,
        default: false,
      },
    },
    menu: {
      dropdown: false,
      scroll: true,
      submenu: {
        accordion: true,
        dropdown: {
          arrow: true,
          "hover-timeout": 500,
        },
      },
    },
  },
  footer: {
    self: {
      display: false,
    },
  },
};
