export const environment = {
  appVersion: "1.0.0",
  production: true,
  teams: false,
  iframeHostUrl: "https://report.doplaner.com",
  apiUrl: "https://api.quickanalytix.com",
  allowedUrls: [
    "http://api.quickanalytix.com",
    "https://api.quickanalytix.com",
  ],
  authUrl: "https://auth.quickanalytix.com",
  stripeKey: 'pk_live_51JidX3BbkyHxJErKR2xzTEBfWU5IP75V4xbTf3lx3ssMzKoFSXVtCA6P1gEgDPW1guvxr8u5qB5K3wSDANcIuTIv00nwyvSJ4A'
};
