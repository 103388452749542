import { CompanyStatusServerModel } from "@app/core/services/company-service/model/company-status.server.model";

export class CompanyStatusModel {
  id?: number | undefined;
  name?: string | undefined;
  constructor(item?: CompanyStatusServerModel) {
    if (item) {
      this.id = item.id;
      this.name = item.name;
    }
  }
}
