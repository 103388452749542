import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Report } from "report";
import * as pbi from "powerbi-client";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { BookmarkModel } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/bookmarks/model/bookmark.model";
import { PBIEmbeddedComponent } from "../pbi-embedded/pbi-embedded.component";
import { PowerBIBookmarkService } from "@app/power-bi/components/power-bi-details/workspace-details/reports/report-details/bookmarks/service/power-bi-bookmark.service";
import { NotifyService } from "@app/shared/services/notify.service";
import { UserAction, systemObject } from "@app/shared/model/user-action.enum";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "qx-render-bookmark",
  templateUrl: "./render-bookmark.component.html",
  styleUrls: ["./render-bookmark.component.scss"],
})
export class RenderBookmarkComponent implements OnInit, OnDestroy {
  bookmark: BookmarkModel = new BookmarkModel();
  id: number;
  private sub: any;
  embedConfig: PowerBIEmbedConfig = {};
  private unsubscribe$$ = new Subject<void>();
  powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
    pbi.Report
  );
  visualHeaderVisible = true;
  @ViewChild(PBIEmbeddedComponent, { static: true })
  public embeddedReport: PBIEmbeddedComponent;
  constructor(
    private dialog: MatDialog,
    private notifyService: NotifyService,
    private router: ActivatedRoute,
    public bookmarkService: PowerBIBookmarkService
  ) {}

  ngOnInit() {
    this.router.paramMap.pipe(takeUntil(this.unsubscribe$$)).subscribe((x) => {
      this.id = +x.get("id");
      this.bookmarkService
        .getBookmark$(this.id)
        .toPromise()
        .then((bookmark) => {
          if (bookmark) {
            this.bookmark = bookmark;
          }
        })
        .catch((err) => {
          if (err) {
            this.notifyService.error({
              crud: UserAction.Crud.get,
              object: systemObject.bookmark,
            });
          }
        });
    });
    this.bookmarkService
      .getBookmarkEmbedConfig$(this.id)
      .toPromise()
      .then((config) => {
        this.embedConfig = config;
      })
      .catch((err) => {
        if (err) {
          this.notifyService.error({
            crud: UserAction.Crud.get,
            object: systemObject.bookmark,
          });
        }
      });
  }
  fullScreen() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.fullscreen();
  }
  printReport() {
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.print();
  }
  removeVisualHeader() {

    this.visualHeaderVisible = !this.visualHeaderVisible;

    const newSettings = {
      visualSettings: {
        visualHeaders: [
          {
            settings: {
              visible: this.visualHeaderVisible,
            },
          },
        ],
      },
    };
    const report: Report = this.powerbi.get(
      this.embeddedReport.el.nativeElement.querySelector("#reportsContainer")
    ) as Report;
    report.updateSettings(newSettings);
  }
  ngOnDestroy() {
    this.unsubscribe$$.next();
  }
}
