import { FeatureServerModel } from "@core/services/application-services/model/feature.server.model";

export class Feature {
  applicationId?: number | undefined;
  id?: number | undefined;
  key: string;
  value?: string | undefined;
  dataType: string;
  showInSummery?: boolean | undefined;
  included?: boolean | undefined;
  /**
   *
   */
  constructor(feature?: FeatureServerModel) {
    if (feature) {
      this.applicationId = feature.applicationId;
      this.id = feature.id;
      this.key = feature.key;
      this.value = feature.value;
      this.dataType = feature.dataType;
      this.showInSummery = feature.showInSummery;
    }
  }
}
