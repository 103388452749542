<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
	<!--begin: Search -->
	<kt-search-dropdown [icon]="'./assets/media/icons/svg/General/Search.svg'"
						[useSVG]="true"></kt-search-dropdown>
	<!--end: Search -->
	<!--begin: Notifications -->
	<!-- <kt-notification [bgImage]="'./assets/media/misc/bg-1.jpg'"
					 [pulse]="true"
					 [skin]="'dark'"
					 [icon]="'./assets/media/icons/svg/Code/Compiling.svg'"
					 [useSVG]="true"></kt-notification> -->
	<!--end: Notifications -->
	<!--begin: Quick actions -->
	<!-- <kt-quick-action [bgImage]="'./assets/media/misc/bg-1.jpg'"
					 [pulse]="true"
					 [skin]="'dark'"
					 [icon]="'./assets/media/icons/svg/Code/Compiling.svg'"
					 [useSVG]="true"></kt-quick-action> -->
	<!--end: Quick actions -->
	<!--begin: My Cart -->
	<!-- <kt-cart [bgImage]="'./assets/media/misc/bg-1.jpg'"
			 [icon]="'./assets/media/icons/svg/Shopping/Cart%233.svg'"
			 [useSVG]="true"></kt-cart> -->
	<!--end: My Cart -->
	<!--begin: Quick panel toggler -->
	<div class="kt-header__topbar-item kt-header__topbar-item--quick-panel"
		 data-placement="bottom">
		<span *ngIf="service.alertsCount +
		service.notificationsCount +
		service.syncNotifications.length>0"
			  class="kt-badge kt-badge--danger">{{ service.alertsCount +
			service.notificationsCount +
			service.syncNotifications.length}}</span>
		<span class="kt-header__topbar-icon"
			  id="kt_quick_panel_toggler_btn">

			<span class="kt-svg-icon"
				  [ngClass]="{ 'kt-pulse--light' : true}"
				  [inlineSVG]="'./assets/media/icons/svg/General/Notifications1.svg'">

			</span>
			<span class="kt-pulse__ring"></span>
		</span>
		<mat-progress-bar mode="indeterminate"
						  *ngIf="showProgress()">
		</mat-progress-bar>
	</div>
	<!--end: Quick panel toggler -->
	<!--begin: Language bar -->
	<!-- <kt-language-selector></kt-language-selector> -->
	<!--end: Language bar -->
	<!--begin: User bar -->
	<kt-user-profile></kt-user-profile>
	<!--end: User bar -->
</div>
<!-- end:: Header Topbar -->