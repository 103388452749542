import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "./Auth-service/auth.service";
@Injectable({
  providedIn: "root"
})
export class BaseService {
  [x: string]: any;
  public baseUrl = "";
  public isDebug: boolean;
  constructor() {
    this.isDebug = !environment.production;
    this.baseUrl = environment.apiUrl;
  }
}
