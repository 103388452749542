<div class="d-flex justify-content-between report-pageNames">
   <div class="col-lg-9 col-sm-6">
      <div class="btn-group k--padding-left-none"
           role="group"
           aria-label="Button group with nested dropdown">
         <span *ngFor="let item of getSlice(pages,true)">
            <button type="button"
                    class="btn btn-secondary b-radius-0"
                    [ngClass]="{'border-bottom-blue':activePage?.id==item.id}"
                    (click)="ChangePage(item)"> {{item.displayName}}</button>
         </span>
         <div *ngIf="pages.length && pages.length>limit"
              ngbDropdown
              placement="bottom-right"
              class="dropdown">
            <div class="dropdown-toggle"
                 ngbDropdownToggle>
               <button class="btn btn-secondary b-radius-0"
                       [ngClass]="{'active':activeInMore()}"
                       type="button"
                       (click)="showMore=!showMore">More
                  <span
                        class="kt-badge  kt-badge--round kt-badge--inline kt-badge--pill kt-badge--primary ng-star-inserted">+{{getSlice(pages,false).length}}
                  </span>
               </button>
            </div>
            <div ngbDropdownMenu
                 class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl fit-content-width"
                 [ngClass]="{'show':showMore}">
               <span *ngFor="let item of getSlice(pages,false)">
                  <button class="dropdown-item m--padding-top-10 m--padding-bottom-10 btn-secondary"
                          [ngClass]="{'border-bottom-blue':activePage?.id==item.id,'bg-active-blue':activePage?.id==item.id}"
                          (click)="ChangePage(item)">{{item.displayName}}</button>
               </span>
            </div>
         </div>

      </div>
   </div>
   <div class="text-right reportActions-container">
      <div class="btn-group"
           role="group"
           aria-label="Button group with nested dropdown">

         <button class="btn btn-icon b-radius-0"
                 type="button"
                 (click)="fullScreen()">
            <i class="la la-expand text-dark"
               title="Full Screen"></i>
         </button>
         <button class="btn btn-icon b-radius-0"
                 type="button"
                 (click)="refreshDataset()">
            <i class="la la-refresh text-dark"
               title="Refresh Data"></i>
         </button>
         <button class="btn btn-icon b-radius-0"
                 type="button"
                 (click)="refreshReport()">
            <i class="la la-recycle text-dark"
               title="Reset to Default"></i>
         </button>
         <button class="btn btn-icon b-radius-0"
                 type="button"
                 (click)="printReport()">
            <i class="la la-print text-dark"
               title="Print"></i>
         </button>
         <button *ngIf="embedConfig?.settings?.visualSettings?.visualHeaders"
                 class="btn btn-icon b-radius-0"
                 type="button"
                 (click)="removeVisualHeader()">
            <i class="fa fa-filter"
               [ngClass]="{'text-icon':visualHeaderVisible===false,'text-primary':visualHeaderVisible===true}"
               [title]="visualHeaderVisible?'Hide Visual Header':'Show Visual Header'"></i>
         </button>
         <button class="btn btn-icon b-radius-0"
                 type="button"
                 (click)="addRemoveFavourite()">
            <i class="la la-star"
               [ngClass]="{'text-secondary':activePage?.isFavourite===false,'text-warning':activePage?.isFavourite===true}"
               title="Favourite"></i>
         </button>
         <button mat-button
                 class="btn btn-icon b-radius-0"
                 title="Capture Data Story"
                 [matMenuTriggerFor]="menu"><i class="la la-camera text-dark"></i></button>
         <mat-menu #menu="matMenu">
            <button mat-menu-item
                    (click)="captureBookmark(false)">Capture</button>
            <button mat-menu-item
                    (click)="captureBookmark(true)">Capture & Share</button>
         </mat-menu>

         <!-- <a class="btn btn-icon "
      (click)="captureBookmark()"
      href="javascript:;">
      <i class="la la-camera text-dark"
         title="Capture Data Story"></i>
   </a> -->
         <button class="btn btn-icon b-radius-0"
                 type="button"
                 (click)="pinToDashbaord()">
            <i class="la la-thumb-tack text-dark"
               title="Pin to Dashboard"></i>
         </button>


      </div>
   </div>


</div>
<div class="reports-container">
   <qx-pbi-embedded [page]="activePage"
                    [embeddedConfig]='embedConfig'></qx-pbi-embedded>
</div>