import { Feature } from "../../../applications/model/feature.model";
import { PackageServerModel } from "@core/services/product-services/models/package.server.model";

export class Package {
  id?: number | undefined;
  productId?: number | undefined;
  name: string;
  description?: string | undefined;
  isPublished?: boolean | undefined;
  isFree?: boolean | undefined;
  features?: Feature[] | undefined;
  amount: number;
  interval: string;
  intervalCount: number;
  trialPeriodDays?: number | undefined;
  usageType: string;
  isSubscribed?: boolean | undefined;
  constructor(item?: PackageServerModel) {
    if (item) {
      this.id = item.id;
      this.productId = item.productId;
      this.name = item.name;
      this.description = item.description;
      this.isPublished = item.isPublished;
      this.isFree = item.isFree;
      this.isSubscribed = item.isSubscribed;
      this.amount = item.amount;
      this.interval = item.interval;
      this.intervalCount = item.intervalCount;
      this.trialPeriodDays = item.trialPeriodDays;
      this.usageType = item.usageType;
      this.features =
        item.features && item.features.length > 0
          ? item.features.map(res => {
              return new Feature(res);
            })
          : [];
    }
  }
}
