import { SearchRestModel } from "@app/core/services/search-service/model/search-rest.model";

export class SearchModel {
  id?: number | undefined | null;
  name?: string | undefined | null;
  icon?: string | undefined | null;
  type?: string | undefined | null;
  url?: string | undefined | null;
  description?: string | undefined | null;
  constructor(item?: SearchRestModel) {
    if (item) {
      this.id = item.id;
      this.name = item.name;
      this.icon = item.icon;
      this.type = item.type;
      this.url = item.url;
      this.description = item.description;
    }
  }
}
