import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { PageResultModel } from "@app/core/models/page-result.server.model";
import { IconServerModel } from "../model/icon.server.model";

@Injectable({ providedIn: "root" })
export class IconRestService extends BaseService {
  routUrl = "";
  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + "/icons";
  }

  getIcons$(
    page: number,
    pageSize: number,
    query: string,
    getTotal: boolean
  ): Observable<PageResultModel<IconServerModel>> {
    let url_ = this.routUrl + "?";
    if (page !== undefined)
      url_ += "page=" + encodeURIComponent("" + page) + "&";

    if (pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";

    if (query !== undefined)
      url_ += "searchTerm=" + encodeURIComponent("" + query) + "&";

    url_ += "getTotal=" + encodeURIComponent("" + getTotal);
    return this.http.get<PageResultModel<IconServerModel>>(url_);
  }

  updateIcon$(icon: IconServerModel): Observable<IconServerModel> {
    return this.http.put<IconServerModel>(this.routUrl, icon);
  }
  createIcon$(file: File, icon: IconServerModel): Observable<IconServerModel> {
    const uploadData = new FormData();
    uploadData.append("image", file, file.name);
    uploadData.append("iconDto", JSON.stringify(icon));

    return this.http.post<IconServerModel>(this.routUrl, uploadData);
  }
  remove$(icon: IconServerModel): Observable<boolean> {
    const url_ = this.routUrl;
    return this.http.put<boolean>(url_, icon);
  }
}
