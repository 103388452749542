import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErrorPageComponent } from "@theme/metronic/app/views/theme/content/error-page/error-page.component";
import { BaseComponent } from "@theme/metronic/app/views/theme/base/base.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { PermissionGuard } from "./core/guards/permission.guard";
import { ScopeGuard } from "./core/guards/scope.guard";
import { CustomErrorComponent } from "./custom-error/custom-error.component";

const routes: Routes = [
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "roles",
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import("./roles-management/roles-management.module").then(
            (m) => m.RolesManagementModule
          ),
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./notification/notification.module").then(
            (m) => m.NotificationModule
          ),
      },
      {
        path: "profile",

        children: [
          {
            path: "",
            loadChildren: () =>
              import("./user-profile/user-profile.module").then(
                (m) => m.UserProfileModule
              ),
          },
        ],
      },
      {
        path: "companies",
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import("./company-management/company-management.module").then(
            (m) => m.CompanyManagementModule
          ),
      },
      {
        path: "users",
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import("./users-management/users-management.module").then(
            (m) => m.UsersManagementModule
          ),
      },
      {
        path: "powerBi",
        canActivate: [ScopeGuard],
        loadChildren: () =>
          import("./power-bi/power-bi.module").then((m) => m.PowerBIModule),
      },
      {
        path: "powerbi-server",
       canActivate: [ScopeGuard],
        loadChildren: () =>
          import("./powerbi-server/powerbi-server.module").then(
            (m) => m.PowerBIServerModule
          ),
      },
      {
        path: "render",
        loadChildren: () =>
          import("./power-bi-render/power-bi-render.module").then(
            (m) => m.PowerBIRenderModule
          ),
      },
      {
        path: "powerbi-server-render",
        loadChildren: () =>
          import("./powerbi-server-render/powerbi-server-render.module").then(
            (m) => m.PowerBIServerRenderModule
          ),
      },
      {
        path: "payments",
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import("./payment-management/payment-management.module").then(
            (m) => m.PaymentManagementModule
          ),
      },
      {
        path: "teams",
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import("./teams/teams.module").then((m) => m.TeamsModule),
      },
      {
        path: "icons",
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import("./icons/icons.module").then((m) => m.IconsModule),
      },

      {
        path: "error/403",
        component: ErrorPageComponent,
        data: {
          type: "error-v6",
          code: 403,
          title: "403... Access forbidden",
          desc:
            "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
        },
      },
      { path: "error/:type", component: ErrorPageComponent },
      // { path: "", redirectTo: "dashboard", pathMatch: "full" },
      // { path: "**", redirectTo: "dashboard", pathMatch: "full" }
    ],
  }, {
    path: "custom-error/:error",
    component: CustomErrorComponent,
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
