// Angular
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
// RxJS
import { Observable, of } from "rxjs";
import { AuthService } from "../services/Auth-service/auth.service";
@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const isAdmin = this.authService.isAdmin;

    if (isAdmin) {
      return of(true);
    }
    const url = state.url.split("/");
    let currentRoute = url[url.length - 1];
    if(currentRoute === 'dns-records')
    currentRoute = 'powerbionprem.manage';
    return of(
      this.authService.containsModulePermission(currentRoute.toLowerCase())
    );
  }
}
