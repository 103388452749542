import { ProductServerModel } from "@core/services/product-services/models/product.server.model";
import { Package } from "../packages/models/package.model";

export class Product {
  id?: number | undefined;
  name?: string | undefined;
  type?: string | undefined;
  description?: string | undefined;
  isPublicPreview?: boolean | undefined;
  applicationId?: number | undefined;
  packages?: Package[] | undefined;
  constructor(product?: ProductServerModel) {
    if (product) {
      this.id = product.id;
      this.applicationId = product.applicationId;
      this.name = product.name;
      this.type = product.type;
      this.description = product.description;
      this.isPublicPreview = product.isPublicPreview;
      this.packages =
        product.packages && product.packages.length > 0
          ? product.packages.map(res => {
              return new Package(res);
            })
          : [];
    }
  }
}
