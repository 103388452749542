import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { CompanyServerModel } from "../model/company.server.model";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../../base.service";
import { CompanyStatusServerModel } from "../model/company-status.server.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable({ providedIn: "root" })
export class CompanyRestService extends BaseService {
  routUrl = "";
  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + "/companies";
  }

  getCompanies$(
    pageNumber?: number,
    pageSize?: number,
    query?: string,
    getTotal?: boolean
  ): Observable<PageResultModel<CompanyServerModel>> {
    let url_ = this.routUrl + "?";

    if (pageNumber !== undefined)
      url_ += "page=" + encodeURIComponent("" + pageNumber) + "&";
    if (pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";
    if (query !== undefined)
      url_ += "name=" + encodeURIComponent("" + query) + "&";
    if (getTotal !== undefined)
      url_ += "getTotal=" + encodeURIComponent("" + getTotal);

    return this.http.get<PageResultModel<CompanyServerModel>>(url_);
  }
  getCompany$(companyId: number): Observable<CompanyServerModel> {
    let url_ = this.routUrl + `/${companyId}`;
    return this.http.get<CompanyServerModel>(url_);
  }
  getCompanyStatuses$(): Observable<CompanyStatusServerModel[]> {
    let url_ = this.routUrl + `/statuses`;
    return this.http.get<CompanyStatusServerModel[]>(url_);
  }
  getCurrentCompanyDetail$(): Observable<CompanyServerModel> {
    let url_ = this.routUrl + `/my`;
    return this.http.get<CompanyServerModel>(url_);
  }
  createCompany$(company: CompanyServerModel): Observable<CompanyServerModel> {
    return this.http.post<CompanyServerModel>(this.routUrl, company);
  }
  uploadLogo$(file: File): Observable<CompanyServerModel> {
    let url_ = this.routUrl + `/logo`;
    const uploadData = new FormData();
    uploadData.append("image", file, file.name);
    return this.http.post<CompanyServerModel>(url_, uploadData);
  }
  updateCompany$(company: CompanyServerModel): Observable<CompanyServerModel> {
    return this.http.put<CompanyServerModel>(this.routUrl, company);
  }
}
