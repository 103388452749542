<!-- begin::Offcanvas Toolbar Quick Actions -->
<div ktOffcanvas
	 [options]="offcanvasOptions"
	 id="kt_quick_panel"
	 class="kt-quick-panel"
	 [ngStyle]="{'overflow': 'hidden'}">
	<a href="javascript:;"
	   class="kt-quick-panel__close"
	   id="kt_quick_panel_close_btn"><i class="flaticon2-delete"></i></a>

	<div class="kt-quick-panel__nav">


		<ul ktTabClickEvent
			ngbNav
			#nav="ngbNav"
			class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x"
			role="tablist">
			<li ngbNavItem="notification"
				class="nav-item">
				<a ngbNavLink
				   class="nav-link active"
				   href="javascript:;"
				   (click)="nav.select('notification');"
				   role="tab">Events
					<span *ngIf="notificationService.notificationsCount>0"
						  class="kt-badge kt-badge--brand kt-badge--wide">{{notificationService.notificationsCount}}</span></a>
				<ng-template ngbNavContent>
					<div [ngStyle]="{'max-height': '85vh', 'position': 'relative'}"
						 class="kt-notification">
						<p class="actions text-right"><a href="javascript:;"
							   routerLink="/notifications"
							   class="kt-link">See All</a>
							<a href="javascript:;"
							   [ngClass]="{'text-secondary': notificationService.notificationsCount ===0}"
							   (click)="notificationService.notifications.length>0 && clearAll(1)"
							   class="kt-link">Clear All</a>
						</p>
						<div class=""
							 *ngIf="notificationService.syncNotifications.length>0">
							<a href="javascript:;"
							   *ngFor="let item of notificationService.syncNotifications"
							   class="kt-notification__item"
							   [ngClass]="{'border-red':isFailed(item),'border-green':isCompleted(item),'border-blue':inProgress(item)}">
								<div class="kt-notification__item-icon">

									<i *ngIf="isCompleted(item)"
									   class="fa fa-check-circle kt-font-success"></i>
									<i *ngIf="isFailed(item)"
									   class="fa fa-times-circle kt-font-danger"></i>
									<i class="kt-spinner kt-spinner--right kt-spinner--md kt-spinner--primary notification-spinner"
									   *ngIf="inProgress(item)"></i>

								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title=">
										<h5 class="kt-font-primary">
											{{item.message.object | titlecase}} {{item.type | titlecase }} -
											{{item.message.status | titlecase}}</h5>
										{{item.message.information}}
									</div>
									<div class="kt-notification__item-time">
										{{item.createdOn | kTimeElapsed}}
									</div>
								</div>
								<i class="la la-times kt-font-danger"
								   *ngIf="!inProgress(item)"
								   (click)="notificationService.removeSyncNotification(item)"></i>


							</a>
						</div>


						<a href="javascript:;"
						   *ngFor="let item of notificationService.notifications"
						   class="kt-notification__item">
							<div class="kt-notification__item-icon">
								<i class="fa fa-exclamation kt-font-info"></i>
							</div>
							<div class="kt-notification__item-details">
								<div class="kt-notification__item-title=">
									{{item.data}}
								</div>
								<div class="kt-notification__item-time">
									{{item.createdOn | kTimeElapsed}}
								</div>
							</div>

							<i class="la la-times kt-font-danger"
							   (click)="removeNotification(item)"></i>

						</a>

					</div>
				</ng-template>
			</li>

			<li ngbNavItem
				class="nav-item">
				<a ngbNavLink="logs"
				   class="nav-link"
				   href="javascript:;"
				   (click)="[nav.select('logs'),getLogs()]"
				   role="tab">Logs</a>
				<ng-template ngbNavContent>
					<div [ngStyle]="{'max-height': '85vh', 'position': 'relative'}"
						 class="kt-notification">
						<a href="javascript:;"
						   *ngFor="let item of logs"
						   class="kt-notification__item">
							<div class="kt-notification__item-icon">
								<i class="fa flaticon-web kt-font-primary"></i>
							</div>
							<div class="kt-notification__item-details">
								<div class="kt-notification__item-title=">
									{{item.message}}
								</div>
								<div class="kt-notification__item-time">
									{{item.createdOn | kTimeElapsed}}
								</div>
							</div>

						</a>

					</div>
				</ng-template>
			</li>
		</ul>
	</div>

	<div class="kt-quick-panel__content">
		<div [ngbNavOutlet]="nav"></div>

	</div>
</div>
<!-- end::Offcanvas Toolbar Quick Actions -->