<div class="bg-white">
     <div class="pulse-container"
          *ngIf="loading">
          <img [attr.src]="'./assets/media/loader/Q-Loader.gif'"
               alt="Logo"
               class="report-logo">
          <!-- <div class="dot-pulse"></div> -->
     </div>
     <div id="reportsContainer"
          [ngClass]="{'hide-visible':loading}"
          #cont></div>
</div>