import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { PageServerModel } from "../models/page.server.model";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class PowerBIPageRestService extends BaseService {
  routUrl = "";
  constructor(private http: HttpClient) {
    super();
  }

  getPages$(reportId: number, status?: boolean): Observable<PageServerModel[]> {
    this.routUrl =
      this.baseUrl + `/application/powerbi/reports/${reportId}/pages`;
    let url_ = this.routUrl;
    if (status) {
      url_ = url_ + "?";
      url_ += "status=" + encodeURIComponent("" + status);
    }
    return this.http.get<PageServerModel[]>(url_);
  }
  getRecentPages$(): Observable<PageServerModel[]> {
    this.routUrl = this.baseUrl + `/pages/recent/my`;
    return this.http.get<PageServerModel[]>(this.routUrl);
  }
  getFrequentPages$(): Observable<PageServerModel[]> {
    this.routUrl = this.baseUrl + `/pages/frequent/my`;
    return this.http.get<PageServerModel[]>(this.routUrl);
  }
  getFavoritePages$(): Observable<PageServerModel[]> {
    this.routUrl = this.baseUrl + `/pages/favourite/my`;
    return this.http.get<PageServerModel[]>(this.routUrl);
  }
  getUserPages$(reportId: number): Observable<PageServerModel[]> {
    this.routUrl = this.baseUrl + `/reports/${reportId}/pages`;
    let url_ = this.routUrl;
    if (status) {
      url_ = url_ + "?";
      url_ += "status=" + encodeURIComponent("" + true);
    }
    return this.http.get<PageServerModel[]>(url_);
  }
  getPage$(id: number): Observable<PageServerModel> {
    this.routUrl = this.baseUrl + `/pages/${id}`;
    return this.http.get<PageServerModel>(this.routUrl);
  }
  updatePage$(page: PageServerModel): Observable<PageServerModel> {
    this.routUrl = this.baseUrl + `/pages`;
    return this.http.put<PageServerModel>(this.routUrl, page);
  }
  updatePageOrder$(page: PageServerModel): Observable<PageServerModel> {
    this.routUrl = this.baseUrl + `/pages/order`;
    return this.http.put<PageServerModel>(this.routUrl, page);
  }
  remove$(page: PageServerModel): Observable<boolean> {
    this.routUrl = this.baseUrl + `/pages/remove`;
    return this.http.put<boolean>(this.routUrl, page);
  }
  syncPage$(id: number): Observable<boolean> {
    this.routUrl = this.baseUrl + `/pages/${id}/sync`;
    return this.http.get<boolean>(this.routUrl);
  }

  addFavourite$(id: number): Observable<boolean> {
    this.routUrl = this.baseUrl + `/pages/${id}/favourite`;
    return this.http
      .post<any>(this.routUrl, id)
      .pipe(map((x) => (x ? true : false)));
  }
  removeFavourite$(id: number): Observable<boolean> {
    this.routUrl = this.baseUrl + `/pages/${id}/unfavourite`;
    return this.http.delete<boolean>(this.routUrl).pipe(map(() => true));
  }
  getPageEmbedConfig$(pageId: number): Observable<PowerBIEmbedConfig> {
    this.routUrl =
      this.baseUrl + `/application/powerbi/pages/${pageId}/embed-config`;
    return this.http.get<PowerBIEmbedConfig>(this.routUrl);
  }
}
