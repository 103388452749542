import { Injectable } from "@angular/core";
import { FileRestService } from "@app/core/services/file-service/service/file.rest.service";
import { FileServerModel } from "@app/core/services/file-service/model/file.server.model";
import { Observable } from "rxjs";
import { CompanyServerModel } from "@app/core/services/company-service/model/company.server.model";

@Injectable()
export class FileService {
  constructor(private service: FileRestService) {}
  getFiles(): Observable<FileServerModel[]> {
    return this.service.getFiles();
  }
  getFilesByApplication(applicationId: number): Observable<FileServerModel[]> {
    return this.service.getFilesByApplication(applicationId);
  }
  uploadLogo$(file: File): Observable<CompanyServerModel> {
    return this.service.uploadLogo$(file);
  }
  deleteFile$(url: string): Observable<FileServerModel> {
    return this.service.deleteFile$(url);
  }
}
