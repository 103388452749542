// Angular
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SearchService } from "./service/search-service";
import { systemObject } from "@app/shared/model/user-action.enum";
import {
  ReportIcon,
  DashboardIcon,
  PageIcon,
  BookmarkIcon,
} from "@app/core/config-objects/default-icons";
import { debounceTime } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { async } from "rxjs/internal/scheduler/async";
import { resolve } from "url";

@Component({
  selector: "kt-search-dropdown",
  templateUrl: "./search-dropdown.component.html",
})
export class SearchDropdownComponent implements OnInit {
  // Public properties

  // Set icon class name
  @Input() icon: string = "flaticon2-search-1";

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  @Input() type: "brand" | "success" | "warning" = "success";

  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;

  data: any[];
  result: any[];
  loading: boolean;

  searchControl = new FormControl()

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  constructor(private cdr: ChangeDetectorRef, private service: SearchService) {}

  /**
   * On init
   */
  ngOnInit(): void {
    // simulate result from API
    // type 0|1 as separator or item

    this.initFilterForm()

    this.result = [
      {
        text: "Documents",
        type: 0,
      },
      {
        icon: '<i class="flaticon-interface-3 kt-font-warning">',
        text: "Annual finance report",
        type: 1,
      },
      {
        icon: '<i class="flaticon-share kt-font-success"></i>',
        text: "Company meeting schedule",
        type: 1,
      },
      {
        icon: '<i class="flaticon-paper-plane kt-font-info"></i>',
        text: "Project quotations",
        type: 1,
      },
      {
        text: "Customers",
        type: 0,
      },
      {
        img: '<img src="assets/media/users/user1.jpg" alt="">',
        text: "Amanda Anderson",
        type: 1,
      },
      {
        img: '<img src="assets/media/users/user2.jpg" alt="">',
        text: "Kennedy Lloyd",
        type: 1,
      },
      {
        img: '<img src="assets/media/users/user3.jpg" alt="">',
        text: "Megan Weldon",
        type: 1,
      },
      {
        img: '<img src="assets/media/users/user4.jpg" alt="">',
        text: "Marc-André ter Stegen",
        type: 1,
      },
      {
        text: "Files",
        type: 0,
      },
      {
        icon: '<i class="flaticon-lifebuoy kt-font-warning"></i>',
        text: "Revenue report",
        type: 1,
      },
      {
        icon: '<i class="flaticon-coins kt-font-primary"></i>',
        text: "Anual finance report",
        type: 1,
      },
      {
        icon: '<i class="flaticon-calendar kt-font-danger"></i>',
        text: "Tax calculations",
        type: 1,
      },
    ];
  }


  initFilterForm(){
    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(async value=>{
      await this.search(value)
    })
  }

  /**
   * Search
   * @param e: Event
   */
  async search(value) {
    this.data = null;
    if (value.length) {
      this.loading = true;
      await this.service
        .search$(value)
        .toPromise()
        .then((x) => {
          this.result = x.map((d) => {
            if (d.type.toLowerCase() == systemObject.report.toLowerCase()) {
              return {
                title: d.name,
                img:
                  d.icon !== "" && d.icon !== undefined && d.icon !== null
                    ? `<img class='max-width-20' src='${d.icon}'
                    style='max-width: 20px;' />`
                    : `<img class='max-width-20' src='${ReportIcon}'
                    style='max-width: 20px;' />`,
                text: d.description,
                url: d.url,
                type: 1,
              };
            }
            if (d.type.toLowerCase() == systemObject.OnPremReport.toLowerCase()) {
              return {
                title: d.name,
                img:
                  d.icon !== "" && d.icon !== undefined && d.icon !== null
                    ? `<img class='max-width-20' src='${d.icon}'
                    style='max-width: 20px;' />`
                    : `<img class='max-width-20' src='${ReportIcon}'
                    style='max-width: 20px;' />`,
                text: d.description,
                url: d.url,
                type: 1,
              };
            }
            if (d.type.toLowerCase() == systemObject.dashboard.toLowerCase()) {
              return {
                img:
                  d.icon !== "" && d.icon !== undefined && d.icon !== null
                    ? `<img class='max-width-20' src='${d.icon}'
                    style='max-width: 20px;' />`
                    : `<img class='max-width-20' src='${DashboardIcon}'
                    style='max-width: 20px;' />`,
                title: d.name,
                text: d.description,
                url: d.url,
                type: 1,
              };
            }
            if (d.type.toLowerCase() == systemObject.page.toLowerCase()) {
              return {
                img:
                  d.icon !== "" && d.icon !== undefined && d.icon !== null
                    ? `<img class='max-width-20' src='${d.icon}'
                    style='max-width: 20px;' />`
                    : `<img class='max-width-20' src='${PageIcon}'
                    style='max-width: 20px;' />`,
                title: d.name,
                text: d.description,
                url: d.url,
                type: 1,
              };
            }
            if (d.type.toLowerCase() == systemObject.bookmark.toLowerCase()) {
              return {
                img:
                  d.icon !== "" && d.icon !== undefined && d.icon !== null
                    ? `<img class='max-width-20' src='${d.icon}'
                style='max-width: 20px;' />`
                    : `<img class='max-width-20' src='${BookmarkIcon}'
                style='max-width: 20px;' />`,
                title: d.name,
                text: d.description,
                url: d.url,
                type: 1,
              };
            }
            if (d.type.toLowerCase() == systemObject.dataStory.toLowerCase()) {
              return {
                icon: `<i class="fa fa-tags text-primary">`,
                title: d.name,
                text: d.description,
                url: d.url,
                type: 1,
              };
            }

          });
          this.result.unshift({
            text: "Search Result",
            type: 0,
          });
          this.data = this.result;


          this.cdr.markForCheck();
        })
        .then(() => {
          this.data = this.result;
          this.loading = false;
          this.cdr.markForCheck();
        }).catch(()=>{
          this.loading = false;
        });
    }
    else{
      this.loading = false;
    }
  }

  /**
   * Clear search
   *
   * @param e: Event
   */
  clear(e) {
    this.data = null;
    this.searchInput.nativeElement.value = "";
  }
  isCicked(clicked: boolean) {
    if (clicked) {
      this.data = null;
      (<HTMLInputElement>document.getElementById("search-dp")).classList.remove(
        "show"
      );
      return clicked;
    }
    return false;
  }
  openChange() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }
}
