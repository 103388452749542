import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {
  ReportServerModel,
  ReportDetailsServerModel,
} from "../models/report.server.model";
import { DatasetRefreshServerModel } from "../models/dataset-refresh.server.model";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable({ providedIn: "root" })
export class PowerBIReportRestService extends BaseService {
  routUrl = "";
  page = 0;
  pageSize = 0;
  name = "";
  sortOrder = "name_asc";
  constructor(private http: HttpClient) {
    super();
  }
  getAllReports$(
    page: number,
    pageSize: number,
    query: string,
    sort: string,
    getTotal: boolean,
    status: boolean,
    includePages?: boolean
  ): Observable<PageResultModel<ReportServerModel>> {
    this.routUrl = this.baseUrl + `/reports`;
    let url_ = this.routUrl + "?";
    url_ += "page=" + encodeURIComponent("" + page) + "&";
    url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";
    url_ += "searchTerm=" + encodeURIComponent("" + query) + "&";
    url_ += "sortOrder=" + encodeURIComponent("" + sort) + "&";
    url_ += "getTotal=" + encodeURIComponent("" + getTotal) + "&";
    url_ += "status=" + encodeURIComponent("" + status);
    if (includePages)
      url_ += "&" + "includePages=" + encodeURIComponent("" + includePages);

    return this.http.get<PageResultModel<ReportServerModel>>(url_);
    //return this.http.get<ReportServerModel[]>(this.routUrl);
    //return of(this.reports);
  }
  getRecentReports$(): Observable<ReportServerModel[]> {
    this.routUrl = this.baseUrl + `/reports/recent/my`;
    return this.http.get<ReportServerModel[]>(this.routUrl);
  }
  getFrequentReports$(): Observable<ReportServerModel[]> {
    this.routUrl = this.baseUrl + `/reports/frequent/my`;
    return this.http.get<ReportServerModel[]>(this.routUrl);
  }
  getReports$(
    workspaceId: number,
    search?: string
  ): Observable<PageResultModel<ReportServerModel>> {
    this.routUrl = this.baseUrl + `/workspaces/${workspaceId}/reports`;
    let url_ = this.routUrl + "?";

    url_ += "page=" + encodeURIComponent("" + this.page) + "&";
    url_ += "pageSize=" + encodeURIComponent("" + this.pageSize) + "&";
    url_ += "name=" + encodeURIComponent("" + search) + "&";
    url_ += "sortOrder=" + encodeURIComponent("" + this.sortOrder);

    return this.http.get<PageResultModel<ReportServerModel>>(url_);
  }
  getUserReports$(): Observable<ReportDetailsServerModel[]> {
    let url_ = this.baseUrl + `/application/powerbi/reports/my`;
    return this.http.get<ReportDetailsServerModel[]>(url_);
    //return of(this.reports);
  }
  getReportsCount$(): Observable<number> {
    this.routUrl = this.baseUrl + `/reports/active/count`;
    return this.http.get<number>(this.routUrl);
  }
  getReportsCountByStatus$(status: number): Observable<number> {
    return of(this.reports.length);
  }
  getReport$(id: number): Observable<ReportServerModel> {
    this.routUrl = this.baseUrl + `/reports/${id}`;
    return this.http.get<ReportServerModel>(this.routUrl);
    //return of(this.reports.find(x => x.id == id));
  }
  updateReport$(report: ReportServerModel): Observable<ReportServerModel> {
    this.routUrl = this.baseUrl + `/reports`;
    return this.http.put<ReportServerModel>(this.routUrl, report);
  }
  remove$(report: ReportServerModel): Observable<boolean> {
    this.routUrl = this.baseUrl + `/reports/remove`;
    return this.http.put<boolean>(this.routUrl, report);
  }
  syncReport$(reportId: number): Observable<boolean> {
    this.routUrl = this.baseUrl + `/reports/${reportId}/sync`;
    return this.http.get<boolean>(this.routUrl);
  }
  refreshReportDataset$(id: number): Observable<boolean> {
    this.routUrl = this.baseUrl + `/reports/${id}/refreshDataSet`;
    return this.http.get<boolean>(this.routUrl);
  }
  getReportDatasetRefreshHistory$(
    id: number
  ): Observable<DatasetRefreshServerModel[]> {
    this.routUrl = this.baseUrl + `/reports/${id}/refreshHistory`;
    return this.http.get<DatasetRefreshServerModel[]>(this.routUrl);
  }
  getReportEmbedConfig$(reportId: number): Observable<PowerBIEmbedConfig> {
    this.routUrl =
      this.baseUrl + `/application/powerbi/reports/${reportId}/embed-config`;
    return this.http.get<PowerBIEmbedConfig>(this.routUrl);
  }
  syncReportEmbedConfig$(reportId: number): Observable<PowerBIEmbedConfig> {
    this.routUrl =
      this.baseUrl +
      `/application/powerbi/reports/${reportId}/embed-config/sync`;
    return this.http.get<PowerBIEmbedConfig>(this.routUrl);
  }
}
