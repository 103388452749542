import { Feature } from "../../applications/model/feature.model";
import { Usage } from "./usage.model";
import { SubscriptionServerModel } from "@core/services/subscription-services/model/subscription.server.model";

export class SubscriptionModel {
  id?: number | undefined;
  packageId?: number | undefined;
  usage?: Usage[] | undefined;
  chargeType?: number | undefined;
  features?: Feature[] | undefined;
  applicationId?: number | undefined;
  isFree?: boolean | undefined;
  coupon?: string | undefined;
  constructor(subs?: SubscriptionServerModel) {
    if (subs) {
      this.id = subs.id;
      this.packageId = subs.packageId;
      this.usage = subs.usage ? subs.usage.map((u) => new Usage(u)) : [];
      this.chargeType = subs.chargeType;
      this.isFree = subs.isFree;
      this.features = subs.features
        ? subs.features.map((x) => new Feature(x))
        : undefined;
      this.applicationId = subs.applicationId;
    }
  }
}
