import { Injectable } from "@angular/core";
import { NotifyService } from "@shared/services/notify.service";
import { BaseService } from "../../base.service";
import { NotificationService } from "@theme/metronic/app/views/partials/layout/quick-panel/service/notification.service";
import {
  NotificationModel,
  SyncNotificationModel,
  MessageModel
} from "@theme/metronic/app/views/partials/layout/quick-panel/model/notification.model";
import { AuthService } from "../../Auth-service/auth.service";
import {
  CommandNotificationModel,
  CommandOperations
} from "../model/notification.server.model";
import { OAuthService } from "angular-oauth2-oidc";
import { AppLayoutConfigService } from "../../layout-config.service";

@Injectable({ providedIn: "root" })
export class SSEService extends BaseService {
  eventSource: EventSource;
  isConnected = false;
  constructor(
    private notifyService: NotifyService,
    private notificationService: NotificationService,
    private authService: OAuthService,
    private layoutConfig: AppLayoutConfigService
  ) {
    super();
  }

  connect(id: number) {
    this.eventSource = new EventSource(this.baseUrl + `/event-stream?id=${id}`);
    this.isConnected = true;
    this.eventSource.onerror = event => {
      this.connected = false;
    };
    this.eventSource.addEventListener("cmd", (event: any) => {
      if (event.data) {
        const data = JSON.parse(event.data) as any;
        if (data) {
          if (data.Object) {
            const notification = new CommandNotificationModel();
            notification.object = data.Object;
            notification.operation = data.Operation;
            if (data.Operation !== "Shared")
            this.showNotification(
              `${notification.object} ${notification.operation}`
            );
          }
          this.runCommand(data.Operation);
        }
      }
    });
    this.eventSource.addEventListener("msg", (event: any) => {
      if (event.data) {
        const data = JSON.parse(event.data) as any;
        if (data) {
          this.notificationService.addSyncNotification(this.mapSyncModel(data));
        }
      }
    });
    this.eventSource.addEventListener("notify", (event: any) => {
      if (event.data) {
        const item = JSON.parse(event.data) as any;
        if (item) {
          const notification = this.mapNotificationModel(item);
          this.notificationService.addNotification(notification);
          this.showNotification(notification.data);
        }
      }
    });
  }

  mapNotificationModel(item: any): NotificationModel {
    const notification = new NotificationModel();
    notification.id = item.Id;
    notification.data = item.Message;
    notification.severity = item.Severity;
    notification.status = item.Status;
    notification.createdOn = item.CreatedOn;
    return notification;
  }
  mapSyncModel(data: any): SyncNotificationModel {
    const notification = new SyncNotificationModel();
    notification.id = data.Id;
    notification.type = data.Type;
    const message = JSON.parse(data.Message) as any;
    notification.message = new MessageModel();
    notification.message.createdOn = new Date(message.CreatedOn);
    notification.message.information = message.Information
      ? data.Information
      : "";
    notification.message.name = message.Name;
    notification.message.object = message.SystemObject;
    notification.message.status = message.Status;
    notification.createdOn = new Date(data.CreatedOn);
    return notification;
  }
  runCommand(command: string) {
    switch (command.toLowerCase()) {
      case CommandOperations.suspend: {
        this.authService.logOut();
        break;
      }
      case CommandOperations.shared: {
        this.layoutConfig.reloadConfigs();
        break;
      }
      case CommandOperations.refreshToken: {
        this.authService.silentRefresh().then(() => {
          this.layoutConfig.reloadConfigs();
        });
        break;
      }
    }
  }
  disconnect() {
    this.eventSource.close();
  }
  showNotification(msg: string) {
    this.notifyService.info(msg);
  }
}
