import { Component, OnInit, ViewChildren, OnDestroy } from "@angular/core";
import { DashboardVisualModel } from "../model/dashboard-visual.model";
import { NotifyService } from "@app/shared/services/notify.service";
import { PBIEmbeddedComponent } from "../pbi-embedded/pbi-embedded.component";
import { UserAction, systemObject } from "@app/shared/model/user-action.enum";
import { PowerBIDashboardVisualService } from "../service/pbi-dashboard-visual.service";
import {
  GridsterConfig,
  GridsterItem,
  GridType,
  DisplayGrid,
} from "angular-gridster2";
import { Subject } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { Report } from "report";
import * as pbi from "powerbi-client";

@Component({
  selector: "qx-pinned-visuals",
  templateUrl: "./pinned-visuals.component.html",
  styleUrls: ["./pinned-visuals.component.scss"],
})
export class PinnedVisualsComponent implements OnInit, OnDestroy {
  options: GridsterConfig;
  visuals: DashboardVisualModel[] = [];
  dashboard: Array<GridsterItem> = [];
  private unsubscribe$$ = new Subject<void>();
  @ViewChildren(PBIEmbeddedComponent)
  public embeddedReport: PBIEmbeddedComponent;
  edit = false;
  loading = true;
  showHeader = true;
  powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
    pbi.Report
  );
  constructor(
    private toaster: NotifyService,
    public visualService: PowerBIDashboardVisualService
  ) {}
  async ngOnInit() {
    this.options = {
      gridType: GridType.Fixed,
      displayGrid: DisplayGrid.OnDragAndResize,
      fixedColWidth: 105,
      fixedRowHeight: 105,
      keepFixedHeightInMobile: true,
      keepFixedWidthInMobile: true,
      mobileBreakpoint: 640,
      pushItems: true,
      draggable: {
        enabled: false,
      },
      resizable: {
        enabled: false,
      },
    };
    this.visualService
      .getAllDashboardVisuals$()
      .pipe(take(1), takeUntil(this.unsubscribe$$))
      .subscribe(
        (x) => {
          this.visuals = x;
          this.loading = false;
          this.resetDashboard();
        },
        (err) => {
          this.loading = false;
          if (err) {
            this.toaster.error({
              crud: UserAction.Crud.get,
              object: systemObject.visual,
            });
          }
        }
      );
  }

  changedOptions() {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  removeItem($event, item) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dashboard.splice(this.dashboard.indexOf(item), 1);
  }
  enableresizeAndDrag() {
    this.options.draggable.enabled = true;
    this.options.resizable.enabled = true;
    this.options.displayGrid = DisplayGrid.Always;
    this.edit = true;
    this.changedOptions();
  }
  async saveChanges() {
    this.disabledresizeAndDrag();
    await this.updatePositions();
  }
  disabledresizeAndDrag() {
    this.options.draggable.enabled = false;
    this.options.resizable.enabled = false;
    this.options.displayGrid = DisplayGrid.None;
    this.edit = false;
    this.changedOptions();
  }
  cancelChanges() {
    this.disabledresizeAndDrag();
    this.resetDashboard();
  }
  resetDashboard() {
    this.dashboard = [];
    this.visuals.forEach((element) => {
      this.addItem(element);
    });
  }
  addItem(visual: DashboardVisualModel) {
    this.dashboard.push({
      id: visual.id,
      x: visual.left,
      y: visual.top,
      cols: visual.width,
      rows: visual.height,
    });
  }
  async updatePositions() {
    const visuals = this.dashboard.map((visual) => {
      const widget = this.visuals.find((m) => m.id === visual.id);
      if (widget) {
        widget.left = visual.x;
        widget.top = visual.y;
        widget.width = visual.cols;
        widget.height = visual.rows;
        return widget;
      }
    });
    this.visualService
      .updateDashboardVisuals$(visuals)
      .toPromise()
      .then(() => {
        this.toaster.success({
          crud: UserAction.Crud.update,
          object: systemObject.widget,
        });
      })
      .catch(() => {
        this.toaster.error({
          crud: UserAction.Crud.update,
          object: systemObject.widget,
        });
      });
  }
  toggleVisualHeader() {
    this.showHeader = !this.showHeader;
    var divs = <HTMLElement[]>(
      (<unknown>document.querySelectorAll("#reportsContainer"))
    );
    divs.forEach((div, i) => {
      const newSettings = {
        visualSettings: {
          visualHeaders: [
            {
              settings: {
                visible: this.showHeader,
              },
              // No selector - Hide visual header for all the visuals in the report
            },
          ],
        },
      };
      const report: Report = this.powerbi.get(div) as Report;
      report.updateSettings(newSettings);
    });
  }
  ngOnDestroy() {
    this.unsubscribe$$.next();
  }
}
