import { OnPremPageServerModel } from './../../../../../../core/services/powerbi-server-service/model/on-prem-page-server.model';

import { IconModel } from "@app/icons/model/icon.model";

export class OnPremPageModel {
    id?: number | undefined;
    reportId?: number | undefined;
    iconId?: number | undefined;
    name?: string | undefined;

    displayName?: string | undefined;
    description?: string | undefined;
    tags?: string[] | undefined;
    status?: number | undefined;

    filterPane?: boolean | undefined;
    sortId?: number | undefined;
    isFavourite?: boolean | undefined;

    icon?: IconModel | undefined;
    constructor(item?: OnPremPageServerModel) {
        if (item) {
            this.id = item.id;
            this.reportId = item.reportId;
            this.iconId = item.iconId;
            this.name = item.name;
            this.displayName = item.displayName;
            this.description = item.description;
            this.tags = item.tags;
            this.status = item.status;
            this.filterPane = item.filterPane;
            this.sortId = item.sortId;
            this.isFavourite = item.isFavourite;
            this.icon = new IconModel(item.icon);
        }
    }
}
