import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonService } from "./services/common.service";
import { AppLayoutConfigService } from "./services/layout-config.service";
import { AuthModule } from "@app/auth.module";
import { AuthService } from "./services/Auth-service/auth.service";

@NgModule({
  imports: [CommonModule, AuthModule.forRoot()],
  declarations: [],
  exports: [],
  providers: [CommonService, AppLayoutConfigService]
})
export class CoreModule {}
