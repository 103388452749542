import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { HttpClient } from "@angular/common/http";
import {
  NotificationServerModel,
  LogServerModel,
} from "../model/notification.server.model";
import { Observable, of } from "rxjs";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable({
  providedIn: "root",
})
export class NotificationRestService extends BaseService {
  routUrl = "";

  constructor(private http: HttpClient) {
    super();
    this.routUrl = this.baseUrl + `/notifications`;
  }
  getAllNotifications$(
    page: number | null | undefined,
    pageSize: number | null | undefined,
    includeRead = false,
    filter: string,
    getTotal: boolean
  ): Observable<PageResultModel<NotificationServerModel>> {
    let url_ = this.routUrl + "?";
    if (page !== undefined)
      url_ += "page=" + encodeURIComponent("" + page) + "&";
    if (pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";
    if (includeRead !== undefined)
      url_ += "includeRead=" + encodeURIComponent("" + includeRead) + "&";
    if (filter !== undefined)
      url_ += "filter=" + encodeURIComponent("" + filter) + "&";
    if (getTotal !== undefined)
      url_ += "getTotal=" + encodeURIComponent("" + getTotal);
    return this.http.get<PageResultModel<NotificationServerModel>>(url_);
  }
  getNotifications$(
    page: number | null | undefined,
    pageSize: number | null | undefined
  ): Observable<PageResultModel<NotificationServerModel>> {
    let url_ = this.routUrl + "?";
    if (page !== undefined)
      url_ += "page=" + encodeURIComponent("" + page) + "&";
    if (pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize);

    return this.http.get<PageResultModel<NotificationServerModel>>(url_);
    //return of(this.notifications);
  }
  getLogs$(
    page: number | null | undefined,
    pageSize: number | null | undefined,
    filter?: string | null | undefined
  ): Observable<PageResultModel<LogServerModel>> {
    let url_ = this.baseUrl + "/useractivitylogs?";
    if (page !== undefined)
      url_ += "page=" + encodeURIComponent("" + page) + "&";
    if (pageSize !== undefined)
      url_ += "pageSize=" + encodeURIComponent("" + pageSize) + "&";
    if (filter !== undefined)
      url_ += "filter=" + encodeURIComponent("" + filter);
    return this.http.get<PageResultModel<LogServerModel>>(url_);
    //return of(this.notifications);
  }
  removeAlert$(id: number): Observable<number[]> {
    let url_ = this.routUrl + `/mark-as-read`;
    return this.http.patch<number[]>(url_, [id]);
  }
  removeNotification$(id: number): Observable<number[]> {
    let url_ = this.routUrl + `/mark-as-read`;
    return this.http.patch<number[]>(url_, [id]);
  }
  clearAll$(ids: number[]): Observable<number[]> {
    let url_ = this.routUrl + `/mark-as-read`;
    return this.http.patch<number[]>(url_, ids);
  }
  getSubdomains$(): Observable<any[]> {
    let url_ = this.baseUrl + `/subdomain`;
    return this.http.get<any[]>(url_);
  }
}
