import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ReportModel } from "../model/report.model";
import { ReportServerModel } from "@core/services/report-service/models/report.server.model";
import { PowerBIReportRestService } from "@core/services/report-service/services/power-bi-report.rest.service";
import { DatasetRefreshModel } from "../model/dataset-refresh.model";
import { DatasetRefreshServerModel } from "@core/services/report-service/models/dataset-refresh.server.model";
import { PageModel } from "../report-details/pages/model/page.model";
import { BookmarkModel } from "../report-details/bookmarks/model/bookmark.model";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { models } from "powerbi-client";
import { PageResultModel } from "@app/core/models/page-result.server.model";

@Injectable()
export class PowerBIReportService {
  count: number = 0;
  constructor(private service: PowerBIReportRestService) {}
  getAllReports$(
    page: number,
    pageSize: number,
    query: string,
    sort: string,
    getTotal: boolean,
    status: boolean,
    includePages?: boolean
  ): Observable<ReportModel[]> {
    return this.service
      .getAllReports$(page, pageSize, query, sort, getTotal, status, includePages)
      .pipe(
        map((res: PageResultModel<ReportServerModel>) => {
          if (getTotal) this.count = res.total;
          return res.result.map((x) => new ReportModel(x));
        })
      );
  }
  getRecentReports$(): Observable<ReportModel[]> {
    return this.service.getRecentReports$().pipe(
      map((res: ReportModel[]) => {
        return res.map((x) => new ReportModel(x));
      })
    );
  }
  getFrequentReports$(): Observable<ReportModel[]> {
    return this.service.getFrequentReports$().pipe(
      map((res: ReportModel[]) => {
        return res.map((x) => new ReportModel(x));
      })
    );
  }
  getReports$(workspaceId: number, search?: string): Observable<ReportModel[]> {
    return this.service.getReports$(workspaceId, search).pipe(
      map((res) => {
        this.count = res.result.length;
        return res.result.map((x) => new ReportModel(x));
      })
    );
  }
  getReportsCount$(): Observable<number> {
    return this.service.getReportsCount$();
  }
  getReportsCountByStatus$(status: number): Observable<number> {
    return this.service.getReportsCountByStatus$(status);
  }
  getReport$(id: number): Observable<ReportModel> {
    return this.service
      .getReport$(id)
      .pipe(map((res: ReportServerModel) => new ReportModel(res)));
  }
  updateReport$(report: ReportModel): Observable<ReportModel> {
    return this.service
      .updateReport$(report)
      .pipe(map((res: ReportServerModel) => new ReportModel(res)));
  }
  remove$(report: ReportModel): Observable<boolean> {
    return this.service.remove$(report);
  }
  syncReport$(reportId: number): Observable<boolean> {
    return this.service.syncReport$(reportId);
  }
  refreshReportDataset$(id: number): Observable<boolean> {
    return this.service.refreshReportDataset$(id);
  }
  getReportDatasetRefreshHistory$(
    id: number
  ): Observable<DatasetRefreshModel[]> {
    return this.service.getReportDatasetRefreshHistory$(id).pipe(
      map((res: DatasetRefreshServerModel[]) => {
        return res.map((x) => new DatasetRefreshModel(x));
      })
    );
  }
  syncReportEmbedConfig$(reportId: number): Observable<PowerBIEmbedConfig> {
    return this.service.syncReportEmbedConfig$(reportId);
  }
  getReportEmbedConfig$(reportId: number): Observable<PowerBIEmbedConfig> {
    return this.service.getReportEmbedConfig$(reportId);
  }
}
