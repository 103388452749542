import { TeamServerModel } from "@app/core/services/team-service/model/team.server.model";

export class TeamModel {
  id?: number | undefined;
  companyId?: number | undefined;
  name?: string;
  code?: string;
  description?: string | undefined;
  reportsCount?: number | undefined;
  premReportsCount?: number | undefined;
  userIds?: number[] | undefined;
  dashboardIds?: number[] | undefined;
  pageIds?: number[] | undefined;
  premPageIds?: number[] | undefined;
  constructor(team?: TeamServerModel) {
    if (team) {
      this.id = team.id;
      this.companyId = team.companyId;
      this.name = team.name;
      this.code = team.code;
      this.description = team.description;
      this.reportsCount = team.reportsCount;
      this.pageIds = team.pageIds;
      this.dashboardIds = team.dashboardIds;
      this.userIds = team.userIds;
      this.premPageIds = team.premPageIds;
      this.premReportsCount = team.premReportsCount;
    }
  }
}
