// Angular
import { Component, Input, OnInit } from "@angular/core";
// RxJS
import { Observable, of } from "rxjs";
// NGRX
import { select, Store } from "@ngrx/store";
// State
import { AppState } from "../../../../../core/reducers";
import { currentUser, Logout, User } from "../../../../../core/auth";
import { AuthService } from "@app/core/services/Auth-service/auth.service";
import { UserModel } from "@app/users-management/model/user.model";
import { AppConfigService } from "@app/shared/services/app-config.service";
import { style } from "@angular/animations";

@Component({
  selector: "kt-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"]
})
export class UserProfileComponent implements OnInit {
  // Public properties
  user: UserModel;

  @Input() avatar: boolean = true;
  @Input() greeting: boolean = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(
    public authService: AuthService,
    public config: AppConfigService
  ) {}
  getImage() {
    return `${this.authService.avatar}`;
  }
  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {}

  /**
   * Log out
   */
  logout() {
    //this.store.dispatch(new Logout());
  }
}
