export const headerMenu: any = {
  items: [
    {
      title: "Dashboards",
      root: true,
      icon: "fa fa-chalkboard",
      bullet: "dot",
      alignment: "left",
      submenu: [],
    },
    {
      title: "Reports",
      root: true,
      icon: "fa fa-chart-bar",
      bullet: "dot",
      alignment: "left",
      submenu: [],
    },
    {
      title: "Favourites",
      root: true,
      icon: "flaticon-star",
      alignment: "left",
      submenu: [
        {
          title: "Widgets",
          icon: "fas fa-th-large",
          bullet: "dot",
          alignment: "left",
          page: "/render/widgets",
        },
        {
          title: "Summary",
          icon: "fas fa-file-alt",
          bullet: "dot",
          alignment: "left",
          page: "/dashboard",
        },
      ],
    },
    {
      title: "Bookmarks",
      root: true,
      icon: "fa fa-tags",
      bullet: "dot",
      alignment: "left",
      submenu: [],
    },
    {
      title: "Data Stories",
      root: true,
      icon: "fa fa-tags",
      bullet: "dot",
      alignment: "left",
      submenu: [],
    },
  ],
};
