// Angular
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map, tap, filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "../services/Auth-service/auth.service";
@Injectable()
export class AuthGuard implements CanActivate {
  private isAuthenticated: boolean;

  constructor(private authService: AuthService) {
    this.authService.isAuthenticated$.subscribe(
      (i) => (this.isAuthenticated = i)
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    return this.authService.isDoneLoading$
      .pipe(filter((isDone) => isDone))
      .pipe(
        tap(async (_) => this.isAuthenticated || await this.authService.login(state.url))
      )
      .pipe(map((_) => {
        return this.isAuthenticated;
      }));
  }
}
