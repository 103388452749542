import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";

import { DataStoryModel } from "../model/data-story.model";
import { DataStoryServerModel } from "@app/core/services/data-story-service/model/data-story.server.model";
import { DataStoryRestService } from "@app/core/services/data-story-service/service/data-story.rest.service";

@Injectable()
export class DataStoryService {
  constructor(private service: DataStoryRestService) {}
  getAllDataStories$(): Observable<DataStoryModel[]> {
    return this.service.getAllDataStories$().pipe(
      map((res: DataStoryServerModel[]) => {
        return res.map(x => new DataStoryModel(x));
      })
    );
  }

  getUserDataStories$(): Observable<DataStoryModel[]> {
    return this.service.getUserDataStories$().pipe(
      map((res: DataStoryServerModel[]) => {
        return res.map(x => new DataStoryModel(x));
      })
    );
  }
  getDataStory$(id: number): Observable<DataStoryModel> {
    return this.service
      .getDataStory$(id)
      .pipe(map((res: DataStoryServerModel) => new DataStoryModel(res)));
  }
  createDataStory$(dataStory: DataStoryModel): Observable<DataStoryModel> {
    return this.service.createDataStory$(dataStory);
  }
  shareDataStory$(dataStory: DataStoryModel): Observable<DataStoryModel> {
    return this.service.shareDataStory$(dataStory);
  }

  getDataStoryEmbedConfig$(
    dataStoryId: number
  ): Observable<PowerBIEmbedConfig> {
    return this.service.getDataStoryEmbedConfig$(dataStoryId);
  }
}
