import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as pbi from "powerbi-client";
import { PowerBIEmbedConfig } from "@app/shared/model/power-bi-embed-config.model";
import { PBIDashboardModel } from "@app/power-bi/components/power-bi-details/workspace-details/dashboards/model/pbi-dashboard.model";
import { PBIEmbeddedComponent } from "../pbi-embedded/pbi-embedded.component";
import { NotifyService } from "@app/shared/services/notify.service";
import { PowerBIDashboardService } from "@app/power-bi/components/power-bi-details/workspace-details/dashboards/service/power-bi-dashboard.service";
import { UserAction, systemObject } from "@app/shared/model/user-action.enum";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "qx-render-dashboard",
  templateUrl: "./render-dashboard.component.html",
  styleUrls: ["./render-dashboard.component.scss"]
})
export class RenderDashboardComponent implements OnInit, OnDestroy {
  id: number;
  embedConfig: PowerBIEmbedConfig = {};
  pbiDashboard: PBIDashboardModel = new PBIDashboardModel();
  @ViewChild(PBIEmbeddedComponent, { static: true })
  public embeddedReport: PBIEmbeddedComponent;
  private unsubscribe$$ = new Subject<void>();
  powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
    pbi.Dashboard
  );
  constructor(
    private notifyService: NotifyService,
    private router: ActivatedRoute,
    public dashboardService: PowerBIDashboardService
  ) {}

  ngOnInit() {
    this.router.paramMap
      .pipe(takeUntil(this.unsubscribe$$))
      .subscribe(async x => {
        this.id = +x.get("id");

        this.dashboardService
          .getDashboard$(this.id)
          .toPromise()
          .then(dashboard => {
            this.pbiDashboard = dashboard;
          })
          .catch(err => {
            if (err) {
              this.notifyService.error({
                crud: UserAction.Crud.get,
                object: systemObject.dashboard
              });
            }
          });
        this.dashboardService
          .getDashboardEmbedConfig$(this.id)
          .toPromise()
          .then((config: PowerBIEmbedConfig) => {
            this.embedConfig = config;
          })
          .catch(err => {
            if (err) {
              this.notifyService.error({
                crud: UserAction.Crud.get,
                object: systemObject.dashboard
              });
            }
          });
      });
  }

  ngOnDestroy() {
    this.unsubscribe$$.next();
  }
}
