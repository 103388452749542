import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { PowerBIProfile } from "../model/power-bi-profile.model";
import { Observable } from "rxjs";
import { ProfileRestService } from "@core/services/power-bi-profile-service/services/profile.rest.service";
import { PowerBIProfileServerModel } from "@core/services/power-bi-profile-service/model/power-bi-profile.model";
import { PowerBIServerProfile } from "../model/power-bi-server-profile.model";
import { PowerBIServerProfileRestModel } from "@app/core/services/powerbi-server-service/model/power-bi-server-profile.model";
import { PowerBIServerProfileRestService } from "@app/core/services/powerbi-server-service/services/powerbi-server-profile.rest.service";

@Injectable()
export class ProfileService {
  constructor(
    private service: ProfileRestService, // private notificationService: NotificationService
    private premProfileService: PowerBIServerProfileRestService
  ) {}

  getPowerBIProfiles$(): Observable<PowerBIProfile[]> {
    return this.service.getPowerBIProfiles$().pipe(
      map((res: PowerBIProfileServerModel[]) => {
        return res.map((data) => new PowerBIProfile(data));
      })
    );
  }
  getCompanyPowerBIProfilesCount$(id: number): Observable<number> {
    return this.service.getCompanyPowerBIProfilesCount$(id);
  }
  getPowerBIProfile$(profileId: number): Observable<PowerBIProfile> {
    return this.service.getPowerBIProfile$(profileId).pipe(
      map((res: PowerBIProfileServerModel) => {
        return new PowerBIProfile(res);
      })
    );
  }
  updatePowerBIProfile$(app: PowerBIProfile): Observable<PowerBIProfile> {
    return this.service.updatePowerBIProfile$(app).pipe(
      map((res: PowerBIProfileServerModel) => {
        return new PowerBIProfile(res);
      })
    );
  }
  addPowerBIProfile$(app: PowerBIProfile): Observable<PowerBIProfile> {
    return this.service.addPowerBIProfile$(app).pipe(
      map((res: PowerBIProfileServerModel) => {
        return new PowerBIProfile(res);
      })
    );
  }
  syncPBIProfile$(profileId: number): Observable<boolean> {
    return this.service.syncPBIProfile$(profileId);
  }
  getPremProfiles$(): Observable<PowerBIServerProfile[]> {
    return this.premProfileService.getPremProfiles$().pipe(
      map((res: PowerBIServerProfileRestModel[]) => {
        return res.map((data) => new PowerBIServerProfile(data));
      })
    );
  }
  getCompanyPremProfilesCount$(id: number): Observable<number> {
    return this.service.getCompanyPremProfilesCount$(id);
  }
  getPremProfile$(profileId: number): Observable<PowerBIServerProfile> {
    return this.service.getPremProfile$(profileId).pipe(
      map((res: PowerBIServerProfileRestModel) => {
        return new PowerBIServerProfile(res);
      })
    );
  }
  updatePremProfile$(
    app: PowerBIServerProfile
  ): Observable<PowerBIServerProfile> {
    return this.premProfileService.updatePremProfile$(app).pipe(
      map((res: PowerBIServerProfileRestModel) => {
        return new PowerBIServerProfile(res);
      })
    );
  }
  addPremProfile$(app: PowerBIServerProfile): Observable<PowerBIServerProfile> {
    return this.premProfileService.addPremProfile$(app).pipe(
      map((res: PowerBIServerProfileRestModel) => {
        return new PowerBIServerProfile(res);
      })
    );
  }
  syncPremProfile$(profileId: number): Observable<boolean> {
    return this.premProfileService.syncPremProfile$(profileId);
  }
}
