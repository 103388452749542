import { NotificationServerModel } from "@core/services/notification-service/model/notification.server.model";
import { systemObject } from "@app/shared/model/user-action.enum";

export class NotificationModel {
  id?: number | undefined;
  data?: string | undefined;
  severity?: number | undefined;
  createdOn?: Date | undefined;
  status?: boolean | undefined;

  constructor(notification?: NotificationServerModel) {
    if (notification) {
      this.id = notification.id;
      this.data = notification.message;
      this.severity = notification.severity;
      this.createdOn = notification.createdOn;
      this.status = notification.status;
    }
  }
}
export class SyncNotificationModel {
  id?: number | undefined;
  message?: MessageModel | undefined;
  type?: string | undefined;
  createdOn?: Date | undefined;
  constructor(notification?: any) {
    if (notification) {
      this.id = notification.id;
      this.message = new MessageModel(notification.message);
      this.type = notification.type;
      this.createdOn = notification.createdOn;
    }
  }
}
export class MessageModel {
  name?: string | undefined;
  information?: string | undefined;
  object?: string | undefined;
  status?: string | undefined;
  createdOn?: Date | undefined;
  constructor(msg?: any) {
    if (msg) {
      this.name = msg.name;
      this.information = msg.information;
      this.object = msg.object;
      this.status = msg.status;
      this.createdOn = msg.createdOn;
    }
  }
}
export enum OperationStatus {
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed"
}
