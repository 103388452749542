import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "../../../environments/environment";

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.authUrl,
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/login-callback',
  silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html",
  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.teams ? "qx-team" : "qx-web",
  scope: 'openid profile email qx-api offline_access',


  requireHttps: false,
  responseType: 'code',
  postLogoutRedirectUri: window.location.origin,
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 0.25, // For faster testing
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
};
