import { UsageServerModel } from "@app/core/services/subscription-services/model/usage.server.model";

export class Usage {
  key?: string | undefined;
  value?: any[] | number | boolean | any | undefined;
  dataType?: string | undefined;
  constructor(item?: UsageServerModel) {
    if (item) {
      this.key = item.key;
      this.dataType = item.dataType;
      switch (item.dataType.toLowerCase()) {
        case "list": {
          if (item.value instanceof Array) {
            this.value = item.value;
          } else {
            this.value = item.value;
            //try {
            //   const val = item.value
            //     .toString()
            //     .replace("[", "")
            //     .replace("]", "")
            //     .trim();
            //   if (val === "") {
            //     this.value = [];
            //   } else {
            //     this.value = val.split(",");
            //   }
            // } catch (error) {
            //   this.value = [];
            // }
          }

          break;
        }
        case "boolean": {
          this.value = item.value;
          break;
        }
        case "number": {
          this.value = +item.value;
          break;
        }
        default: {
          this.value = item.value;
          break;
        }
      }
    }
  }
}
