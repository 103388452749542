import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PageResultModel } from "@app/core/models/page-result.server.model";
import { map } from "rxjs/operators";
import { IconModel } from "../model/icon.model";
import { IconServerModel } from "@app/core/services/icon-service/model/icon.server.model";
import { IconRestService } from "@app/core/services/icon-service/service/icon.rest.service";

@Injectable()
export class IconService {
  count: number;
  constructor(private service: IconRestService) {}

  getIcons$(
    page: number,
    pageSize: number,
    query: string,
    getTotal: boolean
  ): Observable<IconModel[]> {
    return this.service.getIcons$(page, pageSize, query, getTotal).pipe(
      map((res: PageResultModel<IconServerModel>) => {
        this.count = res.total;
        return res.result.map((x) => new IconModel(x));
      })
    );
  }

  createIcon$(file: File, icon: IconModel): Observable<IconModel> {
    return this.service
      .createIcon$(file, icon)
      .pipe(map((res) => new IconModel(res)));
  }
  updateIcon$(icon: IconModel): Observable<IconModel> {
    return this.service
      .updateIcon$(icon)
      .pipe(map((res) => new IconModel(res)));
  }
  remove$(icon: IconModel): Observable<boolean> {
    return this.service.remove$(icon);
  }
}
