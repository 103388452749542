import { OnPremPageModel } from './../components/powerbi-server-detail/powerbi-server-reports/powerbi-server-pages/models/on-prem-page.model';
import { Injectable } from "@angular/core";

import { map } from "rxjs/operators";

import { Observable } from "rxjs";
import { PowerBIServerRestService } from "@app/core/services/powerbi-server-service/services/powerbi-server-rest-service";
import { CatalogRestModel } from "@app/core/services/powerbi-server-service/model/catalog-rest.model";
import { Catalog } from "../components/powerbi-server-detail/models/catalog.model";
import { OnPremPageServerModel } from '@app/core/services/powerbi-server-service/model/on-prem-page-server.model';
import { PageResultModel } from '@app/core/models/page-result.server.model';

// make rest calls
@Injectable({
  providedIn: "root",
})
export class PowerBIServerService {
  count: number = 0;
  constructor(private serverService: PowerBIServerRestService) {}
  getRenderUrl$(reportId: number): Observable<any> {
    return this.serverService.renderReport$(reportId);
  }
  // make get call for api and return observable of type in power-bi/models/*.ts
  getByParentFolderId$(
    profileId: number,
    parentFolderId: string,
    page?: number,
    pageSize?: number,
    query?: string
  ): Observable<Catalog[]> {
    return this.serverService
      .getByParentFolderId$(profileId, parentFolderId, page, pageSize, query)
      .pipe(
        map((res: CatalogRestModel[]) => {
          return res.map((data) => new Catalog(data));
        })
      );
  }
  getAllReports$(
    page: number,
    pageSize: number,
    query: string,
    reportId: number,
    sort: string,
    getTotal: boolean,
    status: boolean
  ): Observable<Catalog[]> {
    return this.serverService
      .getAllReports$(page, pageSize, query, reportId, sort, getTotal, status)
      .pipe(
        map((res: PageResultModel<Catalog>) => {
          if (getTotal) this.count = res.total;
          return res.result.map((x) => new Catalog(x));
        })
      );
  }
  getReportsCount$(): Observable<number> {
    return this.serverService.getReportsCount$();
  }
  getActiveReports$(searchTerm?: string): Observable<Catalog[]> {
    return this.serverService.getActiveReports$(searchTerm).pipe(
      map((res: CatalogRestModel[]) => {
        return res.map((data) => new Catalog(data));
      })
    );
  }
  getMyOnPremReports$(searchTerm?: string): Observable<Catalog[]> {
    return this.serverService.getMyOnPremReports$(searchTerm).pipe(
      map((res: CatalogRestModel[]) => {
        return res.map((data) => new Catalog(data));
      })
    );
  }
  getFavoriteReports$(): Observable<Catalog[]> {
    return this.serverService.getFavouriteReports$().pipe(
      map((res: CatalogRestModel[]) => {
        return res.map((x) => new Catalog(x));
      })
    );
  }
  addFavourite$(id: number): Observable<boolean> {
    return this.serverService.addFavourite$(id);
  }
  removeFavourite$(id: number): Observable<boolean> {
    return this.serverService.removeFavourite$(id);
  }
  updateReportStatus$(report: Catalog): Observable<boolean> {
    return this.serverService.updateReportStatus$(report.id, report.status);
  }
  updateReport$(report: Catalog): Observable<Catalog> {
    return this.serverService.updateReport$(report).pipe(
      map((res: CatalogRestModel) => {
        return new Catalog(res);
      })
    );
  }

  // Pages //
  syncReportPages$(reportId: number, pages: string[]) {
    return this.serverService.syncReportPages$(reportId, pages);
  }
  getPages$(reportId: number, status?: boolean): Observable<OnPremPageModel[]> {
    return this.serverService.getPages$(reportId, status).pipe(
      map((res: OnPremPageServerModel[]) => {
        return res.map((data) => new OnPremPageModel(data));
      })
    );
  }
  getPage$(reportId: number,): Observable<OnPremPageModel> {
    return this.serverService.getPage$(reportId).pipe(
      map((res: OnPremPageServerModel) => {
        return new OnPremPageModel(res);
      })
    );
  }
  updatePage$(page: OnPremPageModel): Observable<OnPremPageModel> {
    return this.serverService
      .updatePage$(page)
      .pipe(map((res: OnPremPageServerModel) => new OnPremPageModel(res)));
  }
  updatePageOrder$(pages: OnPremPageModel[]): Observable<OnPremPageModel[]> {
    return this.serverService
      .updatePageOrder$(pages)
      .pipe(map((res: OnPremPageServerModel[]) => res.map(x => new OnPremPageModel(x))));
  }
  remove$(page: OnPremPageModel): Observable<boolean> {
    return this.serverService.remove$(page);
  }
}
